import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import {
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import MDButton from "../../../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import {getAxiosInstance} from "../../../../utils/interceptor";
import store from "../../../../reduxStore/store";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {loadingStart, loadingStop} from "../../../../reduxStore/actions/actionUI";
import MDTypography from "../../../../components/MDTypography";
import axios from "axios";

const ApiConnections = () => {

    const loading = useSelector(state => state.UI.loading);
    const [lexOfficeApi, setLexOfficeApi] = useState({});
    const [sevDeskApi, setSevDeskApi] = useState({});

    const [lexofficeApiInput, setLexofficeApiInput] = useState('');
    const [sevDeskApiInput, setSevDeskApiInput] = useState('');

    const [showDialog, setShowDialog] = useState(false);

    const [sevUser, setSevUser] = useState(null);

    const authToken = useSelector(state => state.auth.token);
    const farmerId = useSelector(state => state.userProfile.id);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadingStart());
        getAxiosInstance(store).get(`/items/farmer_profile/${farmerId}?fields=fk_api_connections.*`)
            .then(response => {
                dispatch(loadingStop());
                if (response.data.data.fk_api_connections[0]) {
                    response.data.data.fk_api_connections.forEach(connection => {
                        console.log(connection, "CON")
                        if (connection.provider === "lexoffice") {
                            setLexOfficeApi({
                                id: connection.id,
                                key: connection.key
                            })
                        }
                        if (connection.provider === "sevdesk") {
                            setSevDeskApi({
                                id: connection.id,
                                key: connection.key

                            })
                        }
                    })
                    // setLexOfficeApi(response.data.data.fk_api_connections[0].key)
                    // setSevDeskApi(response.data.data.fk_api_connections[0].key)
                }
            })
            .catch(error => {
                console.log(error)
                dispatch(loadingStop());
            })
    }, [])

    function connectApi(provider) {
        getAxiosInstance(store).get(`/api/verify-api-connection`, {
            params: {
                provider: provider,
                apiKey: provider === "lexoffice" ? lexofficeApiInput : sevDeskApiInput,
                farmerId
            },
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        })
            .then((response) => {
                console.log(response, "RESS")
                setSevDeskApiInput('');
                setLexofficeApiInput('');
                if (provider === "lexoffice") {
                    setLexOfficeApi({
                        id: response.data.data.id,
                        key: response.data.data.key
                    })
                }
                if (provider === "sevdesk") {
                    // safetyUserSave
                    getAxiosInstance(store).patch(`/items/api_connections/${response.data.data.id}`, {
                        sevdesk_contact_person: {
                            id: response.data.users[0].id,
                            firstName: response.data.users[0].firstName,
                            lastName: response.data.users[0].lastName,
                        }
                    })

                    setShowDialog(true)
                    setSevDeskApi({
                        id: response.data.data.id,
                        key: response.data.data.key,
                        users: response.data.users
                    })

                }
            })
            .catch(error => {
                console.log(error)
                toast.error("Fehler: Überprüfe, ob der API-Schlüssel korrekt ist.")
            })
    }

    function deleteApi(connectionId, provider) {
        if (confirm("Bist du sicher, dass du diese Verbindung löschen möchtest?")) {
            setLexofficeApiInput('');
            setSevDeskApiInput('');
            getAxiosInstance(store).delete(`/items/api_connections/${connectionId}`,)
                .then(response => {
                    console.log(response.data)
                    if (provider === "sevdesk") {
                        setSevDeskApi({})
                        setShowDialog(false)
                    }
                    if (provider === "lexoffice") {
                        setLexOfficeApi({})
                    }
                    dispatch(loadingStop());
                })
                .catch(error => {
                    console.log(error)
                    setShowDialog(false)
                    dispatch(loadingStop());
                })
            toast.info(`Die API-Verbindung wurde gelöscht.`, {
                position: toast.POSITION.TOP_CENTER
            })
        }

    }

    function handleSevUser(data) {
        if(!sevUser) {
            toast.error("Du musst einen Ansprechspartner wählen.")
        } else {
            getAxiosInstance(store).patch(`/items/api_connections/${sevDeskApi.id}`, {
                sevdesk_contact_person: {
                    id: sevUser.id,
                    firstName: sevUser.firstName,
                    lastName: sevUser.lastName,
                }
            })
                .then(response => {
                    setShowDialog(false);
                    dispatch(loadingStop());

                })
                .catch(error => {
                    console.log(error)
                    dispatch(loadingStop());
                })
        }

    }

    return (
        <div className="container">
            <div className="row">
                <div className="col mb-5">
                    <Typography fontSize={32}>API-Verbindungen</Typography>
                </div>
            </div>

            {
                !sevDeskApi.key && (
                    <div className="row mb-4">
                        <div className="col-md-8 col-xl-6">
                            <div className="flex-row d-flex justify-content-between align-items-center gap-4">

                                <div className="flex-row d-flex justify-content-between align-items-center gap-4">
                                    <div>
                                        <img src={require('../../../../assets/images/lexoffice_logo.png')}
                                             alt="lexoffice_logo"
                                             height={40} width={40}/>
                                    </div>

                                    {
                                        lexOfficeApi.key ? (
                                            <MDTypography sx={{fontSize: 16}} color="success">
                                                Verbunden
                                            </MDTypography>
                                        ) : (
                                            <TextField
                                                id="standard-basic"
                                                value={lexofficeApiInput}
                                                variant="standard"
                                                placeholder="Lexoffice API-Key"
                                                onChange={(e) => setLexofficeApiInput(e.target.value)}
                                            />

                                        )
                                    }
                                </div>

                                <div className="flex-row d-flex justify-content-between align-items-center gap-4">
                                    <div>
                                        {
                                            lexOfficeApi.key ? (
                                                <MDButton
                                                    variant="gradient"
                                                    sx={{minWidth: 140, textAlign: "center"}}
                                                    color="secondary"
                                                    onClick={() => deleteApi(lexOfficeApi.id, "lexoffice")}
                                                    disabled={loading}
                                                >
                                                    {!loading ? "löschen" : <TailSpin color="#fff" height={20}/>}
                                                </MDButton>
                                            ) : (
                                                <MDButton
                                                    variant="gradient"
                                                    sx={{minWidth: 140, textAlign: "center"}}
                                                    color="success"
                                                    onClick={() => connectApi("lexoffice")}
                                                    disabled={loading}
                                                >
                                                    {!loading ? "verbinden" : <TailSpin color="#fff" height={20}/>}
                                                </MDButton>
                                            )
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                )
            }

            {
                !lexOfficeApi.key && (
                    <div className="row mb-4">
                        <div className="col-md-8 col-xl-6">
                            <div className="flex-row d-flex justify-content-between align-items-center gap-4">

                                <div className="flex-row d-flex justify-content-between align-items-center gap-4">
                                    <div>
                                        <img src={require('../../../../assets/images/sevdesk_logo.png')}
                                             alt="lexoffice_logo"
                                             height={40} width={40}/>
                                    </div>

                                    {
                                        sevDeskApi.key ? (
                                            <div className="flex-row">
                                                <MDTypography sx={{fontSize: 16}} color="success">
                                                    Verbunden
                                                </MDTypography>
                                            </div>
                                        ) : (
                                            <TextField
                                                id="standard-basic"
                                                value={sevDeskApiInput}
                                                variant="standard"
                                                placeholder="SevDesk API-Key"
                                                onChange={(e) => setSevDeskApiInput(e.target.value)}
                                            />
                                        )
                                    }
                                </div>

                                <div className="flex-row d-flex justify-content-between align-items-center gap-4">
                                    <div>
                                        {
                                            sevDeskApi.key ? (
                                                <MDButton
                                                    variant="gradient"
                                                    sx={{minWidth: 140, textAlign: "center"}}
                                                    color="secondary"
                                                    onClick={() => deleteApi(sevDeskApi.id, "sevdesk")}
                                                    disabled={loading}
                                                >
                                                    {!loading ? "löschen" : <TailSpin color="#fff" height={20}/>}
                                                </MDButton>
                                            ) : (
                                                <MDButton
                                                    variant="gradient"
                                                    sx={{minWidth: 140, textAlign: "center"}}
                                                    color="success"
                                                    onClick={() => connectApi("sevdesk")}
                                                    disabled={loading}
                                                >
                                                    {!loading ? "verbinden" : <TailSpin color="#fff" height={20}/>}
                                                </MDButton>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {/* Delete confirmation Dialog box */}
            <Dialog
                open={showDialog}
                onClose={!showDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Ansprechspartner*innen
                </DialogTitle>
                <DialogContent>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Ansprechpartner</InputLabel>
                            <Select
                                labelId="Ansprechspartner"
                                id="Ansprechspartner"
                                placeholder="Ansprechspartner"
                                value={sevUser}
                                label="Ansprechpartner"
                                style={{minHeight: "44px"}}
                                onChange={(e) => setSevUser(e.target.value)}
                            >
                                <MenuItem key={0} value={null}>
                                    Keine wahl
                                </MenuItem>
                                {
                                    sevDeskApi.users?.map(user => (
                                            <MenuItem
                                                key={user.id}
                                                value={user}
                                            >
                                                {`${user.firstName} ${user.lastName}`}
                                            </MenuItem>
                                        )
                                    )
                                }
                            </Select>
                        </FormControl>
                </DialogContent>
                <DialogActions>
                    <MDButton variant="gradient" onClick={() => deleteApi(sevDeskApi.id, "sevdesk")}
                              color="light" style={{color: "gray"}}>Abbrechen</MDButton>
                    <MDButton variant="gradient" onClick={handleSevUser}
                              color={sevUser ? "info" : "light"} style={{color: !sevUser && "gray"}}
                    >
                        { !loading ? "Speichern" : <TailSpin height={20} width={60}/> }
                    </MDButton>
                </DialogActions>
            </Dialog>
        </div>

    );
};

export default ApiConnections;
