import * as actionTypes from '../actions/types';

const userProfileReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.READ_USER_PROFILE:
            const {
                id,
                contact_email,
                contact_first_name,
                contact_last_name,
                contact_telephone,
                delivery_fee,
                colour,
                delivery_deadline,
                fk_address,
                fk_customer_restaurant,
                fk_farmer_categories,
                shipping_availability,
                store_banners,
                store_description,
                store_name,
                store_thumbnail,
                fk_global_tax
            } = action.payload.fk_farmer_profile[0];
            return {
                ...state,
                id,
                contact_email,
                contact_first_name,
                contact_last_name,
                contact_telephone,
                delivery_fee,
                colour,
                delivery_deadline,
                fk_address,
                fk_customer_restaurant,
                fk_farmer_categories,
                shipping_availability,
                store_banners,
                store_description,
                store_name,
                store_thumbnail,
                fk_global_tax
            }

        case actionTypes.UPDATE_THUMBNAIL:
            return {
                ...state,
                store_thumbnail: action.payload.store_thumbnail
            }

        case actionTypes.UPDATE_BANNERS:
            return {
                ...state,
                store_banners: action.payload.store_banners
            }
        case actionTypes.UPDATE_BUSINESS_DETAILS:
            return {
                ...state,
                store_name: action.payload.store_name,
                store_description: action.payload.store_description,
                short_description: action.payload.short_description,
                delivery_fee: action.payload.delivery_fee,
                delivery_deadline: action.payload.delivery_deadline,
                shipping_availability: action.payload.shipping_availability,
                fk_farmer_categories: action.payload.fk_farmer_categories,
                fk_global_tax: action.payload.fk_global_tax,
            }
        case actionTypes.UPDATE_BUSINESS_CATEGORIES:
            return {
                ...state,
                farmer_categories: action.payload.fk_farmer_categories,
            }

        default:
            return state;
    }
};

export default userProfileReducer;
