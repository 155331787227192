/* eslint-disable no-console */

import axios from 'axios';
import * as actionTypes from "./types";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../store";
import {loadingStart, loadingStop} from "./actionUI";
import {ROLE_RESTAURANT} from "../../variables";
import {toast} from "react-toastify";

export const generateNewTokens = (data) => {
    const { access_token, refresh_token } = data;
    return {
        type: actionTypes.SET_TOKENS,
        payload: {
            access_token,
            refresh_token
        }
    }
}

export const authLogin = (email, password) => async dispatch => {
    const loginData = {email, password}
    dispatch(loadingStart())
    // FIRST GET THE ACCESS TOKEN AND REFRESH TOKEN
    axios.post(`${process.env.REACT_APP_SITE_URL}/auth/login`, loginData)
        .then(response => {
            const {data} = response.data;
            console.log(data, "LOGIN DATA")
            dispatch({
                type: actionTypes.SET_TOKENS,
                payload: data
            });
            // THEN GET THE USER INFO AND SAVE IT IN REDUX
            getAxiosInstance(store).get('/users/me',{
                params: { "fields": "*.*.*"}
            })
                .then(response => {
                    const {role} = response.data.data;

                    if (role.name === "Restaurant") {
                        toast.error("Wenn du dich als Restaurant anmelden möchtest, verwende bitte die Foodhi-App.")
                        dispatch(authLogout())
                    } else {
                        dispatch({
                            type: actionTypes.AUTH_LOGIN,
                            payload: response.data.data
                        });
                        dispatch({
                            type: actionTypes.READ_USER_PROFILE,
                            payload: response.data.data
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    toast.error(error.message)
                    dispatch(authLogout())
                    dispatch(loadingStop())
                })
        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error);
            if(error.response.status === 401) {
                toast.error("Anmeldefehler. Entweder deine E-Mail oder dein Passwort ist falsch.")
            } else toast.error("Anmeldefehler. Bestätige deine E-Mail oder dein Passwort.")
            dispatch(loadingStop())
        })
}

export const authLogout = () => {
    return {
        type: actionTypes.AUTH_LOGOUT,
    };
}

export const authForgotPassword = (data) => (
    {
        type: actionTypes.AUTH_FORGOT_PASSWORD,
        payload: data
    }
)

export const notAuthorised = () => (
    {
        type: actionTypes.NOT_AUTHORIZED,
    }
)
