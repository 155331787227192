import {useState} from "react";
import {Button, Icon, Modal, useMediaQuery} from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import colors from "../../../../../assets/theme/base/colors";
import OrderDetailsData from "./OrdeDetailData";

function OrderModal ({ order }) {
    const [modalVisibility, setModalVisibility] = useState({
        visibility: false,
        id: null,
    });

    function handleOpenModal() {
        setModalVisibility({
            visibility: true,
            id: order.id,
        });
    }

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <div style={{paddingRight: 0}}>
            <Icon
                sx={{ color: "lightgray", display: "flex", margin: "auto", cursor: "pointer"}}
                fontSize="16"
                onClick={() => handleOpenModal()}
            >
                visibilityOutlined
            </Icon>
            {modalVisibility.id === order.id && (
                <Modal
                    open={modalVisibility}
                    onClose={!modalVisibility}
                    order={order}
                    style={{
                        // position: "absolute",
                        position: "fixed",
                        // top: "10%",
                        // left: "10%",
                        overflow: "scroll",
                        height: "100%",
                        display: "block",
                        overflowY: "auto"
                    }}
                >
                    <MDBox
                        pt="5rem"
                        pb={3}
                        sx={
                            matches ? { width: window.innerWidth * 0.75 }
                                : { width: window.innerWidth * 0.9 }
                        }
                        width={window.innerWidth * 0.75}
                        margin="auto"
                    >
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={2}
                                        px={2}
                                        variant="gradient"
                                        bgColor="foodhiLightGreen"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            {`Bestellungsdetails`}
                                        </MDTypography>
                                        <Button
                                            color={colors.foodhiOrange.main}
                                            variant="gradient"
                                            onClick={() => setModalVisibility(!modalVisibility)}
                                        >
                                            <Icon
                                                sx={{
                                                    fontWeight: "bold",
                                                    color: "#fff",
                                                }}
                                                fontSize="16"
                                            >
                                                cancel
                                            </Icon>
                                        </Button>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <OrderDetailsData order={order} />
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </Modal>
            )}
        </div>
    );
};

export default OrderModal;