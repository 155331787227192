/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

// @mui material components
import Icon from "@mui/material/Icon";
import {TailSpin} from "react-loader-spinner";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";


import DataTable from "examples/Tables/DataTable";

// Data
import productDataTable from "./productDataTable";

import MDButton from "../../../components/MDButton";
import colors from "../../../assets/theme/base/colors";
import {
    closeProductCreateDialog,
    closeProductDeleteDialog, closeProductEditDialog,
    loadingStart,
    loadingStop,
} from "../../../reduxStore/actions/actionUI";
import ProductUpdate from "../product-update";
import ProductCreate from "../product-create";
import {fetchProducts} from "../../../reduxStore/actions/actionProducts";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";


function ProductsList() {
    const {columns, rows} = productDataTable();

    const UIActions = useSelector(state => state.UI);
    const openDialogDelete = useSelector(state => state.UI.openProductDeleteDialog);
    const deleteProductIdRedux = useSelector(state => state.UI.deleteProductId);
    const userId = useSelector(state => state.auth.id);

    const products = useSelector(state => state.products.productsList)
    const isLoading = useSelector(state => state.products.loading);
    const productError = useSelector(state => state.products.error);

    const dispatchActions = useDispatch();

    const [open, setOpen] = useState(false);
    const [modalCreateProduct, setModalCreateProduct] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);

    const modalProductEditVisible = UIActions.openProductEditDialog;
    
    const handleDialogOpen = () => {
        setOpen(true);
    };

    const handleDialogClose = () => {
        setOpen(false);
        dispatchActions(closeProductDeleteDialog())
    };

    const handleProductDelete = () => {
        try {
            dispatchActions(loadingStart());
            setButtonLoading(true);

            getAxiosInstance(store).patch(`/items/products/${deleteProductIdRedux}`, {status: "archived"})
                .then(() => {
                    toast.info('Produkt gelöscht');
                    dispatchActions(fetchProducts(userId));
                    handleDialogClose()
                    setButtonLoading(false)
                })
                .catch(error => {
                    console.log(error.response);
                    setButtonLoading(false)
                })
        } catch (error) {
            toast.error('Some pronlem occured while deleting.');
            dispatchActions(loadingStop);
            setButtonLoading(false)
        }
    }

    // DELETE PRODUCT MODAL CHECK
    useEffect(() => {
        if (UIActions.openProductDeleteDialog) {
            handleDialogOpen()
        }
    }, [openDialogDelete]);

    function handleClose() {
        dispatchActions(closeProductEditDialog());
        dispatchActions(closeProductCreateDialog());
    }

    const handleCloseProductCreateModal = () => {
        setModalCreateProduct(false);
    }

    const renderListOfProducts = useCallback(
        () => {
            return (
                <MDBox>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "start",
                        minHeight: "400px",
                        paddingBottom: "70px"
                    }}>
                        {
                            /* eslint-disable */
                            isLoading
                                ? <TailSpin wrapperStyle={{alignSelf: "center"}} color={colors.foodhiOrange.main}/>
                                : productError
                                    ? <h4>Es gibt ein Fehler mit der Verbindung</h4>
                                    : products && products.length
                                        ? (
                                            <DataTable
                                                products={products}
                                                table={{columns, rows}}
                                                showTotalEntries={false}
                                                isSorted
                                                noEndBorder
                                                entriesPerPage={false}
                                            />
                                        )
                                        : <h4>Noch keine Produkte angelegt</h4>
                            /* eslint-enable */

                        }
                    </div>
                </MDBox>
            )
        },
        [products],
    );

    return (
        <>
            {/*PRODUCT COUNTER AT THE TOP LEFT OF THE LIST*/}
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <MDBox>
                    {
                        products && products?.length > 0 && (
                            <MDBox display="flex" alignItems="center" lineHeight={0}>
                                <Icon sx={{fontWeight: "bold", color: ({palette: {info}}) => info.main, mt: -0.5}}>
                                    done
                                </Icon>
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                    &nbsp;
                                    <strong>{products?.length} {products?.length > 1 ? "Produkte" : "Produkt"}</strong>
                                </MDTypography>
                            </MDBox>
                        )
                    }
                </MDBox>

                {/*BUTTON THAT OPENS CREATE PRODUCT MODAL*/}
                <MDBox color="text" px={2}>
                    <div style={{textAlign: "right"}}>
                        <MDButton
                            onClick={() => {
                                setModalCreateProduct(true)
                            }}
                            sx={{
                                borderRadius: "50%",
                                backgroundColor: colors.foodhiOrange.main,
                                "&:hover": {
                                    backgroundColor: colors.foodhiOrange.focus
                                },
                                color: "#fff",
                                width: 40,
                                height: 40,
                                minWidth: 0,
                                padding: 0,
                                marginRight: window.innerWidth < 1200 ? 0 : "2rem"
                            }}
                        >
                            <Icon sx={{fontWeight: "bold"}}>add</Icon>
                            {/* &nbsp;add product */}
                        </MDButton>
                    </div>
                </MDBox>
            </MDBox>


            {/*LIST OF PRODUCTS*/}
            {renderListOfProducts()}

            {/* Delete confirmation Dialog box */}
            <Dialog
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Bist Du sicher, dass Du diesen Artikel löschen wollst?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Einmal gelöschte Produkte können nicht wiederhergestellt werden. Bist Du sicher, dass Du
                        fortfahren möchtest?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton variant="gradient" onClick={!buttonLoading && handleDialogClose}
                              color="light" style={{color: "gray"}}>Abbrechen</MDButton>
                    <MDButton variant="gradient" onClick={!buttonLoading && handleProductDelete}
                              color="info">
                        {!buttonLoading ? "Löschen" : <TailSpin height={20} width={60}/>}
                    </MDButton>
                </DialogActions>
            </Dialog>

            {/* EDIT PRODUCT MODAL */}
            <Dialog
                open={modalProductEditVisible}
                onClose={handleClose}
                aria-labelledby="Produkt bearbeiten"
                aria-describedby="Produkt bearbeiten"
            >
                <ProductUpdate/>
            </Dialog>

            {/* CREATE PRODUCT MODAL */}
            <Dialog
                open={modalCreateProduct}
                onClose={() => setModalCreateProduct(false)}
                aria-labelledby="Produkt erstellen"
                aria-describedby="Produkt erstellen"
            >
                <ProductCreate handleCloseModal={handleCloseProductCreateModal}/>

            </Dialog>
        </>

    );
}

export default ProductsList;
