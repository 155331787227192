/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProductsList from "./product-list";
import {useEffect} from "react";
import {fetchProducts} from "../../reduxStore/actions/actionProducts";
import {useDispatch, useSelector} from "react-redux";


function Products() {

    const dispatch = useDispatch();
    const userId = useSelector(state => state.auth.id)

    // GET ALL PRODUCTS RELATED TO THE VENDOR
    useEffect(() => {
        dispatch(fetchProducts(userId));
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="foodhiLightGreen"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Produkte
                                </MDTypography>
                            </MDBox>

                            <ProductsList/>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer/>
        </DashboardLayout>
    );
}

export default Products;
