import * as actionTypes from "./types";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../store";
import {toast} from "react-toastify";
import sendEmail from "../../emails/emailApi";
import {FETCH_PENDING_RESTAURANTS} from "./types";
import {loadingStop} from "./actionUI";

// CUSTOMERS
export const fetchCustomers = (storeId, sort) => async dispatch => {
    console.log("CUSTOMERS REQUESTED")
    dispatch({type: actionTypes.FETCH_CUSTOMERS_REQUESTED})
    getAxiosInstance(store).get(`${process.env.REACT_APP_SITE_URL}/items/farmer_profile`, {
        params: {
            "filter[id][_eq]": storeId,
            fields:
                "fk_customer_restaurant.restaurant_profile_id.fk_shipping_address.*, " +
                "fk_customer_restaurant.restaurant_profile_id.restaurant_name, " +
                "fk_customer_restaurant.restaurant_profile_id.phone, " +
                "fk_customer_restaurant.restaurant_profile_id, " +
                "fk_customer_restaurant.restaurant_profile_id.id, " +
                "fk_customer_restaurant.restaurant_profile_id.status, " +
                "fk_customer_restaurant.restaurant_profile_id.push_notification_key, " +
                "fk_customer_restaurant.restaurant_profile_id.restaurant_thumbnail, " +
                "fk_customer_restaurant.restaurant_profile_id.contact_first_name, " +
                "fk_customer_restaurant.restaurant_profile_id.contact_telephone, " +
                "fk_customer_restaurant.restaurant_profile_id.contact_last_name, " +
                "fk_customer_restaurant.restaurant_profile_id.contact_email, " +
                "fk_customer_restaurant.restaurant_profile_id.lexoffice_customer_number, " +
                "fk_customer_restaurant.restaurant_profile_id.preferential_delivery_days, " +
                "fk_customer_restaurant.id, " +
                "pending_invites"
        }
    })
        .then(response => {
            console.log("CUSTOMERS SUCCEED", response.data.data[0].fk_customer_restaurant)
            // CHECK IF PENDING CUSTOMERS EMAILS ARE ALREADY LINKED AND FILTER THEM FROM ARRAY PENDING_INVITES
            const pendingInvites = response.data.data[0].pending_invites;
            const existingCustomers = response.data.data[0].fk_customer_restaurant.map(item => item.restaurant_profile_id.contact_email);
            const filteredPendingInvites = pendingInvites?.filter(item => !existingCustomers.includes(item))
            getAxiosInstance(store).patch(`/items/farmer_profile/${storeId}`, {pending_invites: filteredPendingInvites})
                .then((response) => {
                    console.log("REMOVE PENDING RESTAURANTS THAT GOT LINKED PATCH SUCCESS", response)
                    dispatch({
                        type: actionTypes.FETCH_PENDING_RESTAURANTS,
                        payload: response.data.data.pending_invites
                    });
                })
                .catch(() => {
                    console.log("ERROR REMOVING PENDING RESTAURANTS THAT GOT LINKED")
                })

            dispatch({
                type: actionTypes.FETCH_CUSTOMERS_SUCCEEDED,
                payload: response.data.data[0].fk_customer_restaurant
            });
        })
        .catch(error => {
            console.log("CUSTOMERS ERROR", error)
            dispatch({
                type: actionTypes.FETCH_CUSTOMERS_FAILED,
                payload: error.message
            })
        })
}

export const addExistingCustomer = (restaurantId, restaurantDetails, farmerId, farmerName, linkedCustomers, email) => async dispatch => {

    console.log("ADDING EXISTING CUSTOMER")
    dispatch({type: actionTypes.START_ADD_EXISTING_CUSTOMER})
    // EXTRACT ID OF EXISTING LINKED CUSTOMERS
    let linkedCustomersId = [];
    linkedCustomers.forEach(customer => {
        linkedCustomersId.push(customer.id)
    })

    // PATCH FARMER PROFILE TO ADD NEW CUSTOMER
    getAxiosInstance(store).get(`items/farmer_profile/${farmerId}?fields=fk_customer_restaurant.*`)
        .then(response => {
            const existingCustomers = response.data.data.fk_customer_restaurant;
            getAxiosInstance(store).patch(`items/farmer_profile/${farmerId}?fields=fk_customer_restaurant.*.*`, {
                fk_customer_restaurant: [
                    ...existingCustomers,
                    {
                        "farmer_profile_id": farmerId,
                        "restaurant_profile_id": restaurantId,
                        "status": "connected"
                    }
                ],
            })
                .then((response) => {
                    const parsedDataForRedux = [];
                    response.data.data.fk_customer_restaurant.forEach(customer => {
                        parsedDataForRedux.push({
                            id: customer.id,
                            restaurant_profile_id: customer.restaurant_profile_id
                        })
                    })
                    dispatch({
                        type: actionTypes.ADD_EXISTING_CUSTOMER,
                        payload: parsedDataForRedux
                    })
                    sendEmail({
                        templateId: "RESTAURANT_Incoming_Invitation_Existing_User",
                        data: {...restaurantDetails, farmerName},
                        recipient: email
                    });

                    toast.success(`${email} wurde erfolgreich eingeladen.`);
                })
                .catch(error => {
                    console.log(error, "ERROR REDUX ADD EXISTING CUSTOMER")
                    dispatch({type: actionTypes.ERROR_ADD_EXISTING_CUSTOMER})
                })
        })

}

export const actionUnlinkCustomer = (farmer_id, patchData) => async dispatch => {
    dispatch({type: actionTypes.START_UNLINK_CUSTOMER})
    getAxiosInstance(store).patch(`/items/farmer_profile/${farmer_id}`, patchData, {
        params: {fields: "fk_customer_restaurant.*.*"}
    })
        .then(response => {
            console.log("PATCH LINKED CUSTOMERS OK", response.data.data)
            dispatch({
                type: actionTypes.UNLINK_CUSTOMER,
                payload: response.data.data.fk_customer_restaurant
            })
        })
        .catch(error => {
            console.log("PATCH LINKED CUSTOMERS ERROR", error)
            dispatch({
                type: actionTypes.ERROR_UNLINK_CUSTOMER,
                error: error.response.data
            })
        })
}
export const actionFetchRecurrentOrders = (customerId, farmerId) => async dispatch => {
    getAxiosInstance(store).get(`items/recurrent_order?fields=*.*`, {
        params: {
            "filter[fk_restaurant][_eq]": customerId,
            "filter[fk_farmer][_eq]": farmerId
        }
    })
        .then(response => {
            dispatch({
                type: actionTypes.FETCH_RECURRENT_ORDERS,
                payload: response.data.data
            })
            dispatch(loadingStop())
        })
        .catch(error => {
            console.log(error.response)
            toast.error("Es ist ein Fehler aufgetreten.")
            dispatch(loadingStop())
        })
}
export const actionDeleteRecurrentOrder = (data) => async dispatch => {
    getAxiosInstance(store).delete(`/items/recurrent_order/${data.id}`)
        .then((response) => {
            dispatch({
                type: actionTypes.DELETE_RECURRENT_ORDER,
                payload: data.id
            });
            // SEND EMAIL TO RESTAURANT

            sendEmail({
                templateId: "RESTAURANT_Recurrent_Order_Cancelled_By_Farmer",
                data,
                recipient: data.fk_restaurant.contact_email,
            });
            toast.info("Die Dauerbestellung wurde gelöscht.")
            dispatch(loadingStop())
        })
        .catch((error) => {
            console.log("RECURRENT ERROR DELETE ERROR", error)
            dispatch(loadingStop())
        })
}
