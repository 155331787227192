import React from 'react';
import bgImage from "../../../assets/foodhi/bg/sign_in_bg_alt.jpeg";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import FoodhiLogoApp from "../../../assets/foodhi/logo/foodhi_logo_no_bg.png";
import MDTypography from "../../../components/MDTypography";
import {Grid} from "@mui/material";
import PageLayout from "../../../examples/LayoutContainers/PageLayout";

const AppHerunterladen = () => {
    const image = require('../../../assets/foodhi/bg/signin_bg.jpg');
    return (
        <PageLayout>
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        image &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} md={9} lg={7} xl={5}>
                        <Card>
                            <MDBox
                                bgColor="linear-gradient(90deg, rgba(253,109,93,1) 0%, rgba(250,90,79,1) 100%)"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                pb={3}
                                textAlign="center"
                            >
                                <img src={FoodhiLogoApp} alt="Foodhi Logo" height={100}/>
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Wilkommen bei Foodhi!
                                </MDTypography>
                            </MDBox>

                            <MDBox pt={4} pb={3} px={3}>
                                <Grid container pb={3} sx={{display: "flex", textAlign: "center"}}>
                                    <Grid container justifyContent="center" mb={2}>
                                        <MDTypography sx={{fontSize: 18}}>
                                            Lade jetzt die Foodhi App herunter
                                        </MDTypography>
                                    </Grid>
                                    <Grid container direction="column" flex={1} mb={{xs:5, sm:1}}>
                                        <Grid item>
                                            <a href="https://apps.apple.com/de/app/foodhi/id1617683726">
                                                <img src={require('../../../assets/foodhi/others/appstore-qr.png')}
                                                     alt="Foodhi AppStore download" height={200}
                                                     style={{marginBottom: 20}}/>
                                            </a>
                                        </Grid>

                                        <Grid item>
                                            <a href="https://apps.apple.com/de/app/foodhi/id1617683726">
                                                <img src={require('../../../assets/foodhi/others/download_ios.png')}
                                                     alt="Foodhi AppStore logo" height={50}/>
                                            </a>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column" flex={1} mb={{xs:5, sm:1}}>
                                        <Grid item>
                                            <a href="https://play.google.com/store/apps/details?id=com.foodhi.foodhiapp&hl=de">
                                                <img src={require('../../../assets/foodhi/others/playstore-qr.png')}
                                                     alt="Foodhi PlayStore download" height={200}
                                                     style={{marginBottom: 20}}/>
                                            </a>
                                        </Grid>
                                        <Grid item>
                                            <a href="https://play.google.com/store/apps/details?id=com.foodhi.foodhiapp&hl=de">
                                                <img src={require('../../../assets/foodhi/others/download_playstore.png')}
                                                     alt="Foodhi PlayStore logo" height={50}/>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>
    );
};

export default AppHerunterladen;
