import React, {useEffect, useState} from 'react';
import {TagsInput} from "react-tag-input-component";
import {Box, Checkbox, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import {Spinner} from "react-bootstrap";
// HANDLE CATEGORIES

const StoreCategories = ({farmerCategories, callback}) => {

    const [allCategories, setAllCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState(farmerCategories);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAxiosInstance(store).get(`/items/farmer_categories`)
            .then(response => {
                setAllCategories(response.data.data)
            })
            .catch(error => console.log(error.response))
    }, [])


    useEffect(() => {
        if(farmerCategories){
            setSelectedCategories(farmerCategories)
        }
    }, [farmerCategories]);


    useEffect(() => {
        if(selectedCategories) {
            callback(selectedCategories)
        }
    }, [selectedCategories]);

    const handleChange = (e) => {
        setSelectedCategories(e.target.value)
    }

    const renderCategories = (selected) => {
        return (
            <Box sx={{flexDirection: "row", display: "flex", alignItems: "center", gap: 1}}>
                {selected.map(value => {
                    return (
                        allCategories.length ? (
                            <Chip variant="outlined" key={value} label={allCategories?.find(cat => cat.id === value).name}/>
                        ) : (
                            <Spinner />
                        )
                    )
                })}
            </Box>
        )
    }

    return (

            <FormControl fullWidth sx={{mt: 2}}>
                <InputLabel
                    id="lieferung">{loading ? "Laden..." : "Kategorien"}
                </InputLabel>
                <Select
                    labelId="kategorien"
                    variant="standard"
                    id="kategorien"
                    required
                    multiple
                    value={selectedCategories}
                    disabled={loading && true}
                    label={loading ? "Laden..." : "Kategorien"}
                    sx={{height: 50}}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                    renderValue={selected => (renderCategories(selected))}
                >
                    {allCategories.map(category => {
                        return (
                            allCategories.length ? (
                                    <MenuItem key={category.id} value={category.id}>
                                        <Checkbox checked={selectedCategories.includes(category.id)}/>
                                        {category.name}
                                    </MenuItem>
                                ) : (
                                    <Spinner />
                            )

                        )
                    })}
                </Select>
            </FormControl>
    );
}

export default StoreCategories;