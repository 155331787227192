import React, {useEffect, useRef, useState} from 'react';

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import {
    Cancel,
    FormatListNumbered,
    LinkOff,
    LinkSharp,
    Search
} from "@mui/icons-material";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import colors from "../../../../../assets/theme/base/colors";
import {TailSpin} from "react-loader-spinner";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import {toast} from "react-toastify";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {loadingStop} from "../../../../../reduxStore/actions/actionUI";


const DeliveryNoteCustomerSelection = (
    {
        externalApiConnection,
        externalVendorCustomerData,
        order,
        checkCreateDeliveryNote,
        setExternalVendorCustomerData,
        apiKeyNumber,
        apiKeyProvider,
        setCheckCreateDeliveryNote
    }) => {

    const searchBoxRef = useRef(null);
    const [searchContactsText, setSearchContactsText] = useState("");
    const [savedExternalCustomerData, setSavedExternalCustomerData] = useState({});
    const [hover, setHover] = useState(false);
    const [filteredExternalApiCustomers, setFilteredExternalApiCustomers] = useState([]);
    const [loadingExternalContacts, setLoadingExternalContacts] = useState(false);
    const [farmerRestaurantRelation, setFarmerRestaurantRelation] = useState("");

    const loading = useSelector(state => state.UI.loading);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log(externalVendorCustomerData, "EXTERNAL")
    }, [externalVendorCustomerData]);


    // IF EXTERNAL CUSTOMER DATA IS SET TO UNDEFINED, CHANGE IT TO NULL, SO THE SEARCH BUTTON IS NOT DISABLED
    useEffect(() => {
        if (externalVendorCustomerData === undefined) {
            setExternalVendorCustomerData(null)
        }
    }, [externalVendorCustomerData])


    // WHEN HOVERING ON CREATE LIEFERSCHEIN NOT AVAILABLE
    const onHover = () => {
        setHover(true)
    }
    const onLeave = () => {
        setHover(false)
    }

    const retreiveCustomersFromThirdParty = () => {

        const connectToExternalSoftware = (customerLexoffice, customerSevDesk) => {
            // EVALUATE WHETHER TO GET THE PREDEFINED CLIENT DATA FROM OUR OWN DATABASE, OR TO GET IT FROM THE THIRD PARTY SOFTWARE
            if (apiKeyProvider === "lexoffice") {
                getAxiosInstance(store).get(`api/get-lexoffice-contacts`, {
                    params: {
                        email: order.restaurant ? order.restaurant.contact_email : null,
                        restaurantName: order.restaurant ? order.restaurant.restaurant_name : order.fk_shipping_address.company,
                        apiKey: apiKeyNumber
                    }
                })
                    .then(response => {
                        if (response.data.content[0]) {
                            dispatch(loadingStop())
                            setFilteredExternalApiCustomers(response.data.content)

                            // CHOOSE WHICH DATA IS PRE SELECTED AND POPULATED ON THE DROWPDOWN
                            if (customerLexoffice) {
                                // IF THERES SAVED INFOMRATION ALREADY ABOUT THE CUSTOMER
                                setExternalVendorCustomerData(customerLexoffice)
                                setSavedExternalCustomerData(customerLexoffice)
                            } else {
                                // HANDLE IF NO EXTENRAL DATA IS LINKED TO FARMER-RESTAURANT RELATIOn
                                // IF THERES NOT AN EXACT MATCH FOR THAT NAME TAKE THE INFO FROM THE REPSONSE OF THE THIRD PARTY
                                if (response.data.content.length > 1) {
                                    setExternalVendorCustomerData(null)
                                    // IF THERES AN EXACT MATCH, SHOWING ONE RESULT, SET IT AS SELECTED
                                } else {
                                    setExternalVendorCustomerData(response.data.content[0])
                                }
                            }

                        }
                    })
                    .catch(error => {
                        console.log(error, "LEXOFFICE ERROR")
                        if (error.message == "Network Error") {
                            toast.error("Es gibt ein Problem beim Versuch, eine Verbindung zu Lexoffice herzustellen.")
                        }

                    })
            }

            if (apiKeyProvider === "sevdesk") {
                getAxiosInstance(store).get(`api/get-sevdesk-contacts`, {
                    params: {
                        email: order.restaurant.contact_email,
                        restaurantName: order.restaurant.restaurant_name,
                        apiKey: apiKeyNumber
                    }
                })
                    .then(response => {
                        console.log(response.data, "SEV RESPONSE")
                        // POPULATE LIST WITH ALL RESULTS
                        setFilteredExternalApiCustomers(response.data.objects)

                        if (customerSevDesk) {
                            setSavedExternalCustomerData(customerSevDesk)
                            setExternalVendorCustomerData(customerSevDesk)
                        } else {
                            // IF NO EXACT MATCH IS FOUND IN SEVDESK, IT MEANS THAT NO CONTACT WAS FOUND
                            if (response.data.objects.length > 0) {
                                setExternalVendorCustomerData(response.data.objects[0])
                            }
                            if (response.data.objects.length > 1) {
                                toast.info("Es wurde kein entsprechender Kontakt in Sevdesk gefunden. Lege erst einen Kontakt in Sevdesk an, um einen Lieferschein mit Foodhi zu erstellen.")
                            }
                        }
                    })
                    .catch(error => console.log(error))
            }
        }

        if (!order.delivery_slip) {
            // IF RESTAURANT IS PRESENT IN ORDER
            if(order.restaurant) {
                // IF THERES A CUSTOMER NAME PRE-STORED, USE THAT FOR THE INITIAL SEARCH TO LEXOFFICE CLIENTS
                getAxiosInstance(store).get(`items/farmer_profile_restaurant_profile`, {
                    params: {
                        filter: {
                            "restaurant_profile_id": {
                                "_eq": order.restaurant.id
                            }
                        }
                    }
                })
                    .then(response => {
                        setFarmerRestaurantRelation(response.data.data[0])

                        const customerLexoffice = response.data.data[0]?.lexoffice_customer;
                        const customerSevDesk = response.data.data[0]?.sevdesk_customer;

                        connectToExternalSoftware(customerLexoffice, customerSevDesk)
                    })
                    .catch(error => console.log(error))
            } else {
                connectToExternalSoftware(null, null)
            }

        }
    }

    useEffect(() => {
        if (checkCreateDeliveryNote && apiKeyNumber) {
            retreiveCustomersFromThirdParty()
        }
    }, [checkCreateDeliveryNote, apiKeyNumber]);

    const searchContacts = () => {
        if (searchContactsText.length < 3) {
            toast.error("Mindestens 3 Zeichen schreiben")
        } else {
            setLoadingExternalContacts(true)
            setSearchContactsText("")
            if (apiKeyProvider === "lexoffice") {
                getAxiosInstance(store).get(`api/search-lexoffice-contacts`, {
                    params: {
                        searchTerm: searchContactsText,
                        apiKey: apiKeyNumber
                    }
                })
                    .then(response => {
                        setLoadingExternalContacts(false)
                        if (response.data.content[0]) {
                            setFilteredExternalApiCustomers(response.data.content)
                        } else {
                            toast.error("Keine Kunden gefunden")
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        setLoadingExternalContacts(false)
                        if (error.message === "Network Error") {
                            toast.error("Es gibt ein Problem beim Versuch, eine Verbindung zu Lexoffice herzustellen.")
                        }
                    })
            }
            if (apiKeyProvider === "sevdesk") {
                getAxiosInstance(store).get(`api/get-sevdesk-contacts`, {
                    params: {
                        restaurantName: searchContactsText,
                        apiKey: apiKeyNumber
                    }
                })
                    .then(response => {
                        setLoadingExternalContacts(false)
                        console.log(response.data)
                        if (response.data.objects[0]) {
                            setFilteredExternalApiCustomers(response.data.objects)
                        } else {
                            toast.error("Keine Kunden gefunden")
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        setLoadingExternalContacts(false)
                        if (error.message === "Network Error") {
                            toast.error("Es gibt ein Problem beim Versuch, eine Verbindung zu Lexoffice herzustellen.")
                        }
                    })
            }

        }
    }


    return (
        <MDBox pb={2} px={4}>
            <MDTypography
                sx={{fontSize: 16, paddingBottom: 2, display: "flex", alignItems: "center", gap: 1}}
                fontWeight="bold">
                <FormatListNumbered/> Lieferschein erstellen
            </MDTypography>

            <Grid
                container
                // gap={2}
                sx={{alignItems: "center", display: "flex", flexDirection: "row"}}
            >
                {
                    externalApiConnection ? (
                        <div style={{
                            flex: 8,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                        >
                            {/*IF THERE IS NO EXISTING DELIVERY NOTE*/}
                            {
                                !order.delivery_slip ? (
                                    <>
                                        {/*CHECKBOX CREATE OR NOT DELIVERY NOTE*/}
                                        <FormControlLabel
                                            sx={{flex: 3}}
                                            control={
                                                <Checkbox checked={checkCreateDeliveryNote}
                                                          onChange={() => {
                                                              if (checkCreateDeliveryNote) {
                                                                  if (confirm("Möchtest du wirklich keinen Lieferschein erstellen?")) {
                                                                      setCheckCreateDeliveryNote(!checkCreateDeliveryNote)
                                                                  }
                                                              } else {
                                                                  setCheckCreateDeliveryNote(!checkCreateDeliveryNote)
                                                              }
                                                          }}
                                                          inputProps={{'aria-label': 'controlled'}}
                                                />
                                            }
                                            label="Lieferschein"
                                        />

                                        {/*DROPDOWN SELECT CUSTOMER FROM EXTERNAL ACCOUNTING SOFTWARE*/}
                                        {
                                            (JSON.stringify(savedExternalCustomerData) !== "{}" && savedExternalCustomerData)
                                                ? (
                                                    <MDTypography variant="span" color="success" sx={{fontSize: 14, fontWeight: 500}}
                                                                  flex={8}>
                                                        {/*IF RESTAURANT IS LINKED TO FARMER, SHOW THE LINKED NAME IN GREEN*/}
                                                        {
                                                            apiKeyProvider === "lexoffice"
                                                                ? savedExternalCustomerData.company
                                                                    ? `${savedExternalCustomerData.company?.name} (#${savedExternalCustomerData.roles?.customer?.number})`
                                                                    : `${savedExternalCustomerData.person?.firstName} ${savedExternalCustomerData.person?.lastName} (#${savedExternalCustomerData.roles?.customer?.number})`
                                                                // IF ITS SEVDESK
                                                                : `${savedExternalCustomerData?.name} (#${savedExternalCustomerData?.customerNumber})`
                                                        }
                                                    </MDTypography>
                                                ) : (
                                                    // IF NO RESTAURANT LINKED YET, RENDER DROPDOWN OF EXTERNAL SOFTWARE
                                                    checkCreateDeliveryNote && (
                                                        <FormControl
                                                            variant="outlined"
                                                            fullWidth
                                                            sx={{flex: 8}}
                                                        >
                                                            <InputLabel id="select-customer">Kunde</InputLabel>
                                                            <Select
                                                                labelId="select-customer"
                                                                id="select-customer"
                                                                value={externalVendorCustomerData}
                                                                label="Kunde"
                                                                style={{minHeight: "44px"}}
                                                                MenuProps={{
                                                                    style: {
                                                                        maxHeight: 400
                                                                    }
                                                                }}
                                                                onChange={(e) => setExternalVendorCustomerData(e.target.value)}
                                                            >
                                                                {/*DROPDOWN FROM THE SELECT*/}
                                                                <MDBox
                                                                    display={"flex"}
                                                                    alignItems="center" py={2}
                                                                    onKeyDown={(e) => {
                                                                        e.stopPropagation();
                                                                        if (e.key === "Enter") {
                                                                            searchContacts()
                                                                        }
                                                                    }}
                                                                >
                                                                    {/*INPUT BOX*/}
                                                                    <Input
                                                                        ref={searchBoxRef}
                                                                        type="text"
                                                                        label={"Suchen"}
                                                                        style={{marginLeft: 15, zIndex: 3, flex: 1}}
                                                                        disabled={loading && true}
                                                                        onChange={e => setSearchContactsText(e.target.value)}
                                                                        value={searchContactsText}
                                                                    />

                                                                    {/*BUTTON SEARCH*/}
                                                                    <MDBox
                                                                        onClick={searchContacts}
                                                                        padding={1}
                                                                        color={colors.foodhiOrange.main}
                                                                        borderRadius={10}
                                                                        textAlign="center"
                                                                        height={40}
                                                                        width={40}
                                                                    >
                                                                        <Search fontSize="medium"
                                                                                alignItems={"center"}
                                                                                cursor={"pointer"}/>
                                                                    </MDBox>
                                                                </MDBox>

                                                                {apiKeyProvider === "lexoffice" &&
                                                                    <MenuItem
                                                                        key={null}
                                                                        value={null}
                                                                    >
                                                                        Kein Kunde
                                                                    </MenuItem>
                                                                }

                                                                {/*RETRIEVE CUSTOMERS FORM API CONNECTION EXTERNAL VENDOR ACCOUNTING SOFTWARE*/}
                                                                {
                                                                    loadingExternalContacts
                                                                        ? <TailSpin height={40}
                                                                                    wrapperStyle={{padding: "20px 0"}}/>
                                                                        : filteredExternalApiCustomers?.map(customer => {
                                                                                return (
                                                                                    <MenuItem key={customer.id}
                                                                                              value={customer}>
                                                                                        {
                                                                                            apiKeyProvider === "lexoffice"
                                                                                                ? customer.company
                                                                                                    ? `${customer.company?.name} (#${customer.roles.customer?.number})`
                                                                                                    : `${customer.person?.firstName} ${customer.person?.lastName} (#${customer.roles.customer?.number})`
                                                                                                // IF ITS SEVDESK
                                                                                                : customer.name
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        )
                                                                }
                                                            </Select>
                                                        </FormControl>

                                                    )
                                                )

                                        }
                                        <MDBox sx={{flex: 1}}/>
                                    </>
                                    // IF DELIVERY NOTE ALREADY EXISTS FOR THIS ORDER
                                ) : (
                                    <MDBox sx={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: 1,
                                        position: "relative"
                                    }} onMouseEnter={onHover} onMouseLeave={onLeave}>
                                        <Cancel sx={{color: "gray"}}/>
                                        <MDTypography sx={{fontSize: 14}}>Lieferschein existiert
                                            bereits.</MDTypography>
                                        {hover &&
                                            <MDBox coloredShadow="error" sx={{
                                                position: "absolute",
                                                bottom: 40,
                                                left: 20,
                                                backgroundColor: "#ffc8c8",
                                                zIndex: 2,
                                                padding: 1,
                                                borderRadius: 1,
                                            }}>
                                                <MDTypography sx={{fontSize: 12}}>
                                                    Lieferschein existiert bereits.
                                                </MDTypography>
                                            </MDBox>}
                                    </MDBox>
                                )
                            }

                        </div>
                    ) : (
                        <MDBox sx={{
                            display: "flex",
                            flex: 2,
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                            position: "relative"
                        }} onMouseEnter={onHover} onMouseLeave={onLeave}>
                            {/*<Cancel sx={{color: "gray"}}/>*/}
                            <img
                                src={`${process.env.REACT_APP_SITE_URL}/assets/53d45365-b315-4b16-bc0d-9d18ce5e492f?height=40&width=40`}
                                alt="Foodhi logo"
                                height={20}
                                width={20}
                                style={{borderRadius: 100}}
                            />
                            <MDTypography sx={{fontSize: 14}}>Lieferschein durch Foodhi erstellt</MDTypography>
                            {hover &&
                                <MDBox coloredShadow="error" sx={{
                                    position: "absolute",
                                    bottom: 40,
                                    left: 20,
                                    backgroundColor: "#ffc8c8",
                                    zIndex: 2,
                                    padding: 1,
                                    borderRadius: 1,
                                }}>
                                    <MDTypography sx={{fontSize: 12}}>
                                        Keine API-Verbindung gefunden.
                                    </MDTypography>
                                </MDBox>}
                        </MDBox>
                    )
                }


                {/*LINK BUTTON*/}
                {
                    externalApiConnection && (
                        order.restaurant && (
                            !order.delivery_slip && (
                                <MDBox flex={3}>
                                    {
                                        (!savedExternalCustomerData || JSON.stringify(savedExternalCustomerData) === '{}')
                                            ? (
                                                <MDBox
                                                    variant="contained"
                                                    sx={{cursor: "pointer"}}
                                                    onClick={() => {
                                                        // SAVE NAME OF KUNDE FROM LEXOFFICE IN FARMER-RESTAURANT RELATIONSHIP TABLE
                                                        getAxiosInstance(store).patch(`items/farmer_profile_restaurant_profile/${farmerRestaurantRelation.id}`, {
                                                            ...apiKeyProvider === "lexoffice" && {lexoffice_customer: externalVendorCustomerData},
                                                            ...apiKeyProvider === "sevdesk" && {sevdesk_customer: externalVendorCustomerData}

                                                        })
                                                            .then(response => {
                                                                retreiveCustomersFromThirdParty()
                                                            })
                                                            .catch(error => console.log(error))
                                                    }}
                                                >
                                                    <MDBox sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1}}>
                                                        <LinkSharp
                                                            sx={{color: "#3D3D3D", cursor: "pointer"}}
                                                            fontSize="medium"
                                                        />
                                                        <MDTypography
                                                            variant="span"
                                                            sx={{
                                                                fontSize: 16,
                                                                color: "#3D3D3D",
                                                                transition: "0.3s",
                                                                "&:hover": {
                                                                    color: colors.foodhiOrange.main,
                                                                }
                                                            }}
                                                            textTransform="uppercase"
                                                        >
                                                            Verknüpfen
                                                        </MDTypography>

                                                    </MDBox>
                                                </MDBox>

                                            ) : (
                                                (JSON.stringify(savedExternalCustomerData) !== "{}" && savedExternalCustomerData) && (
                                                    <MDBox
                                                        variant="contained"
                                                        sx={{cursor: "pointer"}}
                                                        onClick={() => {
                                                            setExternalVendorCustomerData(null)
                                                            getAxiosInstance(store).patch(`items/farmer_profile_restaurant_profile/${farmerRestaurantRelation.id}`, {
                                                                ...apiKeyProvider === "lexoffice" && {lexoffice_customer: null},
                                                                ...apiKeyProvider === "sevdesk" && {sevdesk_customer: null}
                                                            })
                                                                .then(() => {
                                                                    setSavedExternalCustomerData(null)
                                                                    retreiveCustomersFromThirdParty()
                                                                })
                                                                .catch(error => console.log(error))
                                                        }}
                                                    >
                                                        <MDBox sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1}}>
                                                            <LinkOff
                                                                sx={{color: "#3D3D3D", cursor: "pointer"}}
                                                                fontSize="medium"
                                                            />
                                                            <MDTypography
                                                                variant="span"
                                                                sx={{
                                                                    fontSize: 16,
                                                                    color: "#3D3D3D",
                                                                    transition: "0.3s",
                                                                    "&:hover": {
                                                                        color: colors.foodhiOrange.main,
                                                                    }
                                                                }}
                                                                textTransform="uppercase"
                                                            >
                                                                Trennen
                                                            </MDTypography>

                                                        </MDBox>
                                                    </MDBox>

                                                )
                                            )
                                    }
                                </MDBox>
                            )
                        )
                    )

                }


            </Grid>
        </MDBox>
    );
};

export default DeliveryNoteCustomerSelection;
