import * as actionTypes from '../actions/types';

const initialState = {
    loading: false,
    ordersList: [],
    error: ''
}

const ordersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ORDERS_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FETCH_ORDERS_SUCCEEDED:
            return {
                loading: false,
                ordersList: action.payload,
                totalPages: action.totalPages,
                page: action.page,
                error: ''
            }
        case actionTypes.FETCH_ORDERS_FAILED:
            return {
                loading: false,
                ordersList: [],
                error: action.payload
            }
        default:
            return state;
    }
}

export default ordersReducer;