import React, {useEffect, useRef, useState} from 'react';
import {Avatar, Box, Divider, Typography} from "@mui/material";

// LIST IMPORTs
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

// THEME IMPORTS
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {AccountCircleOutlined, Api, RestaurantTwoTone, UploadRounded} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import ContentBusinessSettings from "./settingsSections/BusinessSettings/ContentBusinessSettings";
import ContentMyProfile from "./settingsSections/ProfileSettings/ContentMyProfile";
import {FOLDER_STORE_AVATARS, THUMBNAIL_SETTINGS, USER_PROFILE_PLACEHOLDER} from "../../variables";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../../reduxStore/store";
import {changeStoreThumbnail} from "../../reduxStore/actions/actionUserProfile";
import {toast} from "react-toastify";
import {loadingStart, loadingStop} from "../../reduxStore/actions/actionUI";
import ApiConnections from "./settingsSections/apiConnections/ApiConnections";
import {Spinner} from "react-bootstrap";

const ProfileIndex = () => {

    const thumbnail = useSelector(state => state.userProfile.store_thumbnail);
    const store_name = useSelector(state => state.userProfile.store_name);
    const store_id = useSelector(state => state.userProfile.id);
    const [showImageUploadButton, setShowImageUploadButton] = useState(false);
    const [avatar, setAvatar] = useState('');

    const loading = useSelector(state => state.UI.loading);

    const dispatch = useDispatch();

    useEffect(() => {
        if (thumbnail) {
            setAvatar(`${process.env.REACT_APP_SITE_URL}/assets/${thumbnail.id}?${THUMBNAIL_SETTINGS}`);
        } else setAvatar(`${process.env.REACT_APP_SITE_URL}/assets/${USER_PROFILE_PLACEHOLDER}`);
    }, [thumbnail]);


    const [switchContentDisplay, setSwitchContentDisplay] = useState(0);

    const renderProfileSection = () => {
        switch (switchContentDisplay) {
            case 0:
                return <ContentMyProfile/>;
            case 1:
                return <ContentBusinessSettings/>;
            case 2:
                return <ApiConnections/>;
            default:
                return;
        }
    }

    // AVATAR UPLOAD SECTION
    const avatarUploadButton = useRef(null);
    const handleAvatarUpload = () => {
        avatarUploadButton.current.click();
    }
    const handleUploadClick = () => {
        const formData = new FormData();

        formData.append('title', `Thumbnail_${store_name}`);
        formData.append('folder', FOLDER_STORE_AVATARS);
        formData.append('file', avatarUploadButton.current.files[0]);

        dispatch(loadingStart())

        // FIRST UPLOAD IT TO THE BACKEND STORAGE AND THEN LINK IT TO THE USER COLLECTION
        getAxiosInstance(store).post(`/files`, formData)
            .then(response => {
                const data = { store_thumbnail: response.data.data.id }
                getAxiosInstance(store).patch(`/items/farmer_profile/${store_id}?fields=store_thumbnail.*`, data)
                    .then(response => {
                        dispatch(loadingStop())
                        const {data} = response.data;
                        dispatch(changeStoreThumbnail(data))
                    })
                    .catch(error => {
                        dispatch(loadingStop())
                        console.log(error)
                        toast.error("Ein Fehler ist aufgetreten.")
                    })
            })
            .catch(error => {
                dispatch(loadingStop())
                console.log(error)
                toast.error("Ein Fehler ist aufgetreten.")
            })
    }

    const ProfileNavbar = () => (
        <nav aria-label="main mailbox folders">
            <List style={{marginBottom: 20}}>
                <ListItem disablePadding sx={{justifyContent: "center"}}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                        onMouseEnter={() => setShowImageUploadButton(true)}
                        onMouseLeave={() => setShowImageUploadButton(false)}
                        onClick={handleAvatarUpload}
                    >
                        <Box sx={{
                            position: "absolute",
                            textAlign: "center",
                            zIndex: 2,
                            display: loading ? "none" : "block",
                            opacity: showImageUploadButton ? 1 : 0
                        }}>
                            <UploadRounded/>
                            <Typography fontSize={14}>Hochladen</Typography>
                        </Box>
                        {
                            !loading ? (
                                <Avatar
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        boxShadow: "-1px 10px 22px -9px rgba(0,0,0,0.19)",
                                        opacity: showImageUploadButton ? 0.4 : 1
                                    }}
                                    alt="Store Logo"
                                    src={avatar}
                                />
                            ) : <div style={{display: "flex", height: 100, width: 100,alignItems: "center", justifyContent: "center"}}><Spinner /></div>
                        }

                    </Box>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                    <ListItemButton style={{height: 40}} onClick={() => setSwitchContentDisplay(0)}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <AccountCircleOutlined sx={{marginRight: 2}}/>
                            <span style={{fontWeight: 300, fontSize: "0.875rem", lineHeight: 0}}>
                                Kontaktdetails
                            </span>
                        </div>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton style={{height: 40}} onClick={() => setSwitchContentDisplay(1)}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <RestaurantTwoTone sx={{marginRight: 2}}/>
                            <span style={{fontWeight: 300, fontSize: "0.875rem", lineHeight: 0}}>
                                Geschäftsdetails
                            </span>
                        </div>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton style={{height: 40}} onClick={() => setSwitchContentDisplay(2)}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <Api sx={{marginRight: 2}}/>
                            <span style={{fontWeight: 300, fontSize: "0.875rem", lineHeight: 0}}>
                                API
                            </span>
                        </div>
                    </ListItemButton>
                </ListItem>
            </List>
        </nav>
    );

    return (
        <DashboardLayout>
            <div style={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
                alignItems: "stretch",
                alignContent: "stretch",
                marginBottom: "5rem"
            }}>
                <DashboardNavbar/>
                <div className="container h-100">
                    <div className="row pt-5"
                         style={{
                             maxWidth: 1200,
                             background: "#fff",
                             padding: "20px 10px",
                             height: "100%",
                             borderRadius: 8
                         }}>
                        {/*LEFT DASHBOARD*/}
                        <div className="col-md-3">
                            <ProfileNavbar/>

                            {/*HIDDEN INPUT FOR AVATAR UPLOAD */}
                            <input
                                id="contained-button-file"
                                type="file"
                                hidden
                                ref={avatarUploadButton}
                                onChange={handleUploadClick}
                            />
                        </div>


                        {/*RIGHT DASHBOARD*/}
                        <div className="col-md-9">
                            <div style={{flexDirection: "row"}}>
                                {renderProfileSection()}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </DashboardLayout>
    );
};

export default ProfileIndex;