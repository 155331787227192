import * as actionTypes from '../actions/types';

const initialState = [];

const cartReducer = (state = {cartItems: initialState}, action) => {

        //1. Received item from the action
        const item = action.payload;

        switch (action.type) {
            case actionTypes.ADD_TO_CART:
                // IF PRODUCT IS ALREADY IN CART
                const existInCart = state.cartItems.find(i => i.item.id === item.product.id);

                if (existInCart) {
                    return {
                        ...state,
                        cartItems: state.cartItems.map(i =>
                            i.item.id === item.product.id
                                ? {
                                    ...i,
                                    quantity: item.quantity,
                                    price: item.discount > 0 ? Number((item.product.price * (1 - item.discount / 100)).toFixed(3)) : Number(item.product.price.toFixed(3)),
                                    discount: item.discount,
                                }
                                : i
                        )
                    }
                }

                // IF PRODUCT IS NOT IN CART YET, ADD IT
                else {
                    return {
                        ...state,
                        cartItems: [...state.cartItems,
                            {
                                item: item.product,
                                quantity: item.quantity,
                                price: item.discount > 0 ? Number((item.product.price * (1 - item.discount / 100)).toFixed(3)) : Number(item.product.price.toFixed(3)),
                                discount: item.discount
                            }]
                    }
                }

            case actionTypes.REMOVE_FROM_CART:
                return {
                    ...state,
                    cartItems: item.item
                        ? state.cartItems.filter(i => i.item.id !== item.item.id)
                        : state.cartItems.filter(i => i.item.id !== item.id)
                }

            case actionTypes.PLACE_ORDER:
                return {...state, cartItems: []}

            case actionTypes.EMPTY_CART:
                return {
                    ...state,
                    // cartItems: state.cartItems.filter(i => i.item.store.vendor_id !== action.vendor_id)
                    cartItems: []
                }

            default:
                return state;
        }
    }
;

export default cartReducer;
