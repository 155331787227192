import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import {Avatar} from "@mui/material";
import {THUMBNAIL_SETTINGS} from "../../../../../variables";
import {Inventory2Outlined} from "@mui/icons-material";

function OrderProductsList({order}) {
    function createData(id, thumbnail, name, updated_subtotal, unit_amount, measurement, quantity, updated_product_amount, note, total) {
        return {
            thumbnail,
            name,
            updated_subtotal,
            unit_amount,
            measurement,
            quantity,
            updated_product_amount,
            note,
            total
        };
    }

    function createPackagingData(name, amount) {
        return {name, amount}
    }

    const rows = order.fk_order_items.map((item) => {
            return (
                createData(
                    item.id,
                    item.images[0],
                    item.name,
                    item.updated_subtotal,
                    item.unit_amount,
                    item.measurement,
                    item.quantity,
                    item.updated_product_amount,
                    item.note,
                    (item.updated_subtotal * item.quantity).toLocaleString("de-DE")
                )
            )
        }
    );

    const packaging = order.packaging?.map((item) => {
            return (
                createPackagingData(
                    item.name,
                    item.amount
                )
            )
        }
    );

    return (
        <TableContainer component={Paper} style={{boxShadow: "none"}}>
            <MDBox
                display="flex"
                justifyContent="space-between"
                pt={2}
                pb={2}
                px={2}
            >
                <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    textTransform="capitalize"
                >
                    Bestellte Produkte
                </MDTypography>
            </MDBox>
            <Table
                sx={{minWidth: 780, marginBottom: 5}}
                aria-label="Product details"
            >
                <TableHead sx={{display: "table-header-group"}}>
                    <TableRow>
                        <TableCell>
                            <MDTypography fontWeight="bold" color="text" sx={{fontSize: 16}}>
                                Name
                            </MDTypography>
                        </TableCell>
                        <TableCell align="left">
                            <MDTypography fontWeight="bold" color="text" sx={{fontSize: 16}}>
                                Menge
                            </MDTypography>
                        </TableCell>
                        <TableCell align="left">
                            <MDTypography fontWeight="bold" color="text" sx={{fontSize: 16}}>
                                Gesamtinhalt
                            </MDTypography>
                        </TableCell>
                        <TableCell align="left">
                            <MDTypography fontWeight="bold" color="text" sx={{fontSize: 16}}>
                                Gesamtpreis
                            </MDTypography>
                        </TableCell>
                        <TableCell align="left">
                            <MDTypography fontWeight="bold" color="text" sx={{fontSize: 16}}>
                                Angabe
                            </MDTypography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => {
                        return (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Avatar sx={{marginRight: 1.5}} sizes="sm"
                                                src={`${process.env.REACT_APP_SITE_URL}/assets/${row.thumbnail}?${THUMBNAIL_SETTINGS}`}/>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {`${row.name}`}
                                        </MDTypography>
                                    </div>
                                </TableCell>
                                <TableCell align="left">

                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        {row.quantity} x {row.unit_amount} {row.measurement}
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left">
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        {row.measurement.id !== 6 //the ID for "Stück"
                                            ? `${row.updated_product_amount} ${row.measurement}`
                                            : "-"
                                        }
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left">
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        € {(row.quantity * row.updated_subtotal).toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left">
                                    <MDTypography fontWeight="regular" color="text" sx={{fontSize: 14, whiteSpace: "pre-line"}}>
                                        {row.note}
                                    </MDTypography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    {packaging && packaging.map(row => {
                        return (
                            <TableRow key={row.id}>
                                <TableCell>
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <Inventory2Outlined sx={{margin: 1, marginRight: 2.5}} fontSize="medium"/>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            {`${row.name}`}
                                        </MDTypography>
                                    </div>
                                </TableCell>
                                <TableCell align="left">

                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        {row.amount} Stück
                                    </MDTypography>
                                </TableCell>
                                <TableCell align="left">

                                </TableCell>
                                <TableCell align="left">
                                    <MDTypography variant="button" fontWeight="regular" color="text">
                                        € 0,00
                                    </MDTypography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrderProductsList;