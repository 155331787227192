import React, {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate, useSearchParams} from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/foodhi/bg/sign_in_bg_alt.jpeg";
import FoodhiLogoApp from "assets/foodhi/logo/foodhi_logo_no_bg.png";

import {TailSpin} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import {Icon} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {authLogin} from "../../reduxStore/actions/actionAuth";
import {loadingStart, loadingStop} from "../../reduxStore/actions/actionUI";

function LogIn() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [passwordShown, setPasswordShown] = useState(false);
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth.loggedIn);
    const loading = useSelector(state => state.UI.loading);

    const [searchQuery, setSearchQuery] = useSearchParams();

    // IF AUTHENTICATED, REDIRECT TO DASHBOARD INDEX
    useEffect(() => {
        if (auth) {
            if(searchQuery.get("redirect-restaurant-connection") === "true")  {
                navigate(`/invite-farmer?connectionId=${searchQuery.get("connectionId")}`);
            } else {
                navigate("/dashboard");
            }
        }
    }, [auth]);

    const handleLogin = async () => {
        if (email.length > 0 && password.length > 0) {
            try {
                dispatch(loadingStart())
                dispatch(authLogin(email, password))
            } catch ({response}) {
                toast.error("Du hast einen falschen Benutzernamen oder Passwort eingegeben.");
                dispatch(loadingStop())
            }
        } else {
            toast.error('E-Mail und Passwort kann nicht leer sein.',
                {position: toast.POSITION.TOP_CENTER}
            );
        }

    };

    const handlePressEnter = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    }

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    bgColor="linear-gradient(90deg, rgba(253,109,93,1) 0%, rgba(250,90,79,1) 100%)"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    pb={3}
                    textAlign="center"
                >
                    <img src={FoodhiLogoApp} alt="Foodhi Logo" height={100}/>
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Anmelden
                    </MDTypography>

                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput
                                type="email"
                                label="E-Mail"
                                fullWidth
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput
                                type={passwordShown ? "text" : "password"}
                                label="Passwort"
                                fullWidth
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                onKeyDown={handlePressEnter}
                            />
                            <IconButton
                                size="medium"
                                sx={{
                                    position: "absolute",
                                    right: 30,
                                    height: 44
                                }}
                                disableRipple
                                mr={5}
                                // sx={navbarMobileMenu}
                                onClick={() => setPasswordShown(!passwordShown)}
                            >
                                <Icon fontSize="small" color="darkgray">
                                    {passwordShown ? "visibility_off" : "visibility"}
                                </Icon>
                            </IconButton>
                        </MDBox>

                        <MDBox mt={4} mb={1}>
                            <MDButton
                                variant="gradient"
                                color="foodhiOrange"
                                fullWidth
                                onClick={handleLogin}
                                disabled={loading}
                            >
                                {!loading ? "anmelden" : <TailSpin color="#fff" height={20}/>}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>

    );
}

export default LogIn;
