import React, {useEffect, useState} from 'react';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {TailSpin} from "react-loader-spinner";

import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import gradients from "../../../assets/theme/base/colors";
import Icon from "@mui/material/Icon";
import {Typography} from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChartFoodhi = ({loading, orders}) => {

    const [pieChartData, setPieChartData] = useState({});

    useEffect(() => {
        // PREPARE DATA FOR PIE CHART
        const inProgress = orders.filter(item => item.fk_order_status === 1).length;
        const shipped = orders.filter(item => item.fk_order_status === 2).length;

        setPieChartData({
            labels: ["In Bearbeitung", "Im Versand"],
            datasets: [
                {
                    label: "Status",
                    data: [inProgress, shipped],
                    backgroundColor: [
                        'rgba(254, 83, 58, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                    ],
                    borderColor: [
                        'rgba(254, 83, 58, 1)',
                        'rgba(75, 192, 192, 1)',
                    ]
                }
            ]
        })
    }, [orders])

    const renderPieChart = () => {

        if (!loading) {
            return (
                <Card>
                    <MDBox py={2} pr={2} pl={1}>
                        <MDBox display="flex" px={1} pt={1}>
                            <MDBox
                                width="4rem"
                                height="4rem"
                                // bgColor={icon.color || "info"}
                                variant="gradient"
                                coloredShadow={"info"}
                                style={{backgroundColor: gradients.foodhiLightGreen.main}}
                                borderRadius="xl"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                color="white"
                                mt={-5}
                                mr={2}
                            >
                                <Icon fontSize="medium">local_shipping</Icon>
                            </MDBox>
                            <MDBox mt={-2}>
                                <Typography variant="h6">Aktive Bestellungen</Typography>
                                <MDBox mb={2}>
                                    <Typography component="div" variant="button" color="text" textTransform="capitalize" color="gray">
                                        Bestellungsstatus
                                    </Typography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                        <MDBox pb={5} sx={{alignItems: "center", justifyContent: "center", display: "flex", minHeight: 305}}>
                            {
                                orders && (
                                    orders.find(order => order.fk_order_status === 2 || order.fk_order_status === 1)
                                        ? (
                                            <Pie
                                                options={{animation: true}}
                                                style={{height: "100%", width: "auto", maxHeight: 265}}
                                                data={pieChartData}
                                            />
                                        )
                                        : (
                                            <Typography variant="h6" textTransform="capitalize" style={{textAlign: "center"}}>
                                                Keine data
                                            </Typography>
                                        )
                                    )

                            }

                        </MDBox>
                    </MDBox>
                </Card>
            )
        }
        return <TailSpin/>

    }

    return (
        <Card sx={{height: "100%"}}>
            {renderPieChart()}
        </Card>
    )
}

export default PieChartFoodhi;

