/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import OrderModal from "./Components/OrderModal";
import OrderStatusComponent from "./Components/OrderStatusComponent";
import {getAxiosInstance} from "../../../../utils/interceptor";
import store from "../../../../reduxStore/store";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import {fetchOrders} from "../../../../reduxStore/actions/actionOrders";
import {orderStatusList} from "../../../../variables";


export default function ordersDataTable() {
    const orders = useSelector((state) => state.orders.ordersList);
    const farmerId = useSelector((state) => state.userProfile.id);

    const dispatch = useDispatch();
    //
    // useEffect(() => {
    //     dispatch(fetchOrders(false, farmerId))
    // }, []);

    function formatDate(order, dataType, index) {
        if (dataType === "date_created") {
            const dateCreated = new Date(order.date_created);
            return dateCreated.toLocaleDateString("fr-CH")
        } else if (dataType === "delivery_date") {
            const yyyy = order.delivery_date.split("-")[0]
            const mm = order.delivery_date.split("-")[1]
            const dd = order.delivery_date.split("-")[2]

            const delivery_date = new Date(`${yyyy}/${mm}/${dd}`);
            const formatted_delivery_date = delivery_date.toLocaleDateString("fr-CH")

            return (
                <div style={{display: "flex", alignItems: "center"}}>
                    <LocalShippingOutlinedIcon color="inherit" fontSize="inherit" style={{marginRight: 10}}/>
                    {formatted_delivery_date}
                </div>
            )
        }
    }

    if (orders) {
        return {
            columns: [
                {Header: "details", accessor: "open", width: "8%", align: "center"},
                {Header: "id", accessor: "id", width: "8%", align: "left"},
                {Header: "liefertermin", accessor: "delivery", align: "left", width: "18%"},
                {Header: "status", accessor: "status", align: "center"},
                {Header: "gesamt (inkl. Versand)", accessor: "total", width: "10%", align: "left"},
                {Header: "kunde", accessor: "client", align: "left"},
                {Header: "bestelldatum", accessor: "date", align: "left", width: "18%"},
            ],

            rows: orders.map((order, index) => ({
                open: <OrderModal order={order}/>,
                id: order.id,
                delivery: formatDate(order, "delivery_date"),
                status: <OrderStatusComponent order={order}/>,
                total: `€ ${order.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}`,
                client: <div style={{maxWidth: 200}}>{order.restaurant
                    ? order.restaurant.restaurant_name
                    : order.fk_shipping_address.company
                        ? order.fk_shipping_address.company
                        : "N/A"
                }</div>,
                date: formatDate(order, "date_created"),
            })),
        };
    }

}
