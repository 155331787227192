/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================
 */
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, Typography, Grid, Card, Icon, Paper, TableContainer} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {TailSpin} from "react-loader-spinner";
import colors from "../../assets/theme/base/colors";
import MDButton from "../../components/MDButton";
import {fetchCustomers} from "../../reduxStore/actions/actionCustomers";
import CreateCustomerInvitation from "./Components/inviteCustomers";
import CustomersList from "./CustomersList";
import PendingInvites from "./Components/PendingInvites";


const CustomerList = () => {
    const [modalVisible, setModalVisible] = useState(false);

    const dispatch = useDispatch();

    const isLoading = useSelector(state => state.customers.loading);
    const linkedCustomers = useSelector(state => state.customers.linkedCustomers);
    const storeId = useSelector(state => state.userProfile?.id);

    const refreshCustomerList = () => {
        dispatch(fetchCustomers(storeId));
    }

    useEffect(() => {
        refreshCustomerList();
    }, []);


    // CLOSE MODAL
    function handleDismissModal() {
        setModalVisible(!modalVisible)
    }

    function renderCustomerList() {

        const inviteButton = () => (
            <MDBox px={4} py={2} textAlign="right">
                <MDButton
                    variant="gradient"
                    color="foodhiOrange"
                    onClick={() => setModalVisible(!modalVisible)}
                    sx={{
                        borderRadius: "50%",
                        width: 40,
                        height: 40,
                        minWidth: 0,
                        padding: 0,
                        marginRight: window.innerWidth < 1200 ? 0 : "2rem"
                    }}
                >
                    <Icon sx={{fontWeight: "bold"}}>person_add</Icon>
                    {/* &nbsp;add product */}
                </MDButton>
            </MDBox>
        )

        if (linkedCustomers && linkedCustomers.length) {
            return (
                <>
                    {inviteButton()}
                    <TableContainer component={Paper}>
                        <CustomersList customers={linkedCustomers}/>
                    </TableContainer>
                </>
            )
        }
        return (
            <>
                {inviteButton()}
                <Typography p={5} textAlign="center" style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "400px",
                    paddingTop: 0
                }}>
                    Lade deinen ersten Kunden ein
                </Typography>
            </>

        )
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <MDBox pt={6} pb={4}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="foodhiLightGreen"
                                    borderRadius="lg"
                                    coloredShadow="dark"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Kunden
                                    </MDTypography>
                                </MDBox>

                                <MDBox pt={1}>
                                    {
                                        !isLoading
                                            ? renderCustomerList()
                                            : (
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    minHeight: "400px",
                                                }}>
                                                    <TailSpin color={colors.foodhiOrange.main}/>
                                                </div>
                                            )
                                    }
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>

                <PendingInvites storeId={storeId} linkedCustomers={linkedCustomers}/>
                <Footer/>
            </DashboardLayout>


            <Modal
                open={modalVisible}
                onClose={!modalVisible}
                aria-labelledby="Kunden einladen"
                aria-describedby="Test"
                style={{overflowY: "auto"}}
            >
                <MDBox pt="5rem" pb={3} width={window.innerWidth * 0.75} margin="auto" sx={{maxWidth: 400}}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={2}
                                    px={2}
                                    variant="gradient"
                                    bgColor="foodhiLightGreen"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Kunden einladen
                                    </MDTypography>
                                    <Button
                                        color={colors.foodhiOrange.main}
                                        variant="gradient"
                                        onClick={() => setModalVisible(!modalVisible)}
                                    >
                                        <Icon
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#fff",
                                            }}
                                            fontSize="16"
                                        >cancel</Icon>
                                    </Button>

                                </MDBox>
                                <MDBox pt={3}>
                                    <CreateCustomerInvitation
                                        dismissModal={handleDismissModal}
                                        handleRefreshCustomers={refreshCustomerList}
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </Modal>
        </>
    );
}

export default CustomerList;
