/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */
import React from "react";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {Grid, Icon, Typography} from "@mui/material";
import MDBox from "../../components/MDBox";
import image from "../../assets/foodhi/bg/signin_bg.jpg";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";

function Datenschutzerklaerung() {

    return (
        <PageLayout>
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        image &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} md={9} lg={7} xl={8} mt={10} my={10}>
                        <Card>
                            <MDBox
                                bgColor="linear-gradient(90deg, rgba(253,109,93,1) 0%, rgba(250,90,79,1) 100%)"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                pb={3}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Datenschutzerklärung
                                </MDTypography>
                            </MDBox>
                            <MDBox p={4}>
                                <h1 style={{fontSize: 28}}>DEUTSCH</h1>
                                <br/>

                                <h1 style={{fontSize: 26}}>Datenschutzerklärung – Foodhi</h1>
<br/>
                                <h3 style={{fontSize: 22}}>Entwickler/Unternehmen: Foodhi</h3>

                                <p>Diese Datenschutzerklärung dient als klare Kennzeichnung und gilt für die Erhebung, Verwendung, Offenlegung und den Schutz von Benutzerdaten durch die Foodhi-App.</p>

                                <h2 style={{fontSize: 22}}>1. Informationen, die wir erheben</h2>

                                <p>Die Foodhi-App erhebt und greift auf folgende Arten von persönlichen und sensiblen Benutzerdaten zu:</p>

                                <ul>
                                    <li>Kontaktinformationen: wie Name, Adresse, E-Mail-Adresse und Telefonnummer.</li>
                                    <li>Lieferinformationen: einschließlich Lieferadresse und etwaiger spezieller Anweisungen.</li>
                                    <li>Bestelldetails: wie die bestellten Produkte, Mengen und etwaige besondere Anforderungen oder Vorlieben.</li>
                                    <li>Kommunikationsinformationen: jegliche Korrespondenz mit Benutzern, einschließlich Kunden-Support-Anfragen und Feedback.</li>
                                </ul>

                                <h2 style={{fontSize: 22}}>2. Verwendung und Weitergabe von Daten</h2>

                                <p>Foodhi-App verwendet und teilt Benutzerdaten zu folgenden Zwecken:</p>

                                <ul>
                                    <li>Bestellabwicklung: um Produktbestellungen auszuführen, Lieferungen zu verwalten und Kundensupport zu bieten.</li>
                                    <li>Kommunikation: um Anfragen zu beantworten, Bedenken zu adressieren und Informationen im Zusammenhang mit Bestellungen bereitzustellen.</li>
                                    <li>Verbesserungen: um das App-Erlebnis zu verbessern, einschließlich Empfehlungen relevanter Produkte und Funktionen.</li>
                                    <li>Rechtliche Einhaltung: um geltende Gesetze, Vorschriften und rechtliche Prozesse einzuhalten.</li>
                                </ul>

                                <p>Wir teilen Benutzerdaten nur mit vertrauenswürdigen Dritten in den folgenden Situationen:</p>

                                <ul>
                                    <li>Dienstleister: Wir beauftragen vertrauenswürdige Dienstleister, um uns bei der Erbringung unserer Dienstleistungen zu unterstützen, wie z. B. Lieferpartnern. Diese Anbieter verarbeiten Benutzerdaten ausschließlich zu den von Foodhi angewiesenen Zwecken.</li>
                                    <li>Rechtliche Anforderungen: Wir können Benutzerdaten offenlegen, wenn dies gesetzlich vorgeschrieben ist, durch Gerichtsbeschluss oder andere rechtliche Prozesse, um unsere Rechte, Eigentum oder Sicherheit sowie die Rechte, das Eigentum oder die Sicherheit anderer zu schützen.</li>
                                </ul>

                                <h2 style={{fontSize: 22}}>3. Datensicherheit und -speicherung</h2>

                                <p>Foodhi ergreift angemessene technische und organisatorische Maßnahmen, um die Benutzerdaten vor unbefugtem Zugriff, Offenlegung, Änderung oder Zerstörung zu schützen. Wir bewahren personenbezogene Daten so lange auf, wie es für die Erfüllung der in dieser Datenschutzrichtlinie beschriebenen Zwecke erforderlich ist oder wie es gesetzlich vorgeschrieben ist.</p>

                                <h2 style={{fontSize: 22}}>4. Benutzerrechte und Kontakt</h2>

                                <p>Nutzer haben das Recht auf Zugang, Berichtigung und Löschung ihrer personenbezogenen Daten. Bei Fragen zum Datenschutz oder zur Ausübung der Nutzerrechte wenden Sie sich bitte an Foodhi unter hallo@foodhi.de</p>

<br/>
                                <br/>
                                <h1 style={{fontSize: 28}}>ENGLISH</h1>
                                <br/>
                                <h1 style={{fontSize: 26}}>Privacy Policy – Foodhi</h1>
                                <br/>
                                <h3 style={{fontSize: 22}}>Developer/Company: Foodhi</h3>

                                <p>This Privacy Policy serves as a clear labeling and applies to the collection, use,
                                    disclosure, and protection of user data by the Foodhi app.</p>

                                <h2 style={{fontSize: 22}}>1. Information We Collect</h2>

                                <p>The Foodhi app collects and accesses the following types of personal and sensitive user
                                    data:</p>

                                <ul>
                                    <li>Contact information: such as name, address, email address, and phone number.</li>
                                    <li>Delivery information: including delivery address and any specific instructions
                                        provided.
                                    </li>
                                    <li>Order details: such as the products ordered, quantities, and any special requests or
                                        preferences.
                                    </li>
                                    <li>Communication information: any correspondence with users, including customer support
                                        inquiries and feedback.
                                    </li>
                                </ul>

                                <h2 style={{fontSize: 22}}>2. Use and Sharing of Data</h2>

                                <p>Foodhi app uses and shares user data for the following purposes:</p>

                                <ul>
                                    <li>Order processing: to fulfill product orders, manage deliveries, and provide customer
                                        support.
                                    </li>
                                    <li>Communication: to respond to inquiries, address concerns, and provide information
                                        related to orders.
                                    </li>
                                    <li>Improvements: to enhance the app experience, including recommending relevant
                                        products and features.
                                    </li>
                                    <li>Legal compliance: to comply with applicable laws, regulations, and legal
                                        processes.
                                    </li>
                                </ul>

                                <p>We share user data only with trusted third parties in the following circumstances:</p>

                                <ul>
                                    <li>Service providers: We engage trusted service providers to assist with delivering our
                                        services, such as delivery partners. These providers process user data solely for
                                        the purposes instructed by Foodhi.
                                    </li>
                                    <li>Legal requirements: We may disclose user data if required by law, court order, or
                                        other legal processes to protect our rights, property, or safety, as well as the
                                        rights, property, or safety of others.
                                    </li>
                                </ul>

                                <h2 style={{fontSize: 22}}>3. Data Security and Retention</h2>

                                <p>Foodhi implements appropriate technical and organizational measures to secure user data
                                    against unauthorized access, disclosure, alteration, or destruction. We retain personal
                                    data for as long as necessary to fulfill the purposes outlined in this privacy policy,
                                    or as required by law.</p>

                                <h2 style={{fontSize: 22}}>4. User Rights and Contact</h2>

                                <p>Users have the right to access, correct, and delete their personal data. For inquiries
                                    regarding data privacy practices or to exercise user rights, please contact Foodhi at
                                    hallo@foodhi.de.</p>
                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>
    )

}

export default Datenschutzerklaerung;
