import {Container, Icon} from "@mui/material";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import OrderProductsList from "./OrdersProductList";
import dowloadDeliveryNote from "./dowloadDeliveryNote";

function OrderDetailsData({order}) {

    const unformattedOrderDateCreated = order?.date_created.split("T")[0].split("-").reverse()
    const orderDateCreated = `${unformattedOrderDateCreated[0]}.${unformattedOrderDateCreated[1]}.${unformattedOrderDateCreated[2]}`

    const unformattedDeliveryDate = order?.delivery_date.split("T")[0].split("-").reverse()
    const deliveryDate = `${unformattedDeliveryDate[0]}.${unformattedDeliveryDate[1]}.${unformattedDeliveryDate[2]}`

    const displayOrderStatus = () => {
        switch (order.fk_order_status.id) {
            case 1:
                return "In Bearbeitung";
            case 2:
                return "Abgeschlossen";
            case 3:
                return "Geliefert"
        }
    }

    return (
        <Container>
            <MDBox display="flex" justifyContent="space-between" pt={2} px={2}>
                <MDTypography
                    variant="h5"
                    fontWeight="medium"
                    textTransform="capitalize"
                >
                    {`Bestellung #${order.id}`}
                </MDTypography>

                <div style={{display: "flex", gap: 20}}>
                    <Icon
                        onClick={() => {
                            if (order.status === "processing") {
                                alert("Der Lieferschein wird erstellt, sobald die Bestellung auf \"Im Versand\" gesetzt wird")
                            } else {
                                dowloadDeliveryNote(order)
                            }
                        }}
                        color="secondary"
                        fontSize="medium"
                        style={{cursor: "pointer"}}
                    >
                        download
                    </Icon>

                    <a href={`mailto:${order.restaurant?.contact_email}?subject=Bez%C3%BCglich%20deiner%20Bestellung%20#${order.id}`}>
                        <Icon
                            color="secondary"
                            fontSize="medium"
                            style={{cursor: "pointer"}}
                        >
                            email
                        </Icon>
                    </a>
                </div>

            </MDBox>
            <Grid container>
                {/* DETAILS */}
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" pt={2} px={2}>
                        <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            textTransform="capitalize"
                            mr={1}
                        >
                            Details
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" sx={{flexDirection: "column"}} pt={1} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" mr={1}>
                            Bestellt am:
                        </MDTypography>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {orderDateCreated}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" sx={{flexDirection: "column"}} pt={1} px={2}>
                        <MDTypography variant="h6" fontWeight="medium" mr={1}>
                            Lieferdatum:
                        </MDTypography>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {deliveryDate}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" sx={{flexDirection: "column"}} pt={1} px={2}>
                        <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            textTransform="capitalize"
                            mr={1}
                        >
                            Status:
                        </MDTypography>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {displayOrderStatus()}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" sx={{flexDirection: "column"}} pt={1} px={2}>
                        <MDTypography
                            variant="h6"
                            fontWeight="medium"
                            textTransform="capitalize"
                            mr={1}
                        >
                            Kunde:
                        </MDTypography>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.fk_shipping_address.first_name} ${order.fk_shipping_address.last_name} (${order.fk_shipping_address.company})`}
                        </MDTypography>
                    </MDBox>

                    {
                        order.delivery_note && (
                            <MDBox display="flex" sx={{flexDirection: "column"}} pt={1} px={2}>
                                <MDTypography
                                    variant="h6"
                                    fontWeight="medium"
                                    textTransform="capitalize"
                                    mr={1}
                                >
                                    Bemerkung:
                                </MDTypography>
                                <MDTypography
                                    display="flex"
                                    alignItems="center"
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                >
                                    {`${order.delivery_note}`}
                                </MDTypography>
                            </MDBox>
                        )
                    }


                    <MDBox opacity={0.3}>
                        <Divider/>
                    </MDBox>
                </Grid>

                {/* RECHNUNG */}
                {/* <Grid item xs={12} md={4}>
                    <MDBox display="flex" pt={2} px={2}>
                        <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            textTransform="capitalize"
                            mr={1}
                        >
                            Rechnung
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" pt={2} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.shipping.company}`}
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" pt={1} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.shipping.first_name} ${order.shipping.last_name}`}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" pt={1} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.shipping.address_1}`}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" pt={1} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.shipping.postcode}, ${order.shipping.city}`}
                        </MDTypography>
                    </MDBox>

                    <MDBox opacity={0.3}>
                        <Divider />
                    </MDBox>
                </Grid> */}

                {/* LIEFERUNG */}
                <Grid item xs={12} md={6}>
                    <MDBox display="flex" pt={2} px={2}>
                        <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            textTransform="capitalize"
                            mr={1}
                        >
                            Lieferadresse
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" pt={2} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.fk_shipping_address.company}`}
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" pt={1} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.fk_shipping_address.first_name} ${order.fk_shipping_address.last_name}`}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" pt={1} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.fk_shipping_address.street_1}`}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" pt={1} px={2}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.fk_shipping_address.postcode}, ${order.fk_shipping_address.city}`}
                        </MDTypography>
                    </MDBox>

                    <MDBox display="flex" pt={1} px={2}>
                        <a href={`mailto:${order.restaurant?.contact_email}`}>
                            <MDTypography
                                display="flex"
                                alignItems="center"
                                variant="button"
                                fontWeight="regular"
                                color="text"
                            >
                                {order.restaurant?.contact_email}
                            </MDTypography>
                        </a>

                    </MDBox>

                    <MDBox opacity={0.3}>
                        <Divider/>
                    </MDBox>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <OrderProductsList order={order}/>
                </Grid>
            </Grid>
        </Container>
    );
}


export default OrderDetailsData;