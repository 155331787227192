import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FormControl, Select, MenuItem, Modal} from "@mui/material";
import {TailSpin} from "react-loader-spinner";
import ModifyOrderModal from "./ModifyOrderModal";
import sendEmail from "../../../../../emails/emailApi";
import colors from "../../../../../assets/theme/base/colors";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import {orderStatusList} from "../../../../../variables";
import {fetchOrders} from "../../../../../reduxStore/actions/actionOrders";
import {toast} from "react-toastify";

function OrderStatusComponent({order}) {

    const token = useSelector((state) => state.userProfile.storeToken);
    const farmerId = useSelector((state) => state.userProfile.id);

    const [orderStatus, setOrderStatus] = useState(order.fk_order_status.id);
    const [isLoading, setIsLoading] = useState(false);
    const [newStatus, setNewStatus] = useState({status: null});

    const dispatch = useDispatch();

    const parseOrderStatusName = (item) => {
        switch (item.name) {
            case "in_progress":
                return "In Bearbeitung"
            case "shipped":
                return "Im Versand"
            case "completed":
                return "Abgeschlossen"
            case "cancelled":
                return "Storniert"
            case "refunded":
                return "Zurückerstattet"
            case "partially_refunded":
                return "Teilweise zurückerstattet"
        }
    }

    // MODAL CONSTANTS
    const [modalVisible, setModalVisible] = useState(false);

    const handleClose = () => setModalVisible(false);

    const handleOrderStatus = (status) => {
        setOrderStatus(status.id)
    }

    const applyChangesToOrder = (data) => {
        const statusChange = {
            fk_order_status: data.status
        }
        getAxiosInstance(store).patch(`items/orders/${order.id}`, statusChange, {params: { fields: "*.*.*" } })
            .then((response) => {
                setOrderStatus(response.data.data.fk_order_status.id);
                console.log(response.data.data)
                // SEND EMAIL IF ORDER IS MARKED AS COMPLETED
                if(response.data.data.fk_order_status.id === 3) {
                    const changedOrder = response.data.data;
                    sendEmail({
                        templateId: "RESTAURANT_Order_Completed",
                        data: changedOrder,
                        recipient: order.restaurant.contact_email,
                    });
                }
                if(response.data.data.fk_order_status.id === 6) {
                    const changedOrder = response.data.data;
                    sendEmail({
                        templateId: "RESTAURANT_Order_Cancelled",
                        data: changedOrder,
                        recipient: order.restaurant.contact_email,
                    });
                }
                dispatch(fetchOrders(false, farmerId))
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false);
            });
    }

    // HANDLE CHANGE OF STATUS
    useEffect(() => {
        switch (newStatus.status) {
            case 1:
                if (confirm("Möchtest du die Bestellung wirklich zurück auf 'In Bearbeitung' setzen?")) {
                    applyChangesToOrder(newStatus);
                    setIsLoading(true);
                }
                break;
            case 2:
                if(order.fk_order_status.id + 1 === newStatus.status) {
                    setModalVisible(true)
                } else {
                    toast.info('Die Bestellung muss zuerst im Status "In Bearbeitung" sein.',{
                        position: toast.POSITION.TOP_CENTER
                    })
                }
                break;
            case 3:
                if(order.fk_order_status.id + 1 === newStatus.status) {
                    applyChangesToOrder(newStatus);
                    setIsLoading(true);
                } else {
                    toast.info('Die Bestellung muss zuerst auf "im Versand" gesetzt werden.',{
                        position: toast.POSITION.TOP_CENTER
                    })
                }
                break;
            case 4:
                applyChangesToOrder(newStatus);
                setIsLoading(true);
                break;
            case 6:
                if(confirm("Bist du sicher, dass du diese Bestellung stornieren möchtest?")){
                    applyChangesToOrder(newStatus);
                    setIsLoading(true);
                    toast.info('Die Bestellung wurde storniert.',{
                        position: toast.POSITION.TOP_CENTER
                    })
                }
                break;

        }
    }, [newStatus]);

    // SET THE BACKGROUND COLOR DEPENDING ON THE STATUS
    function statusBackgroundColor() {
        switch (orderStatus) {
            case 1:
                return "lightblue"
            case 2:
                return "lightgreen"
            case 3:
                return "lightgray"
            case 4:
                return "lightgray"
            case 5:
                return "lightgray"
            case 6:
                return "#ffbebe"
        }
    }

    if (order) {
        return (
            <>
                <FormControl fullWidth>
                    <Select
                        labelId="orderStatus"
                        id="orderStatus"
                        value={orderStatus}
                        autoWidth
                        label={false}
                        style={{
                            height: "2.5rem",
                            minWidth: 140,
                            backgroundColor: statusBackgroundColor(),
                        }}
                        onChange={(e) => {
                            setNewStatus({status: e.target.value})
                        }}
                    >
                        {orderStatusList.map((orderStatusItem) => (
                                <MenuItem
                                    key={orderStatusItem.id}
                                    value={orderStatusItem.id}
                                >
                                    {
                                        isLoading
                                            ? <TailSpin height={20} width="100%"/>
                                            : parseOrderStatusName(orderStatusItem)
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>


                <Modal
                    open={modalVisible}
                    onClose={() => handleClose()}
                    aria-labelledby="Produkt bearbeiten"
                    aria-describedby="Produkt bearbeiten"
                    fullwidth
                    style={{overflowY: "auto"}}
                >
                    <ModifyOrderModal
                        order={order}
                        token={token}
                        handleCloseModal={handleClose}
                        newStatus={newStatus}
                        orderStatus={handleOrderStatus}
                        // applyChanges={handleApplyChanges}
                    />
                </Modal>
            </>
        )
    }

    return <TailSpin color={colors.foodhiOrange.main} height={40}/>;
}

export default OrderStatusComponent;