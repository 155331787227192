import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import ImageUploading from "react-images-uploading";
import Icon from '@mui/material/Icon';
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {loadingStart, loadingStop} from "../../../reduxStore/actions/actionUI";
import {FOLDER_PRODUCT_IMAGES, THUMBNAIL_SETTINGS} from "../../../variables";
import {toast} from "react-toastify";
import {Spinner} from "react-bootstrap";

const ImageUpload = ({images, handleOnChange, productId, editMode}) => {

    const [tempImages, setTempImages] = useState([]);

    const loading = useSelector(state => state.UI.loading);

    const dispatch = useDispatch();

    const farmerName = useSelector(state => state.userProfile?.store_name);

    useEffect(() => {
        const tempArr = []
        if (images) {
            images.forEach(image => {
                tempArr.push(image)
            })
        }
        setTempImages(tempArr)

    }, [images]);

    const handleTmpUpload = (imageList, addUpdateIndex) => {
        if (tempImages.length >= 5) {
            toast.error("Du kannst maximal 5 Fotos hochladen.")
        } else if (imageList[addUpdateIndex].file.size < 8388608) {

            dispatch(loadingStart())

            const formData = new FormData();
            formData.append('title', `${farmerName}_ProductID-${productId}_${imageList[addUpdateIndex].file.name}`);
            formData.append('folder', FOLDER_PRODUCT_IMAGES);
            formData.append('file', imageList[addUpdateIndex].file);


            getAxiosInstance(store).post('/files', formData)
                .then((response) => {
                    const newImage = response.data.data.filename_disk;
                    const newArray = [...tempImages, newImage]
                    // IF FORM IS FOR EDITING THE PRODUCT, IMEDIATELY MODIFY THE DATABASE AS WELL AS THE IMAGE IN THE FILE STORE
                    if (editMode) {
                        getAxiosInstance(store).patch(`items/products/${productId}`, {images: newArray})
                            .then(response => {
                                console.log("PATCH", response.data);
                                setTempImages(newArray)
                                dispatch(loadingStop());
                            })
                            .catch(error => {
                                console.log(error, "PATCH ERROR")
                                dispatch(loadingStop());
                            })
                    } else {
                        handleOnChange([...tempImages, newImage])
                        dispatch(loadingStop());
                    }

                })
                .catch(error => console.log(error))
        } else toast.error("Max Bildgröße ist 8mb.")
    }

    const removeImage = (index) => {
        if (confirm("Bist du sicher, dass du dieses Bild löschen möchtest?")) {
            const imageId = tempImages[index].split(".")[0]
            const originalImage = tempImages[index]
            const filteredImages = tempImages.filter(image => image !== originalImage);

            getAxiosInstance(store).delete(`/files/${imageId}`,)
                .then((response) => {
                    setTempImages(filteredImages)
                    if (editMode) {
                        getAxiosInstance(store).patch(`items/products/${productId}`, {images: filteredImages})
                            .then(response => {
                                console.log("PATCH", response.data)
                                dispatch(loadingStop())
                            })
                            .catch(error => console.log(error, "PATCH ERROR"))
                    } else {
                        handleOnChange(filteredImages)
                    }
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                    setTempImages(filteredImages)
                    getAxiosInstance(store).patch(`items/products/${productId}`, {images: filteredImages})
                        .then(response => {
                            console.log("PATCH", response.data)
                            dispatch(loadingStop())
                        })
                        .catch(error => console.log(error, "PATCH ERROR"))
                })
        }
    }


    return (
        <ImageUploading
            multiple
            value={tempImages}
            onChange={handleTmpUpload}
            dataURLKey="data_url"
            acceptType={["jpg", "png", "jpeg"]}
        >
            {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
              }) => (
                <div className="upload__image-wrapper"
                     style={{
                         display: "flex",
                         flexDirection: "row",
                         height: 100,
                         alignItems: "center"
                     }}
                >
                    <button
                        style={{
                            width: 80,
                            height: 80,
                            borderRadius: 10,
                            border: "1px solid",
                            borderColor: "#ff9898",
                            cursor: "pointer",
                            backgroundColor: isDragging ? "#eaeaea" : "#fff",
                            fontSize: 30,
                            color: "gray"
                        }}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        +
                    </button>
                    &nbsp;

                    {/*<button onClick={onImageRemoveAll}>Remove all images</button>*/}

                    {imageList.map((image, index) => {
                        return (
                            <div
                                key={index}
                                className="image-item"
                                style={{
                                    marginLeft: 10,
                                    marginRight: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: 80,
                                    justifyContent: "space-between",
                                    padding: "8px 4px"
                                }}>

                                <div>
                                    <img src={`${process.env.REACT_APP_SITE_URL}/assets/${image}?${THUMBNAIL_SETTINGS}`}
                                         alt=""
                                         width="50"
                                         height="50"
                                         style={{borderRadius: 8}}
                                    />
                                </div>
                                <div className="image-item__btn-wrapper">
                                    <Icon style={{color: "gray", cursor: "pointer"}}
                                          onClick={() => removeImage(index)}>delete</Icon>
                                </div>

                            </div>
                        )
                    })}

                    {loading && <div style={{marginLeft: 20}}><Spinner/></div>}
                </div>
            )}
        </ImageUploading>
    );
};

export default ImageUpload;