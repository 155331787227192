import {combineReducers} from "redux";
import userProfileReducer from "./userProfileReducer";
import authReducer from "./authReducer";
import UIReducer from "./UIReducer";
import {AUTH_LOGOUT} from "../actions/types";
import productsReducer from "./productsReducer";
import customersReducer from "./customersReducer";
import ordersReducer from "./ordersReducer";
import cartReducer from "./cartReducer";


const appReducer = combineReducers({
    userProfile: userProfileReducer,
    auth: authReducer,
    UI: UIReducer,
    products: productsReducer,
    customers: customersReducer,
    orders: ordersReducer,
    shop: cartReducer
});

const rootReducer = (state, action) => {
    if (action.type === AUTH_LOGOUT) {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default rootReducer;