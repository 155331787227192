import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import {ArrowDropDown, Euro} from "@mui/icons-material";
import ProductAmountOptionsField from "../../profile/components/ProductAmountOptionsField";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import ImageUpload from "./ImageUpload";
import Divider from "@mui/material/Divider";
import {loadingStop} from "../../../reduxStore/actions/actionUI";

const ProductForm = ({handleCallback, isLoading, editProductValues, productId}) => {

    const farmerId = useSelector(state => state.userProfile?.id);

    const [measurementRefChange, setMeasurementRefChange] = useState('');
    const [allCategories, setAllCategories] = useState([]);
    const [taxes, setTaxes] = useState([]);
    const [loadTaxesForm, setLoadTaxesForm] = useState(false);

    const [formValues, setFormValues] = useState({
        name: '',
        price: '',
        status: "published",
        description: '',
        stock_quantity: '',
        stock_was_edited: false,
        categories: [{
            product_categories_id: ''
        }],
        tax: {},
        fk_tags: [],
        images: [],
        product_available_from: '',
        taxes_enabled: false,
        amount: '',
        fk_unit_measurement: '',
        amount_adjustment: 1,
        min_order_amount: 1,
        featured_product: false,
        fk_bundle_discounts: [],
        fk_farmer: farmerId,
        recurrent_order_enabled: true
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadingStop())

        setTimeout(() => {
            setLoadTaxesForm(true)
        }, 1000)
    }, [formValues]);

    const measurementRef = useRef(null);

    useEffect(() => {
        if (measurementRef.current) {
            setMeasurementRefChange(measurementRef.current.innerText)
        }
    }, [formValues.fk_unit_measurement]);

    useEffect(() => {
        if (editProductValues) {
            setFormValues(editProductValues)
        }
    }, [editProductValues]);

    // GET ALL CATEGORIES FROM PRODUCTS AND TAX VALUES
    useEffect(() => {
        getAxiosInstance(store).get('/items/product_categories')
            .then(response => {
                setAllCategories(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })

        getAxiosInstance(store).get('/items/tax')
            .then(response => {
                setTaxes(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }, []);

    // SET PRODUCT AVAILABLE FROM
    useEffect(() => {
        const date = new Date();
        const zeroPad = (num, places) => String(num).padStart(places, '0')
        const formattedDate = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`
        console.log(formattedDate)
        setFormValues(prevState => ({...prevState, product_available_from: formattedDate}))
    }, []);


    const handleBundlePackages = value => {
        console.log(value, "BUDNLES")
        setFormValues(prevState => ({...prevState, fk_bundle_discounts: value}))
    }

    const handleImagesUpload = values => {
        console.log("IMAGE UPLOAD", values)
        setFormValues(prevState => ({...prevState, images: values}))
    }

    const submitForm = (e) => {
        e.preventDefault();
        handleCallback(formValues)
    }

    return (
        <>
            {/*IMAGE UPLOAD SECTION*/}
            <MDBox pt={2} pb={1} px={3}>
                <MDTypography sx={{marginBottom: 1}}>
                    Bild hochladen
                </MDTypography>
                <MDTypography variant="button">
                    Auf Plus klicken oder Drag & Drop
                </MDTypography>
                <ImageUpload
                    productId={productId}
                    images={formValues.images}
                    handleOnChange={handleImagesUpload}
                    editMode={editProductValues}
                />
            </MDBox>


            <form onSubmit={submitForm}>
                <MDBox px={3}>
                    {/*PRODUCT DETAILS*/}
                    <MDTypography sx={{paddingTop: 2}}>
                        Details zum Produkt
                    </MDTypography>
                    <Divider sx={{marginTop: 0}}/>
                    {/*NAME*/}
                    <MDBox pt={2} pb={1}>
                        <MDInput
                            type="text"
                            required
                            label="Produkt-Titel"
                            placeholder="Titel des Produkts"
                            fullWidth
                            disabled={isLoading && true}
                            onChange={(e) => setFormValues(prevState => ({...prevState, name: e.target.value}))}
                            value={formValues.name}
                        />
                    </MDBox>
                    {/*DESCRIPTION*/}
                    <MDBox pt={2} pb={3}>
                        <MDInput
                            type="text"
                            label="Beschreibung"
                            fullWidth
                            placeholder="Informationen über das Produkt"
                            multiline
                            rows={4}
                            onChange={(e) => setFormValues(prevState => ({...prevState, description: e.target.value}))}
                            value={formValues.description}
                        />
                    </MDBox>
                    {/*PRODUCT AMOUNT*/}
                    <MDTypography sx={{fontSize: 16, marginTop: 1, color: "gray"}}>
                        Lege deine Packgröße fest
                    </MDTypography>
                    <MDBox pt={2} pb={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <MDInput
                                    type="text"
                                    label="Menge"
                                    fullWidth
                                    disabled={isLoading && true}
                                    onChange={(e) => setFormValues(prevState => ({
                                        ...prevState,
                                        amount: e.target.value
                                    }))}
                                    value={formValues.amount}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Einheit</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formValues.fk_unit_measurement ?
                                            formValues.fk_unit_measurement.id : formValues.fk_unit_measurement}
                                        label="Einheit"
                                        ref={measurementRef}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <ArrowDropDown fontSize="medium" color="standard"/>
                                            </InputAdornment>
                                        }
                                        required
                                        onChange={(e) => setFormValues(prevState => ({
                                            ...prevState,
                                            fk_unit_measurement: {id: e.target.value}
                                        }))}
                                        disabled={isLoading && true}
                                        style={{minHeight: "44px"}}
                                    >
                                        {/*HERE WATCH OUT, IDs ARE SKIPPED AT NUMBER 4!!!!*/}
                                        <MenuItem value="1">g</MenuItem>
                                        <MenuItem value="2">kg</MenuItem>
                                        <MenuItem value="3">l</MenuItem>
                                        <MenuItem value="5">cl</MenuItem>
                                        <MenuItem value="6">ml</MenuItem>
                                        <MenuItem value="7">Stück</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </MDBox>

                    {/*PRODUCT PRICE AND TAX RATE*/}
                    <MDTypography sx={{fontSize: 16, marginTop: 1, color: "gray"}}>
                        Preis und Steuer
                    </MDTypography>
                    <MDBox pt={2} pb={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <MDInput
                                    type="number"
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="end">
                                                <Euro/>
                                            </InputAdornment>
                                        )
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    label="Preis pro Packgröße (Netto)"
                                    fullWidth
                                    onChange={(e) => setFormValues(prevState => ({
                                        ...prevState,
                                        price: e.target.value
                                    }))}
                                    value={formValues.price}
                                />
                            </Grid>

                            {(loadTaxesForm) && (
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="tax-label">Steuer</InputLabel>
                                        <Select
                                            labelId="tax-select-label"
                                            id="tax-select"
                                            value={formValues.tax?.id}
                                            label="Steuer"
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <ArrowDropDown fontSize="medium" color="standard"/>
                                                </InputAdornment>
                                            }
                                            required
                                            onChange={(e) => setFormValues(prevState => ({
                                                ...prevState,
                                                tax: {id: e.target.value}
                                            }))}
                                            disabled={isLoading && true}
                                            style={{minHeight: "44px"}}
                                        >
                                            {taxes.map(tax => (
                                                <MenuItem key={tax.id} value={tax.id}>{tax.name}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}


                        </Grid>
                    </MDBox>


                    {/*PRODUCT CHANGE NUMBER*/}
                    {/*<MDTypography sx={{fontSize: 16, marginTop: 2, color: "gray"}}>*/}
                    {/*    Wenn der Kunde eine genaue Anzahl angeben möchte, kann er den Betrag um... ändern:*/}
                    {/*</MDTypography>*/}
                    {/*<MDBox pt={2} pb={1}>*/}
                    {/*    <MDInput*/}
                    {/*        type="number"*/}
                    {/*        required*/}
                    {/*        label="Option Betragsänderung"*/}
                    {/*        onWheel={(e) => e.target.blur()}*/}
                    {/*        sx={{width: "50%"}}*/}
                    {/*        inputProps={{*/}
                    {/*            min: 1,*/}
                    {/*            max: 10000*/}
                    {/*        }}*/}
                    {/*        onChange={(e) => setFormValues(prevState => ({*/}
                    {/*            ...prevState,*/}
                    {/*            amount_adjustment: e.target.value*/}
                    {/*        }))}*/}
                    {/*        value={formValues.amount_adjustment}*/}
                    {/*    />*/}
                    {/*</MDBox>*/}

                    {/*WEITERE OPTIONEN*/}
                    <MDTypography sx={{paddingTop: 4}}>
                        Weitere Optionen
                    </MDTypography>

                    {/*<MDTypography sx={{fontSize: 16, marginTop: 2, color: "gray"}}>*/}
                    {/*    Jede Packgröße enthält {formValues.amount} {measurementRef.current?.innerText}*/}
                    {/*</MDTypography>*/}

                    <Divider sx={{marginTop: 0}}/>
                    {/*{console.log(formValues.fk_bundle_discounts[0].bundle_quantity, "BUNDLE")}*/}
                    <MDBox pt={2} pb={1}>
                        <MDInput
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            label="Mindestbestellmenge der Packgröße"
                            inputProps={{
                                min: 1,
                                max: formValues.fk_bundle_discounts && formValues.fk_bundle_discounts[0]?.bundle_quantity > 0
                                    ? formValues.fk_bundle_discounts[0].bundle_quantity - formValues.amount_adjustment
                                    : 1000
                            }}
                            fullWidth
                            disabled={isLoading && true}
                            onChange={(e) => setFormValues(prevState => ({
                                ...prevState,
                                min_order_amount: e.target.value
                            }))}
                            value={formValues.min_order_amount}
                        />
                    </MDBox>

                    <MDBox pt={2} pb={1}>
                        <MDInput
                            type="number"
                            required
                            label={`Lagerbestand`}
                            onWheel={(e) => e.target.blur()}
                            inputProps={{
                                min: 1,
                                max: 100000
                            }}
                            fullWidth
                            onChange={(e) => {
                                setFormValues(prevState => ({
                                    ...prevState,
                                    stock_quantity: e.target.value,
                                    stock_quantity_reference: e.target.value,
                                    stock_was_edited: true
                                }))
                            }}
                            value={formValues.stock_quantity}
                        />
                    </MDBox>

                    <MDBox pt={2} pb={1}>
                        <MDInput
                            type="date"
                            label="Produkt verfügbar ab"
                            required
                            onWheel={(e) => e.target.blur()}
                            fullWidth
                            onChange={(e) => setFormValues(prevState => ({
                                ...prevState,
                                product_available_from: e.target.value
                            }))}
                            value={formValues.product_available_from}
                        />
                    </MDBox>
                    <MDBox pt={2} pb={1}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Kategorie</InputLabel>
                            <Select
                                required
                                endAdornment={
                                    <InputAdornment position="end">
                                        <ArrowDropDown fontSize="medium" />
                                    </InputAdornment>
                                }
                                labelId="product-categories-id"
                                id="product-categories"
                                value={formValues.categories?.length ? formValues.categories[0].product_categories_id : null}
                                label="Kategorie"
                                sx={{height: 45}}
                                onChange={(e) => setFormValues(prevState => (
                                    {
                                        ...prevState,
                                        categories: [
                                            {product_categories_id: e.target.value}
                                        ]
                                    }))}>
                                {allCategories && allCategories.length ? (
                                    allCategories.map((category) => (
                                        <MenuItem value={category.id}>
                                            {category.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem>Loading..</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </MDBox>

                    <MDBox pt={2} pb={3}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={formValues.featured_product}
                                              onChange={() => setFormValues(prevState => ({
                                                  ...prevState,
                                                  featured_product: !prevState.featured_product
                                              }))}
                                    />}
                                label="Bevorzugtes Produkt"/>
                        </FormGroup>
                        <MDTypography sx={{color: "gray", fontSize: 14}}>
                            Das Produkt wird an erster Stelle der Produktliste angezeigt.
                        </MDTypography>
                    </MDBox>

                    <MDBox pb={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={formValues.recurrent_order_enabled}
                                              onChange={() => setFormValues(prevState => ({
                                                  ...prevState,
                                                  recurrent_order_enabled: !prevState.recurrent_order_enabled
                                              }))}
                                    />}
                                label="Dauerbestellung erlauben"/>
                        </FormGroup>
                        <MDTypography sx={{color: "gray", fontSize: 14}}>
                            Ermögliche deinen Kunden Dauerbestellungen für dieses Produkt zu planen.
                        </MDTypography>
                    </MDBox>

                    {/*PRICE, QUANTITY AND BUNDLES*/}
                    <MDTypography sx={{paddingTop: 0}}>
                        Gebindegröße und Rabatte
                    </MDTypography>
                    <Divider sx={{marginTop: 1}}/>

                    <MDTypography sx={{color: "gray", fontSize: 16}} mb={2}>
                        Lege Gebindegrößen fest. Wie viel Packgrößen sind in einem Gebinde und welchen Rabatt kannst
                        du anbieten?
                    </MDTypography>

                    <MDBox pt={1} pb={1}>
                        <Grid item xs={12}>
                            <ProductAmountOptionsField
                                isLoading={isLoading}
                                bundlePackages={handleBundlePackages}
                                productId={productId}
                                productPrice={formValues.price}
                                initialAmountState={!editProductValues ? null : editProductValues.fk_bundle_discounts}
                            />
                        </Grid>
                    </MDBox>


                    <MDBox pt={2} pb={3}>
                        <MDButton type="submit" variant="gradient" color="info" disabled={isLoading && true}>
                            {!isLoading
                                ? editProductValues ? "Aktualisieren" : "Erstellen"
                                : <TailSpin height={20} color="lightgray"/>
                            }
                        </MDButton>
                    </MDBox>
                </MDBox>

            </form>
        </>
    );
}

export default ProductForm;