// COMMON VARIABLES
export const ROLE_RESTAURANT = "efb390e3-ff39-4f03-81c7-93304b7dad81"
export const ROLE_FARMER = "efb390e3-ff39-4f03-81c7-93304b7dad81"
export const FOLDER_PRODUCT_IMAGES = "7cab0462-ed8c-4d48-a3d5-edd9d3cb662d"
export const FOLDER_STORE_BANNERS = "bdf5e985-f5b7-499d-acd0-f28b42610489"
export const FOLDER_STORE_AVATARS = "8ba022ff-30fc-4fab-9d70-05923b13b67d"

// IMAGES
export const USER_PROFILE_PLACEHOLDER = "dacd10e3-6ecc-41d7-951e-ec770953fe7f"
export const RESTAURANT_PROFILE_PLACEHOLDER = "8a31d9da-279e-4202-83bf-36a28ef54939"

export const THUMBNAIL_SETTINGS = "fit=cover&width=200&height=200&quality=85";

// STATISTIKS VARIABLES
export const MONTH_LABELS = [
    {value: 1, label: "JAN"},
    {value: 2, label: "FEB"},
    {value: 3, label: "MÄR"},
    {value: 4, label: "APR"},
    {value: 5, label: "MAI"},
    {value: 6, label: "JUN"},
    {value: 7, label: "JUL"},
    {value: 8, label: "AUG"},
    {value: 9, label: "SEP"},
    {value: 10, label: "OKT"},
    {value: 11, label: "NOV"},
    {value: 12, label: "DEZ"}
]

// MISC
export const orderStatusList = [
    {
        "id": 1,
        "name": "in_progress"
    },
    {
        "id": 2,
        "name": "shipped"
    },
    {
        "id": 3,
        "name": "completed"
    },
    {
        "id": 6,
        "name": "cancelled"
    }
];