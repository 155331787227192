import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import FoodhiLogoApp from "../../../assets/foodhi/logo/foodhi_logo_no_bg.png";
import MDTypography from "../../../components/MDTypography";
import {Grid, Icon, Typography} from "@mui/material";
import PageLayout from "../../../examples/LayoutContainers/PageLayout";
import MDInput from "../../../components/MDInput";
import IconButton from "@mui/material/IconButton";
import MDButton from "../../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {loadingStart, loadingStop} from "../../../reduxStore/actions/actionUI";
import axios from "axios";
import {CheckCircleOutline} from "@mui/icons-material";

const PasswordReset = () => {

    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [successPasswordReset, setSuccessPasswordReset] = useState(false);

    const [passwordShown, setPasswordShown] = useState(false);

    const dispatch = useDispatch();
    // const loading = useSelector(state => state.UI.loading);
    const loading = false

    const navigation = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.get("token")

    useEffect(() => {
        if (!searchParams) {
            navigation('/authentication/sign-in')
            toast.info("Kein Reset-Token gefunden.")
        }
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === passwordRepeat) {
            dispatch(loadingStart())
            const data = {
                "token": searchParams.get("token"),
                password
            }
            axios.post(`${process.env.REACT_APP_SITE_URL}/auth/password/reset`, data)
                .then(() => {
                    dispatch(loadingStop())
                    setSuccessPasswordReset(true)
                })
                .catch(error => {
                    console.log(error.response)
                    toast.error("Etwas ist schiefgelaufen.");
                    dispatch(loadingStop())
                })
        } else {
            toast.error("Passwörter sind nicht gleich.");
            dispatch(loadingStop())
        }
    }


    const image = require('../../../assets/foodhi/bg/signin_bg.jpg');
    return (
        <PageLayout>
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        image &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} md={9} lg={7} xl={5}>
                        <Card>
                            <MDBox
                                bgColor="linear-gradient(90deg, rgba(253,109,93,1) 0%, rgba(250,90,79,1) 100%)"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                pb={3}
                                textAlign="center"
                            >
                                <img src={FoodhiLogoApp} alt="Foodhi Logo" height={100}/>
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Passwort zurücksetzen
                                </MDTypography>
                            </MDBox>

                            <MDBox pt={4} pb={3} px={3}>
                                <Grid container pb={3} sx={{display: "flex", textAlign: "center"}}>
                                    <Grid container justifyContent="center" mb={2}>
                                        {!successPasswordReset ? (
                                            <MDBox pt={4} pb={3} px={3} sx={{ position: "relative", minWidth: 320}}>
                                                <form onSubmit={handleSubmit}>
                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            type={passwordShown ? "text" : "password"}
                                                            label="Passwort"
                                                            required
                                                            fullWidth
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            value={password}
                                                        />
                                                        <IconButton
                                                            size="medium"
                                                            sx={{
                                                                position: "absolute",
                                                                right: 30,
                                                                height: 44
                                                            }}
                                                            disableRipple
                                                            mr={5}
                                                            // sx={navbarMobileMenu}
                                                            onClick={() => setPasswordShown(!passwordShown)}
                                                        >
                                                            <Icon fontSize="small" color="darkgray">
                                                                {passwordShown ? "visibility_off" : "visibility"}
                                                            </Icon>
                                                        </IconButton>
                                                    </MDBox>

                                                    <MDBox mb={2}>
                                                        <MDInput
                                                            type={passwordShown ? "text" : "password"}
                                                            label="Passwort wiederholen"
                                                            required
                                                            fullWidth
                                                            onChange={(e) => setPasswordRepeat(e.target.value)}
                                                            value={passwordRepeat}
                                                        />
                                                        <IconButton
                                                            size="medium"
                                                            sx={{
                                                                position: "absolute",
                                                                right: 30,
                                                                height: 44
                                                            }}
                                                            disableRipple
                                                            mr={5}
                                                            // sx={navbarMobileMenu}
                                                            onClick={() => setPasswordShown(!passwordShown)}
                                                        >
                                                            <Icon fontSize="small" color="darkgray">
                                                                {passwordShown ? "visibility_off" : "visibility"}
                                                            </Icon>
                                                        </IconButton>
                                                    </MDBox>

                                                    <MDBox mt={5} mb={1}>
                                                        <MDButton
                                                            variant="gradient"
                                                            color="foodhiOrange"
                                                            fullWidth
                                                            disabled={loading}
                                                            type="submit"
                                                        >
                                                            {!loading ? "bestätigen" :
                                                                <TailSpin color="#fff" height={20}/>}
                                                        </MDButton>
                                                    </MDBox>
                                                </form>
                                            </MDBox>
                                        ) : (
                                            <MDBox display="flex" flexDirection="column"
                                                   sx={{alignItems: "center"}}>
                                                <MDBox sx={{fontSize: 60}}>
                                                    <CheckCircleOutline color="foodhiLightGreen" fontSize="inherit"/>
                                                </MDBox>
                                                <Typography pb={3}>
                                                    Passwort erfolgreich zurückgesetzt
                                                </Typography>

                                                <Typography color="gray" fontSize={16}>
                                                    Du kannst dich jetzt in der App anmelden
                                                </Typography>
                                            </MDBox>
                                        )}

                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>
    );
};

export default PasswordReset;
