import {templates} from "../templates";
import dayjs from "dayjs";
import {Repeat} from "@mui/icons-material";
import {Typography} from "@mui/material";
import React from "react";

function parseLineItems(items) {
    const parsedItems = items.map((item) => {
        return {
            name: item.name,
            quantity: item.quantity,
            unit_amount: `${item.product_amount / item.quantity} ${item.measurement}`,
            updated_product_amount: `${item.updated_product_amount.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })} ${item.measurement}`,
            updated_price: item.updated_subtotal,
            subtotal: (item.updated_subtotal * item.quantity).toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        }
    });
    return parsedItems;
}

export const createEmailData = (sender, recipient, template, data) => {
    let templateId = "";
    let dynamicTemplateData = {};
    let subject = "";

    switch (template) {
        case "RESTAURANT_Order_Received":
            dynamicTemplateData = {
                restaurantFirstName: `${data?.restaurant.contact_first_name}`,
                farmerFirstName: `${data?.farmer.contact_first_name}`,
                shippingAddress: `${data?.fk_shipping_address.street_1} ${data?.fk_shipping_address.street_2}, ${data?.fk_shipping_address.city}, ${data?.fk_shipping_address.country}`,
                items: parseLineItems(data.fk_order_items),
                orderNum: data?.id,
                storeName: data?.farmer.store_name
            };
            templateId = templates.RESTAURANT_Order_Received;
            break;

        case "RESTAURANT_Order_Shipped":
            dynamicTemplateData = {
                total: Number(data?.total).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }),
                restaurantFirstName: `${data?.restaurant.contact_first_name}`,
                shippingAddress: `${data?.fk_shipping_address.street_1} ${data?.fk_shipping_address.street_2}, ${data?.fk_shipping_address.city}, ${data?.fk_shipping_address.country}`,
                items: parseLineItems(data?.fk_order_items),
                packaging: data?.packaging.map((item) => ({name: item.name, amount: item.amount})),
                orderNum: data?.id,
                storeName: data?.farmer.store_name,
                farmerFirstName: data?.farmer.contact_first_name,
                lieferschein: data?.lieferschein,
                deliveryFee: Number(data?.delivery_fee).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            };
            templateId = templates.RESTAURANT_Order_Shipped;
            break;

        case "RESTAURANT_Order_Completed":
            dynamicTemplateData = {
                total: data.total.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2}),
                restaurantFirstName: data.restaurant.fk_shipping_address.first_name,
                shippingAddress: `${data.restaurant.fk_shipping_address.street_1}, ${data.restaurant.fk_shipping_address.postcode}, ${data.restaurant.fk_shipping_address.city}`,
                items: parseLineItems(data.fk_order_items),
                packaging: data?.packaging.map((item) => ({name: item.name, amount: item.amount})),
                orderNum: data.id,
                storeName: data.farmer.store_name,
                farmerFirstName: data.farmer.contact_first_name,
                lieferschein: data.lexoffice_delivery_slip,
                deliveryFee: data.delivery_fee.toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            };
            console.log(dynamicTemplateData)
            templateId = templates.RESTAURANT_Order_Completed;
            break;

        case "RESTAURANT_Accepted_Invitation_from_Farmer":
            dynamicTemplateData = {
                restaurantFirstName: data?.restaurantFirstName,
                farmerFirstName: data?.farmerFirstName,
                farmerStoreName: data?.farmerStoreName
            };
            console.log(dynamicTemplateData)
            templateId = templates.RESTAURANT_Accepted_Invitation_from_Farmer;
            break;

        case "RESTAURANT_Incoming_Invitation_Existing_User":
            console.log(data, "DATA")
            dynamicTemplateData = {
                storeName: data?.farmerName,
                restaurantFirstName: data?.contact_first_name,
                restaurantName: data?.restaurant_name
            };
            console.log(dynamicTemplateData)

            templateId = templates.RESTAURANT_Incoming_Invitation_Existing_User;
            break;

        case "RESTAURANT_Recurrent_Order_Cancelled_By_Farmer":
            const parseWeekDays = (data) => {
                const arrayOfDays = []
                data.forEach(day => {
                    if (day == "monday") {
                        arrayOfDays.push("Mo")
                    }
                    if (day == "tuesday") {
                        arrayOfDays.push("Di")
                    }
                    if (day == "wednesday") {
                        arrayOfDays.push("Mi")
                    }
                    if (day == "thursday") {
                        arrayOfDays.push("Do")
                    }
                    if (day == "friday") {
                        arrayOfDays.push("Fr")
                    }
                    if (day == "saturday") {
                        arrayOfDays.push("Sa")
                    }
                    if (day == "sunday") {
                        arrayOfDays.push("So")
                    }
                })
                return arrayOfDays.join(", ")
            }
            dynamicTemplateData = {
                restaurantFirstName: data?.fk_restaurant.contact_first_name,
                restaurantLastName: data?.fk_restaurant.contact_last_name,
                product: `${data?.fk_order_items.name} (${data?.fk_order_items.updated_product_amount} ${data?.fk_order_items.measurement})`,
                date_start: dayjs(data?.date_start).format("DD.MM.YYYY"),
                date_end: dayjs(data?.date_end).format("DD.MM.YYYY"),
                repeat: parseWeekDays(data?.order_repeat),
                farmerEmail: data?.fk_farmer.contact_email,
                storeName: data?.fk_farmer.store_name
            };
            console.log(dynamicTemplateData)

            templateId = templates.RESTAURANT_Recurrent_Order_Cancelled_By_Farmer;
            break;

        case "RESTAURANT_Order_Cancelled":
            dynamicTemplateData = {
                restaurantFirstName: data?.restaurant.contact_first_name,
                restaurantLastName: data?.restaurant.contact_last_name,
                orderNum: data?.id,
                deliveryDate: dayjs(data?.delivery_date).format("DD.MM.YYYY"),
                farmerEmail: data?.farmer.contact_email,
                storeName: data?.farmer.store_name,
                items: parseLineItems(data.fk_order_items),
            };
            console.log(dynamicTemplateData)

            templateId = templates.RESTAURANT_Order_Cancelled;
            break;

        case "FARMER_Lieferschein_Not_Created":
            dynamicTemplateData = {
                orderNum: data?.id,
                farmerFirstName: data?.farmer.contact_first_name
            };
            console.log(dynamicTemplateData)

            templateId = templates.FARMER_Lieferschein_Not_Created;
            break;

        case "FARMER_Manual_Order_Created":
            dynamicTemplateData = {
                restaurantFirstName: `${data?.restaurant?.contact_first_name}`,
                restaurantName: `${data?.restaurant.restaurant_name}`,
                farmerFirstName: `${data?.farmer.contact_first_name}`,
                shippingAddress: `${data?.farmer.fk_address.street_1} ${data?.farmer.fk_address.street_2}, ${data?.farmer.fk_address.city}, ${data?.farmer.fk_address.country}`,
                items: parseLineItems(data?.items),
                orderNum: data?.orderNum,
                storeName: data?.farmer.store_name
            };
            templateId = templates.FARMER_Manual_Order_Created;
            break;

        default:
            subject = "Default message";
            break;
    }

    const msg = {
        to: recipient,
        from: {email: sender.email, name: sender.name},
        subject,
        templateId,
        dynamicTemplateData,
    };

    return msg;
};
