import * as actionTypes from './types';
import {loadingStart, loadingStop} from "./actionUI";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../store";

export const addToCart = (product, quantity, discount) => async dispatch => {
  dispatch({
    type: actionTypes.ADD_TO_CART,
    payload: {
      product,
      quantity,
      discount
    }
  });
  dispatch(loadingStop());
};

export const removeFromCart = (product) => {
  return {
    type: actionTypes.REMOVE_FROM_CART,
    payload: product
  };
};

export const updateCartQuantity = (productID, quantity) => {
  return {
    type: actionTypes.UPDATE_CART_QUANTITY,
    id
  };
};

export const emptyCart = (vendor_id) => {
  return {
    type: actionTypes.EMPTY_CART,
    vendor_id
  };
}

export const totalSumOfCarts = (value) => {
  return {
    type: actionTypes.TOTAL_SUM,
    payload: value
  };
}

export const createOrder = (orderDataResponse) => async dispatch => {
  const products = orderDataResponse.fk_order_items;

  // REDUCE STOCK FOR EACH PRODUCT IN THE ORDER
  products.forEach(product => {
    const productId = product.product_id;
    const previousStock = product.stock_quantity;
    const stockReduction = product.quantity;

    const patchData = {
      stock_quantity: (previousStock - stockReduction)
    }

    getAxiosInstance(store).patch(`items/products/${productId}`, patchData)
        .then(() => {
          dispatch({
            type: actionTypes.PLACE_ORDER,
          })
        })
        .catch(error => console.log("ERROR PATCHING STOCK QUANTITY", error.response))

  })
}



