import React, {useEffect, useState} from 'react';
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import {Send} from "@mui/icons-material";
import MDButton from "../../../components/MDButton";
import {Divider, Grid} from "@mui/material";
import {useSelector} from "react-redux";

const PendingInvites = ({storeId, linkedCustomers}) => {

    // const [pendingInvites, setPendingInvites] = useState([]);
    //
    // useEffect(() => {
    //     getAxiosInstance(store).get(`/items/farmer_profile/${storeId}`,
    //         {params: {fields: "pending_invites"}})
    //         .then(response => {
    //             setPendingInvites(response.data.data.pending_invites)
    //         })
    // }, []);

    const pendingInvites = useSelector(state => state.customers.pendingRestaurants);

    if (pendingInvites?.length > 0) {
        return (
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card sx={{paddingY: 3, paddingX: 3}}>
                            <MDTypography variant="h5" color="text">
                                Ausstehende Einladungen
                            </MDTypography>
                            <Divider/>
                            <MDBox>
                                <Grid container flexDirection="column" sm={6}>
                                    {pendingInvites.map(item => {
                                        return (
                                            <Grid item my={1}>
                                                <MDBox sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyItems: "space-between",
                                                    gap: 2,
                                                }}>
                                                    <MDBox sx={{flex: 1, textAlign: "center"}}>
                                                        <MDButton onClick={() => window.location = `mailto:${item}`}
                                                                  color="light" size="small"
                                                                  ml={3}>
                                                            Versendet
                                                        </MDButton>
                                                    </MDBox>
                                                    <MDBox sx={{flex: 1, textAlign: "center"}}>
                                                        <MDTypography sx={{
                                                            fontSize: 14,
                                                            color: "#7b809a",
                                                            fontFamily: "Roboto",
                                                            fontWeight: 400,
                                                        }}>{item}</MDTypography>
                                                    </MDBox>
                                                    <MDBox sx={{flex: 1, textAlign: "center"}}>
                                                        <Send onClick={() => window.location = `mailto:${item}`}
                                                              style={{cursor: "pointer"}} color="action"/>
                                                    </MDBox>
                                                </MDBox>
                                            </Grid>
                                        )
                                    })}
                                </Grid>


                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        );
    }
    return <></>;

};

export default PendingInvites;
