import * as actionTypes from '../actions/types';

const UIReducer = (state = {
    loading: false,
    openProductDeleteDialog: false,
    openProductEditDialog: false,
    openProductCreateDialog: false,
    orderModalVisible: false
}, action) => {
    switch (action.type) {
        case actionTypes.LOADING_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.LOADING_STOP:
            return {
                ...state,
                loading: false
            }
        case actionTypes.DELIVERY_ZONES:
            return {
                ...state,
                deliveryZones: action.payload
            }
        case actionTypes.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case actionTypes.SET_VENDORS:
            return {
                ...state,
                vendors: action.payload
            }
        case actionTypes.SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                loading: false
            }
        case actionTypes.SET_ORDERS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            }
        case actionTypes.OPEN_PRODUCT_DELETE_DIALOG:
            return {
                ...state,
                openProductDeleteDialog: true,
                deleteProductId: action.payload.id
            }
        case actionTypes.CLOSE_PRODUCT_DELETE_DIALOG:
            return {
                ...state,
                openProductDeleteDialog: false,
                deleteProductId: ""
            }
        case actionTypes.OPEN_PRODUCT_EDIT_DIALOG:
            return {
                ...state,
                openProductEditDialog: true,
                editProductId: action.payload.id
            }
        case actionTypes.CLOSE_PRODUCT_EDIT_DIALOG:
            return {
                ...state,
                openProductEditDialog: false,
                editProductId: ""
            }
        case actionTypes.OPEN_PRODUCT_CREATE_DIALOG:
            return {
                ...state,
                openProductCreateDialog: true,
            }
        case actionTypes.CLOSE_PRODUCT_CREATE_DIALOG:
            return {
                ...state,
                openProductCreateDialog: false,
            }
        default:
            return state;
    }
};

export default UIReducer;
