import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import {TailSpin} from "react-loader-spinner";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import gradients from "../../../assets/theme/base/colors";

function QuickNumberCard({ color, title, count, icon, loading }) {
    return (
        <Card>
            {loading && <TailSpin wrapperStyle={{position: "absolute", top: 0, left: 95, padding: 25}}/>}
            <MDBox display="flex" justifyContent="space-between" py={2} px={2}>
                <MDBox
                    variant="gradient"
                    // bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{backgroundColor: gradients.foodhiLightGreen.main}}
                    width="4rem"
                    height="4rem"
                    mt={-2}
                >
                    <Icon fontSize="medium" color="inherit">
                        {icon}
                    </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end"
                }}>
                    <MDTypography variant="button" fontWeight="light" color="text">
                        {title}
                    </MDTypography>
                    <MDTypography sx={{alignItems: "flex-end", display: "flex", flex: 1}} variant="h4" style={loading ? {color: "#fff"} : null}>
                        {count}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default QuickNumberCard;
