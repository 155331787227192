/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */
import {toast} from "react-toastify";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

// @mui material components
import {Button, Icon} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {fetchProducts} from "../../../reduxStore/actions/actionProducts";
import ProductForm from "../Components/ProductForm";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";

function ProductCreate({handleCloseModal}) {

    const [isLoading, setIsLoading] = useState(false);

    const userId = useSelector(state => state.auth.id);

    const dispatch = useDispatch();

    const handleProductSubmit = (formValues) => {
        getAxiosInstance(store).post('/items/products/', formValues)
            .then(() => {
                handleCloseModal();
                toast.success("Produkt wurde erfolgreich erstellt.")
                dispatch(fetchProducts(userId))
            })
            .catch(error => {
                toast.error("Es gab ein Problem bei der Erstellung des Produkts.")
                console.log(error.response)
            })
    }

    return (
        <MDBox>
            <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                sx={{display: "flex", alignItems: "center", justifyContent: "space-between", borderTopRightRadius: 0, borderTopLeftRadius: 0}}
                variant="gradient"
                bgColor="foodhiLightGreen"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Produkt anlegen
                </MDTypography>

                <Button
                    variant="gradient"
                    onClick={() => handleCloseModal()}
                >
                    <Icon
                        sx={{
                            fontWeight: "bold",
                            color: "#fff",
                        }}
                        fontSize="16"
                    >cancel</Icon>
                </Button>

            </MDBox>
            <MDBox pt={3}>
                <ProductForm
                    handleCallback={(e) => handleProductSubmit(e)}
                    isLoading={isLoading}
                />
            </MDBox>
        </MDBox>
    )
        ;
}

export default ProductCreate;
