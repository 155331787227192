import React, {useEffect, useState} from 'react';
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../../reduxStore/store";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import PageLayout from "../../examples/LayoutContainers/PageLayout";
import {useSelector} from "react-redux";
import MDBox from "../../components/MDBox";
import image from "../../assets/foodhi/bg/signin_bg.jpg";
import {Grid, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import FoodhiLogoApp from "../../assets/foodhi/logo/foodhi_logo_no_bg.png";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import {toast} from "react-toastify";
import sendEmail from "../../emails/emailApi";
import {sendPushNotification} from "../../utils/sendPushNotification";
import {THUMBNAIL_SETTINGS} from "../../variables";

const NewFarmerInvitation = () => {

    const [connectionId, setConnectionId] = useSearchParams();

    const [restaurant, setRestaurant] = useState({});
    const [loading, setLoading] = useState(false);

    const farmerId = useSelector(state => state.userProfile.id)
    const farmer = useSelector(state => state.userProfile)

    const [authorized, setAuthorized] = useState(null);

    const navigation = useNavigate();

    const checkIfLoggedIn = useSelector(state => state.auth.loggedIn);

    useEffect(() => {
        if (!checkIfLoggedIn) {
            navigation(`/authentication/sign-in?redirect-restaurant-connection=true&connectionId=${connectionId.get("connectionId")}`)
        }
    }, []);

    useEffect(() => {
        setLoading(true)
        getAxiosInstance(store).get(`/items/farmer_profile_restaurant_profile/${connectionId.get("connectionId")}`)
            .then(response => {
                if (response.data.data.farmer_profile_id === farmerId) {
                    setAuthorized(true)
                    getAxiosInstance(store).get(`/items/restaurant_profile/${response.data.data.restaurant_profile_id}`)
                        .then(res => {
                            setRestaurant(res.data.data);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.log(error.response);
                            setLoading(false);
                        })
                } else setAuthorized(false)
            })
            .catch(error => {
                setLoading(false);
                setAuthorized(false);
                console.log(error.response);
            })
    }, [authorized]);

    const acceptInvitation = () => {
        getAxiosInstance(store).patch(`/items/farmer_profile_restaurant_profile/${connectionId.get("connectionId")}`, {
            status: "connected"
        })
            .then(() => {
                sendEmail({
                    templateId: "RESTAURANT_Accepted_Invitation_from_Farmer",
                    data: {
                        farmerFirstName: farmer.contact_first_name,
                        farmerStoreName: farmer.store_name,
                        restaurantFirstName: restaurant.contact_first_name,
                        restaurantName: restaurant.restaurant_name
                    },
                    recipient: restaurant.contact_email,
                });
                // SEND PUSH NOTIFICATION TO RESTAURANT
                sendPushNotification(
                    `${restaurant.push_notification_key}`,
                    "default",
                    "Du hast eine neue Verbindung",
                    `${farmer.store_name} hat deine Anfrage akzeptiert.`
                )
                toast.success(`Du bist jetzt mit ${restaurant.restaurant_name} verbunden`)
            })
            .catch(() => toast.error("Ein Fehler ist aufgetreteb"))

        navigation(`/kunden`)
    }

    return (
        <PageLayout>
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({functions: {linearGradient, rgba}, palette: {gradients}}) =>
                        image &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}
            />
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} md={9} lg={7} xl={5}>
                        <Card>
                            <MDBox
                                bgColor="linear-gradient(90deg, rgba(253,109,93,1) 0%, rgba(250,90,79,1) 100%)"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                pb={3}
                                textAlign="center"
                            >
                                <Link to='/dashboard'>
                                    <img src={FoodhiLogoApp} alt="Foodhi Logo" height={100}/>
                                </Link>
                                {
                                    authorized && (
                                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                            Jemand möchte über deinen Foodhi-Shop bei dir bestellen.
                                        </MDTypography>
                                    )
                                }

                            </MDBox>

                            <MDBox pt={4} pb={3} px={3}>
                                <Grid container pb={3} sx={{display: "flex", textAlign: "center"}}>
                                    <Grid container direction="column" flex={1} mb={{xs: 5, sm: 1}}>
                                        <Grid
                                            item
                                            display="flex"
                                            flexDirection="column"
                                            justifyItems="center"
                                            alignItems="center"
                                            mb={3}
                                        >
                                            {
                                                authorized ? (
                                                    <>
                                                        <img
                                                            src={`https://admin.foodhi.de/assets/${restaurant.restaurant_thumbnail}?${THUMBNAIL_SETTINGS}`}
                                                            alt="Restaurant inviter"
                                                            height={140}
                                                            width={140}
                                                            style={{borderRadius: 100, marginBottom: 20}}
                                                        />
                                                        <MDTypography variant="h2" pt={2} sx={{fontSize: 18}}>
                                                            {restaurant.restaurant_name}
                                                        </MDTypography>
                                                        <MDTypography variant="p" pt={1} sx={{fontSize: 16}}>
                                                            <strong>Name:</strong> {restaurant.contact_first_name} {restaurant.contact_last_name}
                                                        </MDTypography>
                                                        <MDTypography variant="p" sx={{fontSize: 16}}>
                                                            <strong>E-Mail:</strong> {restaurant.contact_email}
                                                        </MDTypography>
                                                        <MDTypography variant="p" pb={4} sx={{fontSize: 16}}>
                                                            <strong>Telefon:</strong> {restaurant.contact_telephone}
                                                        </MDTypography>

                                                        <form onSubmit={acceptInvitation}>
                                                            <MDButton
                                                                variant="gradient"
                                                                color="foodhiOrange"
                                                                fullWidth
                                                                disabled={loading}
                                                                type="submit"
                                                                sx={{maxWidth: 300}}
                                                            >
                                                                {!loading ? "anfrage akzeptieren" :
                                                                    <TailSpin color="#fff" height={20}/>}
                                                            </MDButton>
                                                        </form>
                                                    </>
                                                ) : (
                                                    authorized === false
                                                        ? (
                                                            <MDBox px={3}>
                                                                <img
                                                                    src={require('assets/foodhi/others/error_plants.png')}
                                                                    alt="Restaurant inviter"
                                                                    height={140}
                                                                    width={140}
                                                                    style={{marginBottom: 30}}
                                                                />
                                                                <Typography>
                                                                    Du hast keinen Zugriff. Überprüfe, ob du mit dem richtigen Konto angemeldet bist.
                                                                </Typography>
                                                            </MDBox>

                                                        )
                                                        : <TailSpin/>
                                                )
                                            }


                                        </Grid>


                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </PageLayout>
    );
};

export default NewFarmerInvitation;
