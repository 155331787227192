import {useEffect} from "react";
import {useLocation, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
    useMaterialUIController,
    setMiniSidenav,
    setTransparentSidenav,
    setWhiteSidenav,
} from "context";
import Avatar from "@mui/material/Avatar";
import Logout from "../../layouts/authentication/Logout";
import {THUMBNAIL_SETTINGS, USER_PROFILE_PLACEHOLDER} from "../../variables";


function Sidenav({color, brand, brandName, routes, ...rest}) {
    const [controller, dispatch] = useMaterialUIController();
    // const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
    const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} = controller;
    const location = useLocation();
    const collapseName = location.pathname.replace("/", "");

    let textColor = "white";


    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = "dark";
    } else if (whiteSidenav && darkMode) {
        textColor = "inherit";
    }

    const shopName = useSelector(state => state.userProfile?.store_name);
    const userAvatar = useSelector(state => state.userProfile?.store_thumbnail);

    const closeSidenav = () => setMiniSidenav(dispatch, true);

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200);
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? whiteSidenav : whiteSidenav);
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener("resize", handleMiniSidenav);

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleMiniSidenav);
    }, [dispatch, location]);

    // Render all the routes from the routes.js (All the visible items on the Sidenav)
    const renderRoutes = routes.map(({type, name, icon, title, noCollapse, key, href, route}) => {
        let returnValue;

        if (type === "collapse") {
            returnValue = href ? (
                <Link
                    href={href}
                    key={key}
                    target="_blank"
                    rel="noreferrer"
                    sx={{textDecoration: "none"}}
                >
                    <SidenavCollapse
                        name={name}
                        icon={icon}
                        active={key === collapseName}
                        noCollapse={noCollapse}
                    />
                </Link>
            ) : (
                <NavLink key={key} to={route}>
                    <SidenavCollapse name={name} icon={icon} active={key === collapseName}/>
                </NavLink>
            );
        } else if (type === "title") {
            returnValue = (
                <MDTypography
                    key={key}
                    color={textColor}
                    display="block"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="uppercase"
                    pl={3}
                    mt={2}
                    mb={1}
                    ml={1}
                >
                    {title}
                </MDTypography>
            );
        } else if (type === "divider") {
            returnValue = (
                <Divider
                    key={key}
                    light={
                        (!darkMode && !whiteSidenav && !transparentSidenav) ||
                        (darkMode && !transparentSidenav && whiteSidenav)
                    }
                />
            );
        }

        return returnValue;
    });

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{transparentSidenav, whiteSidenav, miniSidenav}}
        >
            <MDBox pt={3} pb={1} px={4} textAlign="center">
                <MDBox
                    display={{xs: "block", xl: "none"}}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{cursor: "pointer"}}
                >
                    <MDTypography variant="h6" color="secondary">
                        <Icon sx={{fontWeight: "bold"}}>close</Icon>
                    </MDTypography>
                </MDBox>
                <MDBox component={NavLink} to="/" display="flex" alignItems="center">
                    {/* {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" />} */}
                    <Avatar
                        alt={`${shopName} Logo`}
                        src={userAvatar
                            ? `${process.env.REACT_APP_SITE_URL}/assets/${userAvatar.id}?${THUMBNAIL_SETTINGS}`
                            : `${process.env.REACT_APP_SITE_URL}/assets/${USER_PROFILE_PLACEHOLDER}`
                        }
                        sx={{marginRight: 1}}
                    />
                    <MDBox
                        width={!brandName && "100%"}
                        sx={(theme) => sidenavLogoLabel(theme, {miniSidenav})}
                    >
                        <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
                            {shopName}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
            <Divider
                light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                }
            />
            <List>{renderRoutes}</List>

            <Logout/>


            {/* <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          href="https://www.creative-tim.com/product/material-dashboard-pro-react"
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
        >
          upgrade to pro
        </MDButton>
      </MDBox> */}
        </SidenavRoot>
    );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
    color: "info",
    brand: "Foodhi",
};
/* eslint-disable */
// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark", "foodhiOrange"]),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};
/* eslint-disable */

export default Sidenav;
