import * as actionTypes from "./types";

export const loadingStart = () => (
    {
        type: actionTypes.LOADING_START,
    }
)

export const loadingStop = () => (
    {
        type: actionTypes.LOADING_STOP,
    }
)

export const openProductCreateDialog = (product) => async dispatch => {
    dispatch({
        type: actionTypes.OPEN_PRODUCT_CREATE_DIALOG,
        payload: product.product
    });
}
export const closeProductCreateDialog = () => async dispatch => {
    dispatch({
        type: actionTypes.CLOSE_PRODUCT_CREATE_DIALOG
    });
}
export const openProductEditDialog = (product) => async dispatch => {
    dispatch({
        type: actionTypes.OPEN_PRODUCT_EDIT_DIALOG,
        payload: product.product
    });
}
export const closeProductEditDialog = () => async dispatch => {
    dispatch({
        type: actionTypes.CLOSE_PRODUCT_EDIT_DIALOG
    });
}
export const openProductDeleteDialog = (product) => async dispatch => {
    dispatch({
        type: actionTypes.OPEN_PRODUCT_DELETE_DIALOG,
        payload: product.product
    });
}
export const closeProductDeleteDialog = () => async dispatch => {
    dispatch({
        type: actionTypes.CLOSE_PRODUCT_DELETE_DIALOG
    });
}


// CUSTOMERS
// export const fetchCustomers = () => async dispatch => {
//
//     /* eslint-disable no-await-in-loop */
//     await axios.get(`${process.env.REACT_APP_SITE_URL}/wp-json/wc/v3/customers/`,
//         {
//             params: {
//                 page: page += 1,
//                 per_page: 100,
//                 _fields: `email, is_activated, last_name, first_name, id, meta_data`
//             },
//             headers: {Authorization: `${localStorage.getItem('token')}`}
//         })
//         .then(response => {
//             setAllCustomers(response.data);
//
//             response.data.forEach(customer => {
//                 if (customer.is_activated) {
//                     customer.meta_data.forEach(metaData => {
//                         if (metaData.key === "_wcfm_vendor") {
//                             if (Array.isArray(metaData.value)) {
//                                 metaData.value.forEach(id => {
//                                     if (id === localStorage.getItem('id')) {
//                                         setCustomers(preCustomers => [...preCustomers, customer]);
//                                         setIsLoading(false);
//                                     }
//                                 })
//                             } else if (metaData.value === localStorage.getItem('id')) {
//                                 setCustomers(preCustomers => [...preCustomers, customer]);
//                                 setIsLoading(false);
//                             }
//                             // console.log('filtered-->', customer);
//                             // setCustomers(preCustomers => [...preCustomers, customer]);
//                         }
//                     });
//                 }
//                 setIsLoading(false);
//             });
//         })
//         .catch(error => console.log("error custoemr list", error))
//
//
//     // Handle success.
//     dispatch({
//         type: actionTypes.FETCH_CUSTOMERS,
//         payload: fetchedCustomers
//     });
// }