import React, {useEffect, useState} from 'react';
import bgImage from "../../../assets/foodhi/bg/sign_in_bg_alt.jpeg";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import FoodhiLogoApp from "../../../assets/foodhi/logo/foodhi_logo_no_bg.png";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import IconButton from "@mui/material/IconButton";
import {Icon, Typography} from "@mui/material";
import MDButton from "../../../components/MDButton";
import {TailSpin} from "react-loader-spinner";
import BasicLayout from "../components/BasicLayout";
import {useDispatch, useSelector} from "react-redux";
import {loadingStart, loadingStop} from "../../../reduxStore/actions/actionUI";
import {toast} from "react-toastify";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import {WarningAmber} from "@mui/icons-material";
import colors from "../../../assets/theme/base/colors";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";

const NewUserRegistration = () => {
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [passwordShown, setPasswordShown] = useState(false);

    const dispatch = useDispatch();
    // const loading = useSelector(state => state.UI.loading);
    const loading = false

    const navigation = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const checkIfLoggedIn = useSelector(state => state.auth.loggedIn);

    useEffect(() => {
        if (checkIfLoggedIn) {
            navigation('/dashboard')
            toast.info("Du bist gerade eingeloggt.")
        }
    }, []);

    // CHECK IF TOKEN HAS EXPIRED
    const isExpired = dayjs.unix(jwt_decode(searchParams.get("token")).exp).diff(dayjs()) < 1;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === passwordRepeat) {
            dispatch(loadingStart())
            const data = {
                "token": searchParams.get("token"),
                "password": password,
            }
            axios.post(`${process.env.REACT_APP_SITE_URL}/users/invite/accept`, data)
                .then((response) => {
                    const loginData = {
                        email: searchParams.get("restaurant_email"),
                        password
                    }
                    // USE PASSWORD TO LOGIN AND GET TOKEN
                    console.log("LOGIN DATA", loginData)
                    axios.post(`${process.env.REACT_APP_SITE_URL}/auth/login`, loginData)
                        .then((response) => {
                            const token = response.data.data.access_token;
                            // USE TOKEN TO PATCH FIELD IN USER AND SAVE THE INVITER ID
                            axios.patch(`${process.env.REACT_APP_SITE_URL}/users/me`,
                                {inviter_id: searchParams.get("farmer_id")},
                                {headers: {Authorization: `Bearer ${token}`}}
                            )
                                .then(response => {
                                    console.log(response.data, "PATCHED OK")
                                    getAxiosInstance(store).patch(`/items/farmer_profile_restaurant_profile/${response.data.data.fk_provider_farmer}`, {
                                        status: "connected"
                                    })
                                })
                                .catch(error => console.log(error.response, "PATCH ERROR"))
                        })
                        .catch(error => console.log(error.response, "LOGIN ERROR"))
                    dispatch(loadingStop())
                    navigation('/download-app')
                })
                .catch(error => {
                    console.log(error.response)
                    toast.error("Etwas ist schiefgelaufen. Bitte kontaktiere uns unter tech@foodhi.de.");
                    dispatch(loadingStop())
                })
        } else {
            toast.error("Passwörter sind nicht gleich");
            dispatch(loadingStop())
        }
    }

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    bgColor="linear-gradient(90deg, rgba(253,109,93,1) 0%, rgba(250,90,79,1) 100%)"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    pb={3}
                    textAlign="center"
                >
                    <img src={FoodhiLogoApp} alt="Foodhi Logo" height={100}/>
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Benutzerkonto erstellen
                    </MDTypography>

                </MDBox>
                <MDBox pt={4} pb={3} px={3}>

                    {
                        !isExpired ? (
                            <form onSubmit={handleSubmit}>

                                <MDBox mb={2}>
                                    <MDInput
                                        type={passwordShown ? "text" : "password"}
                                        label="Passwort"
                                        required
                                        fullWidth
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                    <IconButton
                                        size="medium"
                                        sx={{
                                            position: "absolute",
                                            right: 30,
                                            height: 44
                                        }}
                                        disableRipple
                                        mr={5}
                                        // sx={navbarMobileMenu}
                                        onClick={() => setPasswordShown(!passwordShown)}
                                    >
                                        <Icon fontSize="small" color="darkgray">
                                            {passwordShown ? "visibility" : "visibility_off"}
                                        </Icon>
                                    </IconButton>
                                </MDBox>

                                <MDBox mb={2}>
                                    <MDInput
                                        type={passwordShown ? "text" : "password"}
                                        label="Passwort wiederholen"
                                        required
                                        fullWidth
                                        onChange={(e) => setPasswordRepeat(e.target.value)}
                                        value={passwordRepeat}
                                    />
                                    <IconButton
                                        size="medium"
                                        sx={{
                                            position: "absolute",
                                            right: 30,
                                            height: 44
                                        }}
                                        disableRipple
                                        mr={5}
                                        // sx={navbarMobileMenu}
                                        onClick={() => setPasswordShown(!passwordShown)}
                                    >
                                        <Icon fontSize="small" color="darkgray">
                                            {passwordShown ? "visibility" : "visibility_off"}
                                        </Icon>
                                    </IconButton>
                                </MDBox>

                                <MDBox mt={4} mb={3}>
                                    <MDButton
                                        variant="gradient"
                                        color="foodhiOrange"
                                        fullWidth
                                        disabled={loading}
                                        type="submit"
                                    >
                                        {!loading ? "bestätigen" : <TailSpin color="#fff" height={20}/>}
                                    </MDButton>
                                </MDBox>

                                <MDTypography color="text" fontSize={12} sx={{textAlign:"center"}}>
                                    Hier kannst du dir unsere
                                    <a style={{color: "red"}} href="https://wp.foodhi.de/datenschutzerklaerung/" target="_blank"> Datenschutzerklärung </a>
                                    und unsere <a style={{color: "red"}} href="https://foodhi.de/agb/" target="_blank"> Allgemeinen Geschäftsbedingungen </a> ansehen.
                                </MDTypography>
                            </form>
                        ) : (
                            <MDBox mb={2}>
                                <MDBox sx={{textAlign: "center"}}>
                                    <WarningAmber fontSize="large" style={{marginBottom: 15} }/>
                                </MDBox>
                                <Typography fontSize={18}>
                                    Der Einladungslink ist abgelaufen. Bitte deinen Produzent*innen, dir eine neue Einladung zu schicken.
                                </Typography>
                            </MDBox>
                        )
                    }

                </MDBox>
            </Card>
        </BasicLayout>
    );
};

export default NewUserRegistration;
