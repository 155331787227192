import React, {useEffect, useState} from 'react';
import VerticalBarChart from "../../../examples/Charts/BarCharts/VerticalBarChart";
import {TailSpin} from "react-loader-spinner";

const MostSoldBarChart = ({orders, loading}) => {
    const [mostSoldData, setMostSoldData] = useState({});

    useEffect(() => {
        let allOrderItems = [];
        // GET THE NAMES OF THE PRODUCTS INSIDE THE ORDERS
        orders.forEach(order => {
            const {name, updated_subtotal, quantity} = order.fk_order_items[0];
            const price = Number(updated_subtotal) * quantity;

            allOrderItems.push({name, price})
        });

        // SUM UP ALL DIFFERENT PRODUCTS INTO ONE
        const arrayByName = [];
        for (const element of allOrderItems) {
            const {name, price} = element;

            const index = arrayByName.findIndex(obj => obj.name === name);
            if (index !== -1) {
                arrayByName[index].price += price;
                // arrayByName[index].quantity += quantity;
            } else {
                arrayByName.push({name, price});
            }
        }

        setMostSoldData({
            labels: arrayByName.map(item => item.name),
            datasets: [{
                label: " Umsatz",
                color: "foodhiOrange",
                data: arrayByName.map(item => item.price)
            }]
        })
    }, [orders])

    return (
        !loading ? (
            <VerticalBarChart
                icon={{color: "info", component: "leaderboard"}}
                title="Verkaufte Produkte"
                description="Anzahl der verkauften Produkte"
                chart={mostSoldData}
            />
        ) : (
            <TailSpin/>
        )

    );
};

export default MostSoldBarChart;
