import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {AddCircleRounded, CloseRounded} from "@mui/icons-material";
import {TailSpin} from "react-loader-spinner";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {loadingStop} from "../../../reduxStore/actions/actionUI";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {FOLDER_PRODUCT_IMAGES, FOLDER_STORE_BANNERS} from "../../../variables";
import {
    deleteStoreBanners,
    updateStoreBanners,
    uploadStoreBanners
} from "../../../reduxStore/actions/actionUserProfile";
import {upload} from "@testing-library/user-event/dist/upload";

function MultiImageBanner() {

    const banners = useSelector(state => state.userProfile.store_banners);
    const farmer_id = useSelector(state => state.userProfile.id);
    const farmerName = useSelector(state => state.userProfile?.store_name);
    // const loading = useSelector(state => state.UI.loading);
    const loading = false;

    const dispatch = useDispatch();

    // UPLOAD PICTURE
    const onDrop = useCallback(acceptedFiles => {
        if (banners.length >= 5) {
            toast.error("Du kannst maximal 5 Banner hochladen.")
        } else if (acceptedFiles[0].size < 3145728) {

            const formData = new FormData();

            formData.append('title', `${farmerName}_Banner_${acceptedFiles[0].name}`);
            formData.append('folder', FOLDER_STORE_BANNERS);
            formData.append('file', acceptedFiles[0])

            dispatch(uploadStoreBanners(formData, farmer_id))

        } else toast.error("Max Bildgröße ist 3mb.")

    }, [])

    // REMOVE PICTURE
    const removePicture = (image) => {
        if (confirm("Bist du sicher, dass du dieses Bild löschen möchtest?")) {
            dispatch(deleteStoreBanners(banners, image, farmer_id))
        }
    }

    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, noClick: true})

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()}/>
            <div style={{
                height: 140,
                padding: 10,
                border: "1px solid lightgray",
                alignItems: "center",
                display: "flex",
                borderRadius: 8,
                overflowX: banners?.length ? "scroll" : null,
            }}>
                {
                    (() => {

                            if (isDragActive) {
                                return <p>Die Dateien hier ablegen ...</p>
                            }
                            if (banners?.length) {
                                return (
                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        {banners.map((banner, index) => {
                                                if (loading && (index === (banners.length - 1))) {
                                                    return (
                                                        <TailSpin/>
                                                    )
                                                }
                                                return (
                                                    <div key={banner.id} style={{position: "relative", display: "flex", height: 100}}>
                                                        <img
                                                            src={`${process.env.REACT_APP_SITE_URL}/assets/${banner.directus_files_id}`}
                                                            alt={`Banner #${index}`}
                                                            style={{height: "100%", borderRadius: 6, marginRight: 10}}
                                                        />
                                                        <CloseRounded
                                                            onClick={() => removePicture(banner.directus_files_id)}
                                                            style={{
                                                                zIndex: 9,
                                                                position: "absolute",
                                                                top: 8,
                                                                right: 18,
                                                                backgroundColor: "lightgray",
                                                                borderRadius: 20,
                                                                cursor: "pointer"
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            }
                                        )}
                                        <AddCircleRounded onClick={open} fontSize="large" style={{
                                            color: "lightgray",
                                            marginLeft: 5,
                                            cursor: "pointer"
                                        }}/>
                                    </div>
                                )
                            }
                            return (
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    textAlign: "center"
                                }}>
                                    <p style={{flex: 1, padding: 20, fontSize: 18}}>
                                        Ziehe einige Dateien hierher, oder klicke
                                    </p>
                                    <AddCircleRounded onClick={open} fontSize="large" style={{
                                        color: "lightgray",
                                        marginLeft: 5,
                                        flex: 1,
                                        cursor: "pointer"
                                    }}/>
                                </div>
                            )
                        }
                    )()
                }
            </div>
        </div>
    );

}

export default MultiImageBanner;