import axios from "axios";
import {createEmailData} from "./templates/createEmailData";

export default function sendEmail({templateId, data, recipient}) {

    const sender = {email: "hallo@foodhi.de", name: "Foodhi"};

    const template = JSON.stringify(
        createEmailData(sender, recipient, templateId, data)
    );

    console.log("Template", template);

    axios.get(`${process.env.REACT_APP_SITE_URL}/api/sg-mail?template=${encodeURIComponent(template)}`)
        .then((response) => console.log("sent", response))
        .catch((err) => console.error(err.response));
}
