import React, {useEffect, useState} from 'react';
import {Autocomplete, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import MDTypography from "../../../../../components/MDTypography";
import MDInput from "../../../../../components/MDInput";
import {fetchCustomers} from "../../../../../reduxStore/actions/actionCustomers";
import {Spinner} from "react-bootstrap";

const ClientSelector = ({selectedCustomerCallback, customCustomerCallback}) => {

    const dispatch = useDispatch();

    const clients = useSelector(state => state.customers.linkedCustomers);
    const farmerId = useSelector(state => state.userProfile.id);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerInputValue, setSelectedCustomerInputValue] = useState('');

    const [address, setAddress] = useState({
        first_name: '',
        last_name: '',
        company: '',
        phone: '',
        street_1: '',
        postcode: '',
        city: 'Berlin',
        country: 'Deutschland'
    });

    useEffect(() => {
        dispatch(fetchCustomers(farmerId));
    }, []);


    useEffect(() => {
        if (selectedCustomer || selectedCustomer === '') {
            selectedCustomerCallback(selectedCustomer.value)
        }
    }, [selectedCustomer]);

    useEffect(() => {
        customCustomerCallback(address)
    }, [address]);


    const fullAddress = (client) => {
        if (client.restaurant_profile_id.fk_shipping_address) {
            return `${client.restaurant_profile_id.fk_shipping_address?.street_1}, ${client.restaurant_profile_id.fk_shipping_address?.postcode}, ${client.restaurant_profile_id.fk_shipping_address?.city}`
        } else return "N/A"
    }

    const parseClients = () => {
        const arr = [];
        clients.forEach(client => {
            arr.push({
                label: `${client.restaurant_profile_id.restaurant_name} ( ${fullAddress(client)} )`,
                value: client.restaurant_profile_id
            })
        })
        arr.push({
            label: "Anderer Kunde...",
            value: "andere"
        })
        return arr;
    }

    if (!clients) {
        return <Spinner/>
    } else {
        return (
            <>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={parseClients()}
                    value={selectedCustomer}
                    onChange={(e, newValue, reason) => {
                        if (reason === 'clear') {
                            setSelectedCustomer('')
                        } else {
                            setSelectedCustomer(newValue)
                        }
                    }}
                    inputValue={selectedCustomerInputValue}
                    onInputChange={(e, newInputValue, reason) => {
                        if (reason === 'clear') {
                            setSelectedCustomerInputValue('')
                        } else {
                            setSelectedCustomerInputValue(newInputValue)
                        }
                    }}
                    sx={{width: 500}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="medium"
                            label="Kunde"
                        />
                    )}
                />

                {/*<InputLabel id="bundle">Kunde</InputLabel>*/}
                {/*<Select*/}
                {/*    sx={{minHeight: 45}}*/}
                {/*    required*/}
                {/*    labelId="bundle-selector-label"*/}
                {/*    label="Kunde"*/}
                {/*    id="bundle-selector"*/}
                {/*    value={selectedCustomer}*/}
                {/*    onChange={e => setSelectedCustomer(e.target.value)}*/}
                {/*>*/}
                {/*    {*/}
                {/*        clients.map(client => {*/}
                {/*            return (*/}
                {/*                <MenuItem value={client.restaurant_profile_id}>*/}
                {/*                    {client.restaurant_profile_id.restaurant_name} ( {fullAddress(client)} )*/}
                {/*                </MenuItem>*/}
                {/*            )*/}
                {/*        })*/}
                {/*    }*/}
                {/*    <MenuItem value={"andere"}>*/}
                {/*        Andere*/}
                {/*    </MenuItem>*/}
                {/*</Select>*/}

                {
                    selectedCustomer?.value === "andere" && (
                        <Grid py={3} container spacing={2}>
                            <Grid item md={12}>
                                <MDTypography variant="span" fontSize={16}>
                                    Name und Adresse hinzufügen:
                                </MDTypography>
                            </Grid>

                            <Grid item md={6}>
                                {/*// FIRST NAME*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        value={address.first_name}
                                        label="Vorname"
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                first_name: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// LAST NAME*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Name"
                                        value={address.last_name}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                last_name: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// COMPANY*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Restaurant"
                                        value={address.company}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                company: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// PHONE*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Telefon"
                                        value={address.phone}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                phone: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// STREET 1*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Straße und Hausnr."
                                        value={address.street_1}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                street_1: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// POSTCODE*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Plz"
                                        value={address.postcode}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                postcode: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// CITY*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Stadt"
                                        value={address.city}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                city: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6}>
                                {/*// COUNTRY*/}
                                <FormControl fullWidth>
                                    <MDInput
                                        required
                                        label="Land"
                                        value={address.country}
                                        onChange={e => setAddress(prevState => {
                                            return {
                                                ...prevState,
                                                country: e.target.value
                                            }
                                        })}

                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                    )
                }
            </>
        )
    }


};

export default ClientSelector;
