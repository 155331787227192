import React, {useCallback, useEffect, useState} from 'react';
import MDBox from "../../../../../components/MDBox";
import {
    Box, Button,
    Dialog,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    Typography
} from "@mui/material";
import colors from "../../../../../assets/theme/base/colors";
import {
    Add,
    AddAlarm,
    AddCircle,
    AddShoppingCart,
    Close,
    Delete,
    Queue,
    Remove,
    WorkspacesOutlined
} from "@mui/icons-material";
import MDTypography from "../../../../../components/MDTypography";
import {addToCart, emptyCart, removeFromCart} from "../../../../../reduxStore/actions/actionEditCart";
import {useDispatch, useSelector} from "react-redux";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";

const AddRemoveProductsToCart = ({product}) => {

    const reduxCartItems = useSelector(state => state.shop.cartItems);
    const reduxQuantity = reduxCartItems.find(state => state.item.id === product.id)?.quantity;
    const reduxPrice = reduxCartItems.find(state => state.item.id === product.id)?.price;
    const reduxDiscount = reduxCartItems.find(state => state.item.id === product.id)?.discount;

    const [discount, setDiscount] = useState(0);
    const [availableStock, setAvailableStock] = useState(0);

    const [items, setItems] = useState([]);
    const [bundleChange, setBundleChange] = useState('');

    const [openBundleModal, setOpenBundleModal] = useState(false);

    const [bundlePackage, setBundlePackage] = useState('');

    const dispatch = useDispatch();

    // const bundlePackage = product?.fk_bundle_discounts?.sort((a, b) => b.bundle_quantity > a.bundle_quantity ? 1 : -1);
    // // const bundlePackage = product.fk_bundle_discounts;
    // //
    useEffect(() => {
        if (product?.fk_bundle_discounts.length > 0) {
            setBundlePackage(product?.fk_bundle_discounts);
        }
    }, [product]);


    // CHECK PRODUCT STOCK WHEN PRODUCT CARD LOADED
    useEffect(() => {
        // CHECK STOCK AVAILABILITY
        getAxiosInstance(store).get(`items/products/${product.id}`, {params: {fields: "stock_quantity"}})
            .then(response => setAvailableStock(response.data.data.stock_quantity))
            .catch(error => console.log(error))
    }, []);

    // PARSE PACKAGE BUNDLES OBJECT FOR THE DROPDOWN COMPONENT
    useEffect(() => {
        let parseBundlePackage = []
        if (bundlePackage?.length > 0) {
            bundlePackage.sort((a, b) => b.bundle_quantity > a.bundle_quantity ? 1 : -1).forEach(bundle => {
                if (bundle.bundle_quantity <= availableStock) {
                    parseBundlePackage.push({
                        label: `${bundle.bundle_quantity * product.amount} ${product.fk_unit_measurement.name} ${bundle.discount > 0 ? `(${Number(bundle.discount).toLocaleString("de-DE", {maximumFractionDigits: 2})}% Rabatt)` : ``}`,
                        // label: `${bundle.bundle_quantity * product.amount} ${product.fk_unit_measurement.name} ${bundle.discount > 0 ? `€${Number(reduxPrice)?.toLocaleString("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : ``}`,
                        inputLabel: " ",
                        value: bundle.bundle_quantity
                    })
                }
            })

            setItems(parseBundlePackage)
        }
    }, [bundlePackage, availableStock])

    // SET DISCOUNT WHEN AMOUNT CAHNGES ACCORDINGLY
    useEffect(() => {
        if (bundlePackage.length > 0) {
            const discount = bundlePackage?.find(bund => bund.bundle_quantity <= reduxQuantity)?.discount;
            if (reduxQuantity) {
                if (discount >= 0) {
                    dispatch(addToCart(product, reduxQuantity, discount))
                }
            } else {
                setDiscount(0)
            }
        }
    }, [reduxQuantity]);
    // WHEN SELECTING BUDNLE PACKAGE, APPLY CHANGES
    useEffect(() => {
        if (bundleChange) {
            if (bundleChange <= availableStock) {
                const amount = bundleChange;
                dispatch(addToCart(product, amount, reduxDiscount));
                setBundleChange('')
            }
        }
    }, [bundleChange]);

    const selectBundle = (item) => {
        setBundleChange(item.value);
        setOpenBundleModal(!openBundleModal)
    }

    const outOfStockAlert = () => {
        alert(`Es gibt nicht genug Produkte. Kontaktiere dein*e Produzent*in.`)
    }
    const renderAddFirstProduct = () => {
        return (
            <MDBox
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                py={0}
            >
                <IconButton
                    sx={{
                        textAlign: "center",
                        borderRadius: 8,
                        padding: .5
                    }}
                    onClick={() => {
                        if (!reduxQuantity) {
                            if (product.min_order_amount > product.stock_quantity) {
                                outOfStockAlert()
                            } else {
                                dispatch(addToCart(product, product.min_order_amount, discount));
                            }
                        } else {
                            // dispatch(removeFromCart(product))
                        }
                    }}
                >
                    <AddShoppingCart fontSize="small" color="error"/>
                </IconButton>
            </MDBox>
        )
    }
    const renderProductsInCart = () => {
        return (
            <>
                <Grid container direction="row" alignItems="center" spacing={0}>
                    <Grid xs={2} item>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {reduxQuantity * product.amount} {product.fk_unit_measurement.name}
                        </MDTypography>
                    </Grid>

                    <Grid xs={3} item>
                        <Grid container spacing={0.5}>
                            <Grid item>
                                <IconButton
                                    sx={{
                                        textAlign: "center",
                                        borderRadius: 8,
                                        padding: .4,
                                        backgroundColor: colors.foodhiOrange.main,
                                        '&:hover': {
                                            backgroundColor: colors.foodhiOrange.focus,
                                        },
                                    }}
                                    onClick={() => {
                                        product.amount_adjustment <= (reduxQuantity - product.min_order_amount)
                                            ? dispatch(addToCart(product, (reduxQuantity - product.amount_adjustment), discount))
                                            : alert(`Du musst mindestens ${product.min_order_amount} bestellen`)
                                    }}>
                                    <Remove fontSize="small" color="white"/>
                                </IconButton>
                            </Grid>
                            <Grid item width={30} textAlign="center">
                                <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                >
                                    {reduxQuantity}
                                </MDTypography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    sx={{
                                        textAlign: "center",
                                        borderRadius: 8,
                                        padding: .4,
                                        backgroundColor: colors.foodhiOrange.main,
                                        '&:hover': {
                                            backgroundColor: colors.foodhiOrange.focus,
                                        },
                                    }}
                                    onClick={() => {
                                        if (availableStock < (reduxQuantity + product.amount_adjustment)) {
                                            outOfStockAlert();
                                        } else {
                                            dispatch(addToCart(product, (reduxQuantity + product.amount_adjustment), reduxDiscount));
                                        }
                                    }}
                                >
                                    <Add fontSize="small" color="white"/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid xs={3} item>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            € {(reduxPrice * reduxQuantity).toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}
                        </MDTypography>
                        <Badge
                            invisible={!reduxDiscount}
                            badgeContent={`-${reduxDiscount?.toFixed(2)}%`}
                            color="info"
                            sx={{marginBottom: 5, marginLeft: 1}}
                        >
                        </Badge>
                    </Grid>

                    <Grid xs={2} item>
                        {items.length > 0 && (
                            // <FormControl sx={{width: 100}}>
                            //     <InputLabel id="bundle">
                            //         Gebinde
                            //     </InputLabel>
                            //     <Select
                            //         sx={{minHeight: 40}}
                            //         labelId="bundle-selector-label"
                            //         id="bundle-selector"
                            //         label="Gebinde"
                            //         value={bundleChange}
                            //         onChange={e => setBundleChange(e.target.value)}
                            //     >
                            //         {
                            //             items.sort((a, b) => a.value - b.value).map(item => {
                            //                 return <MenuItem value={item.value}>{item.label}</MenuItem>
                            //             })
                            //         }
                            //     </Select>
                            // </FormControl>

                            <WorkspacesOutlined
                                color="secondary"
                                fontSize="medium"
                                cursor="pointer"
                                onClick={() => setOpenBundleModal(!openBundleModal)}
                            />
                        )}
                    </Grid>

                    <Grid xs={2} item textAlign="right">
                        <IconButton
                            onClick={() => {
                                dispatch(removeFromCart(product))
                            }}
                            sx={{
                                // textAlign: "center",
                                // borderRadius: 8,
                                // padding: .4,
                                // backgroundColor: colors.foodhiOrange.main,
                                // '&:hover': {
                                //     backgroundColor: colors.foodhiOrange.focus,
                                // },
                            }}
                        >
                            <Close fontSize="small" color="foodhiOrange"/>
                        </IconButton>
                    </Grid>
                </Grid>

                <Dialog
                    open={openBundleModal}
                    onClose={() => setOpenBundleModal(!openBundleModal)}
                    aria-labelledby="Gebinde"
                    aria-describedby="Gebinde"
                >
                    <Box
                        height={60}
                        backgroundColor={colors.foodhiLightGreen.main}
                        display="flex"
                        alignItems="center"
                        pl={2}
                        fontWeight="bold"
                        color="#fff"
                    >
                        <Typography fontSize={18}>
                            Wähle eine Gebindegröße
                        </Typography>
                    </Box>
                    <Box sx={{width: 300}} py={1}>
                        {
                            items.length > 0 && (
                                items.sort((a, b) => a.value - b.value).map((item, index) => {
                                    return (
                                        <>
                                            <Button flexDirection="column" fullWidth onClick={() => selectBundle(item)}>
                                                <Typography px={1} fontSize={16} color="#3D3D3D">
                                                    {item.label}
                                                </Typography>
                                            </Button>
                                            {index !== items.length - 1 && <Divider sx={{margin: 0}}/>}
                                        </>


                                    )
                                })
                            )
                        }
                    </Box>
                </Dialog>
            </>
        )
    }

    return reduxQuantity ? renderProductsInCart() : renderAddFirstProduct()
}

export default AddRemoveProductsToCart;
