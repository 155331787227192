import * as actionTypes from "./types";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../store";

// PRODUCTS
export const fetchProducts = (userId) => async dispatch => {
    if(userId) {
        dispatch({type: actionTypes.FETCH_PRODUCTS_REQUESTED})
        getAxiosInstance(store).get(`items/products`,
            {
                params: {
                    // FILTER PRODUCTS BY CURRENT FARMER
                    "filter[fk_farmer][fk_user][id][_eq]": userId,
                    // ONLY FETCH PRODUCTS THAT ARE ACTIVE
                    "filter[status][_neq]": "archived",
                    "fields": "id," +
                        "images," +
                        "name," +
                        "description," +
                        "price," +
                        "stock_quantity," +
                        "status," +
                        "categories.*.*," +
                        "fk_farmer.id," +
                        "fk_tags.*.*," +
                        "tax.*.*," +
                        "fk_unit_measurement.*.*," +
                        "fk_bundle_discount.*.*, " +
                        "amount_adjustment, " +
                        "amount, " +
                        "taxes_enabled," +
                        "product_available_from," +
                        "min_order_amount," +
                        "fk_bundle_discounts.*," +
                        "min_order_amount, " +
                        "stock_quantity," +
                        "stock_quantity_reference"
                },
            })
            .then(response => {
                console.log(response.data.data)
                const products = response.data.data;

                dispatch({
                    type: actionTypes.FETCH_PRODUCTS_SUCCEEDED,
                    payload: products
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.FETCH_PRODUCTS_FAILED,
                    payload: error.message
                })
            })
    } else alert("Keine Benutzer-ID gefunden")


}