// CART ACTIONS
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const EMPTY_CART = 'EMPTY_CART';
export const PLACE_ORDER = 'PLACE_ORDER';
export const TOTAL_SUM = 'TOTAL_SUM';

// USER PROFILE ACTIONS
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const READ_ADDRESS = 'READ_ADDRESS';
export const PROFILE_DETAILS = 'PROFILE_DETAILS';
export const READ_USER_PROFILE = 'READ_USER_PROFILE';
export const READ_STORE_PROFILE = 'READ_STORE_PROFILE';
export const UPDATE_THUMBNAIL = 'UPDATE_THUMBNAIL';
export const UPDATE_BANNERS = 'UPDATE_BANNERS';
export const UPDATE_BUSINESS_DETAILS = 'UPDATE_BUSINESS_DETAILS';
export const UPDATE_BUSINESS_CATEGORIES = 'UPDATE_BUSINESS_CATEGORIES';

// AUTH ACTIONS
export const CONTINUE_AS_GUEST = 'CONTINUE_AS_GUEST';
export const RETRIEVE_TOKEN = 'RETRIEVE_TOKEN';
export const AUTH_REGISTER_GOOGLE = 'AUTH_REGISTER_GOOGLE';
export const AUTH_REGISTER_FACEBOOK = 'AUTH_REGISTER_FACEBOOK';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
export const SET_TOKENS = 'SET_TOKENS';

// UI ACTIONS
export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
export const DELIVERY_ZONES = 'DELIVERY_ZONES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_VENDORS = 'SET_VENDORS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_ORDERS = 'SET_ORDERS';
export const OPEN_PRODUCT_DELETE_DIALOG = 'OPEN_PRODUCT_DELETE_DIALOG';
export const CLOSE_PRODUCT_DELETE_DIALOG = 'CLOSE_PRODUCT_DELETE_DIALOG';
export const OPEN_PRODUCT_EDIT_DIALOG = 'OPEN_PRODUCT_EDIT_DIALOG';
export const CLOSE_PRODUCT_EDIT_DIALOG = 'CLOSE_PRODUCT_EDIT_DIALOG';
export const OPEN_PRODUCT_CREATE_DIALOG = 'CLOSE_PRODUCT_EDIT_DIALOG';
export const CLOSE_PRODUCT_CREATE_DIALOG = 'CLOSE_PRODUCT_EDIT_DIALOG';

// PRODUCT ACTIONS
export const FETCH_PRODUCTS_REQUESTED = 'FETCH_PRODUCTS_REQUESTED'
export const FETCH_PRODUCTS_SUCCEEDED = 'FETCH_PRODUCTS_SUCCEEDED'
export const FETCH_PRODUCTS_FAILED = 'FETCH_PRODUCTS_FAILED'
export const CREATE_PRODUCT = 'CREATE_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'

// ORDERS ACTIONS
export const FETCH_ORDERS_REQUESTED = 'FETCH_ORDERS_REQUESTED'
export const FETCH_ORDERS_SUCCEEDED = 'FETCH_ORDERS_SUCCEEDED'
export const FETCH_ORDERS_FAILED = 'FETCH_ORDERS_FAILED'


// CUSTOMER ACTIONS
export const FETCH_CUSTOMERS_REQUESTED = 'FETCH_CUSTOMERS_REQUESTED'
export const FETCH_CUSTOMERS_SUCCEEDED = 'FETCH_CUSTOMERS_SUCCEEDED'
export const FETCH_CUSTOMERS_FAILED = 'FETCH_CUSTOMERS_FAILED'
export const FETCH_ALL_CUSTOMERS = 'FETCH_ALL_CUSTOMERS'
export const ADD_EXISTING_CUSTOMER = 'ADD_EXISTING_CUSTOMER'
export const START_ADD_EXISTING_CUSTOMER = 'START_ADD_EXISTING_CUSTOMER'
export const ERROR_ADD_EXISTING_CUSTOMER = 'ERROR_ADD_EXISTING_CUSTOMER'
export const UNLINK_CUSTOMER = 'UNLINK_CUSTOMER'
export const START_UNLINK_CUSTOMER = 'START_UNLINK_CUSTOMER'
export const ERROR_UNLINK_CUSTOMER = 'ERROR_UNLINK_CUSTOMER'
export const FETCH_PENDING_RESTAURANTS = 'FETCH_PENDING_RESTAURANTS'
export const FETCH_RECURRENT_ORDERS = 'FETCH_RECURRENT_ORDERS'
export const DELETE_RECURRENT_ORDER = 'DELETE_RECURRENT_ORDER'



// ORDERS ACTIONS