/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import OrdersList from "./Orders";
import MDButton from "../../../components/MDButton";
import {Alert, Button, Icon, Modal, Typography} from "@mui/material";
import {useState} from "react";
import colors from "../../../assets/theme/base/colors";
import CreateNewOrder from "./Orders/Components/CreateNewOrder";
import {emptyCart} from "../../../reduxStore/actions/actionEditCart";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {CloseOutlined} from "@mui/icons-material";


function Orders() {

    const [modalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch();
    const reduxCartItems = useSelector(state => state.shop.cartItems);

    const createNewOrderButton = () => (
        <MDBox px={4} py={2} textAlign="right">
            <MDButton
                variant="gradient"
                color="foodhiOrange"
                onClick={() => setModalVisible(!modalVisible)}
                sx={{
                    borderRadius: 8,
                    // width: 40,
                    // height: 40,
                    minWidth: 0,
                    paddingHorizontal: 20,
                    marginRight: window.innerWidth < 1200 ? 0 : "2rem",
                    gap: 1
                }}
            >
                <Icon sx={{fontWeight: "bold"}}>add_shopping_cart</Icon>
                <Typography variant="span" fontWeight="standard" fontSize={14}>
                    neue bestellung
                </Typography>
                {/* &nbsp;add product */}
            </MDButton>
        </MDBox>
    )

    const setModalVisibleCallback = (data) => {
        setModalVisible(data)
    }

    const onCloseModal = () => {
        dispatch(emptyCart())
        setModalVisible(!modalVisible);
    }

    return (
        <>
            <DashboardLayout>
                <DashboardNavbar/>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="foodhiLightGreen"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Bestellungen
                                    </MDTypography>
                                </MDBox>

                                {createNewOrderButton()}

                                <OrdersList/>

                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer/>
            </DashboardLayout>

            <Modal
                open={modalVisible}
                onClose={onCloseModal}
                aria-labelledby="Bestellung erstellen"
                aria-describedby="Bestellung erstellen"
                style={{overflowY: "auto"}}
            >
                <MDBox pt="5rem" pb={3} margin="auto" maxWidth={1200}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={10} margin="auto">
                            <Card sx={{backgroundColor: "#f1f1f1"}}>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={2}
                                    px={2}
                                    variant="gradient"
                                    bgColor="foodhiLightGreen"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Neue Bestellung erstellen
                                    </MDTypography>

                                    <CloseOutlined
                                        color="light"
                                        sx={{
                                            backgroundColor: colors.foodhiOrange.main,
                                            borderRadius: 50
                                        }}
                                        onClick={() => {
                                            console.log(reduxCartItems)
                                            if (reduxCartItems.length > 0) {
                                                if (confirm("Es befinden sich noch einige Artikel im Warenkorb. Wenn du das Fenster schließt, gehen die Änderungen verloren.")) {
                                                    onCloseModal();
                                                    toast.info("Warenkorb wurde geleert.")
                                                }
                                            } else {
                                                onCloseModal()
                                            }
                                        }}
                                    />

                                </MDBox>

                                <MDBox pt={3}>
                                    <CreateNewOrder setModalVisibleCallback={setModalVisibleCallback}/>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </Modal>
        </>
    )
        ;
}

export default Orders;
