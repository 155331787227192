import React, {useEffect, useState} from 'react';
import {TextField, Typography} from "@mui/material";
import {TailSpin} from "react-loader-spinner";
import {toast} from "react-toastify";
import store from "../../../../reduxStore/store";
import {getAxiosInstance} from "../../../../utils/interceptor";
import MDButton from "../../../../components/MDButton";
import {useDispatch, useSelector} from "react-redux";
import {loadingStart, loadingStop} from "../../../../reduxStore/actions/actionUI";

const ContentMyProfile = () => {
        const [profileData, setProfileData] = useState({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            address: {
                company: '',
                street_1: '',
                street_2: '',
                postcode: '',
                city: '',
                country: ''
            }
        });
        const loading = useSelector(state => state.UI.loading);

        const dispatch = useDispatch();

        const axiosParams = {
            params: {
                fields: "id, " +
                    "first_name, " +
                    "last_name, " +
                    "fk_farmer_profile.fk_address.*.*, " +
                    "fk_farmer_profile.id, " +
                    "fk_farmer_profile.contact_telephone, " +
                    "fk_farmer_profile.contact_first_name, " +
                    "fk_farmer_profile.contact_last_name, " +
                    "fk_farmer_profile.contact_email, " +
                    "fk_farmer_profile.contact_email, " +
                    "fk_farmer_profile.store_thumbnail"
            }
        }

        useEffect(() => {
            dispatch(loadingStart())
            getAxiosInstance(store).get('/users/me', axiosParams)
                .then(response => {
                    dispatch(loadingStop());
                    const data = response.data.data.fk_farmer_profile[0];

                    setProfileData(prevState => ({
                        ...prevState,
                        id: data.id,
                        first_name: data.contact_first_name,
                        last_name: data.contact_last_name,
                        email: data.contact_email,
                        phone: data.contact_telephone,
                        ...(data.fk_address && {
                            address: {
                                id: data.fk_address.id,
                                company: data.fk_address.company,
                                street_1: data.fk_address.street_1,
                                street_2: data.fk_address.street_2,
                                postcode: data.fk_address.postcode,
                                city: data.fk_address.city,
                                country: data.fk_address.country
                            }
                        })
                    }))
                })
                .catch(error => {
                    dispatch(loadingStop());
                    error.response
                })
        }, []);

        const submitProfileDetails = () => {
            dispatch(loadingStart());

            const submitData = {
                contact_first_name: profileData.first_name,
                contact_last_name: profileData.last_name,
                contact_email: profileData.email,
                contact_telephone: profileData.phone,
                fk_address: {
                    ...(profileData.address.id && {id: profileData.address.id}),
                    first_name: profileData.first_name,
                    last_name: profileData.last_name,
                    phone: profileData.phone,
                    company: profileData.address.company,
                    street_1: profileData.address.street_1,
                    street_2: profileData.address.street_2,
                    postcode: profileData.address.postcode,
                    city: profileData.address.city,
                    country: profileData.address.country
                }
            }

            getAxiosInstance(store).patch(`/items/farmer_profile/${profileData.id}?fields=*.*.*`, submitData)
                .then(() => {
                    setTimeout(() => {
                        dispatch(loadingStop());
                        toast.success('Persönlichen Daten wurden erfolgreich aktualisiert.',
                            {position: toast.POSITION.TOP_CENTER}
                        );
                    }, 1000)
                })
                .catch((error) => {
                    dispatch(loadingStop());
                    toast.error('Es ist ein Fehler aufgetreten, bitte versuche es erneut.',
                        {position: toast.POSITION.TOP_CENTER}
                    );
                    console.log(error.response)
                })

        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col mb-5">
                        <Typography fontSize={32}>Kontaktdetails</Typography>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{maxWidth: 400}}>
                        <form>
                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="standard-basic"
                                    value={profileData.first_name}
                                    variant="standard"
                                    type="text"
                                    label="Vorname"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        first_name: e.target.value
                                    }))}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="standard-basic"
                                    type="text"
                                    value={profileData.last_name}
                                    variant="standard"
                                    label="Name"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        last_name: e.target.value
                                    }))}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    type="email"
                                    margin="normal"
                                    fullWidth
                                    id="standard-basic"
                                    value={profileData.email}
                                    variant="standard"
                                    label="E-Mail"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        email: e.target.value
                                    }))}
                                />
                            </div>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    type="text"
                                    fullWidth
                                    id="standard-basic"
                                    value={profileData.phone}
                                    variant="standard"
                                    label="Telefon"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        phone: e.target.value
                                    }))}
                                />
                            </div>

                            <Typography fontSize={16} marginTop={5} color="gray">Rechnungsadresse</Typography>

                            <div className="form-group">
                                <TextField
                                    margin="normal"
                                    type="text"
                                    fullWidth
                                    id="standard-basic"
                                    value={profileData.address.company}
                                    variant="standard"
                                    label="Firma"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        address: {
                                            ...prevState.address,
                                            company: e.target.value
                                        }
                                    }))}
                                />
                                <TextField
                                    margin="normal"
                                    type="text"
                                    fullWidth
                                    id="standard-basic"
                                    value={profileData.address.street_1}
                                    variant="standard"
                                    label="Adresse 1"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        address: {
                                            ...prevState.address,
                                            street_1: e.target.value
                                        }
                                    }))}
                                />
                                <TextField
                                    margin="normal"
                                    type="text"
                                    fullWidth
                                    id="standard-basic"
                                    value={profileData.address.street_2 ? profileData.address.street_2 : ''}
                                    variant="standard"
                                    label="Adresse 2"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        address: {
                                            ...prevState.address,
                                            street_2: e.target.value
                                        }
                                    }))}
                                />
                                <div className="d-flex justify-content-between">
                                    <TextField
                                        margin="normal"
                                        type="number"
                                        id="standard-basic"
                                        value={profileData.address.postcode}
                                        variant="standard"
                                        label="PLZ"
                                        onChange={(e) => setProfileData(prevState => ({
                                            ...prevState,
                                            address: {
                                                ...prevState.address,
                                                postcode: e.target.value
                                            }
                                        }))}
                                    />
                                    <TextField
                                        margin="normal"
                                        type="text"
                                        id="standard-basic"
                                        value={profileData.address.city}
                                        variant="standard"
                                        label="Stadt"
                                        onChange={(e) => setProfileData(prevState => ({
                                            ...prevState,
                                            address: {
                                                ...prevState.address,
                                                city: e.target.value
                                            }
                                        }))}
                                    />
                                </div>

                                <TextField
                                    margin="normal"
                                    type="text"
                                    id="standard-basic"
                                    value={profileData.address.country}
                                    variant="standard"
                                    label="Land"
                                    onChange={(e) => setProfileData(prevState => ({
                                        ...prevState,
                                        address: {
                                            ...prevState.address,
                                            country: e.target.value
                                        }
                                    }))}
                                />
                            </div>

                            <div className="pt-5 pb-4">
                                <MDButton
                                    variant="gradient"
                                    color="foodhiOrange"
                                    onClick={submitProfileDetails}
                                    disabled={loading}
                                >
                                    {!loading ? "aktualisieren" : <TailSpin color="#fff" height={20}/>}
                                </MDButton>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }
;

export default ContentMyProfile;