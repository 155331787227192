export const templates = {
  RESTAURANT_Order_Received: "d-a832de8fde6d4dd78871a6cccf8fead4",
  RESTAURANT_Order_Shipped: "d-f76abff3fc1b4a3cacce900ee85abfaf",
  RESTAURANT_Order_Completed: "d-3214ea7d246f4d1e8ea80c251fac592c",
  RESTAURANT_Incoming_Invitation_New_User: "d-e520e13713944f3aabed8c8d91d87663",
  RESTAURANT_Incoming_Invitation_Existing_User: "d-1e4a39a9d9f54f63b4c5ac5e902679a7",
  RESTAURANT_Recurrent_Order_Cancelled_By_Farmer: "d-9746f09fe411480ebdd685f5c061a30f",
  RESTAURANT_Order_Cancelled: "d-53bdb9165d36474cbf6d10a2623ca5e3",
  RESTAURANT_Accepted_Invitation_from_Farmer: "d-6e600bf5956741bcb9fe740b0d4ab035",
  FARMER_Lieferschein_Not_Created: "d-c4b89f624dd34cba837c9d10350e843b",
  FARMER_Manual_Order_Created: "d-e3f2d85de0ce43e7b51f2845cc0198b2"
};
