/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useMemo, useState} from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import {Line} from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// DefaultLineChart configurations
import configs from "examples/Charts/LineCharts/DefaultLineChart/configs";

// Material Dashboard 2 React base styles
import gradients from "../../../../assets/theme/base/colors";
import {Autocomplete, TextField} from "@mui/material";
import {useSelector} from "react-redux";

function DefaultLineChart({
                              icon,
                              title,
                              description,
                              height,
                              chart,
                              customers,
                              calculatedYearChart,
                              selectedCustomer,
                              setSelectedCustomerCallback
                          }) {

    const clients = useSelector(state => state.customers.linkedCustomers);
    const [selectedCustomerInputValue, setSelectedCustomerInputValue] = useState('');


    const chartDatasets = chart.datasets
        ? chart.datasets.map((dataset) => ({
            ...dataset,
            tension: 0.15,
            pointRadius: 3,
            borderWidth: 4,
            backgroundColor: "transparent",
            fill: true,
            pointBackgroundColor: dataset.color,
            borderColor: dataset.color,
            maxBarThickness: 6,

        }))
        : [];

    const {data, options} = configs(chart.labels || [], chartDatasets);

    // MODULE THAT CONTAINS A SELECT CUSTOMER DROPDOWN
    const RenderCustomerSelect = () => {
        const parseClients = () => {
            const arr = [];
            arr.push({
                label: "Alle",
                value: "Alle"
            })
            calculatedYearChart.datasets.forEach(client => {
                arr.push({
                    label: `${client.label}`,
                    value: client.label
                })
            })
            return arr;
        }

        return (
            <Autocomplete
                disablePortal
                id="report-clients-autocomplete"
                options={parseClients()}
                sx={{minWidth: 300}}
                value={selectedCustomer}
                onChange={(e, newValue, reason) => {
                    console.log(reason)
                    if(reason === "clear") {
                        setSelectedCustomerCallback("Alle")
                    } else {
                        setSelectedCustomerCallback(newValue.value)
                    }
                }}
                inputValue={selectedCustomerInputValue}
                onInputChange={(e, newInputValue) => setSelectedCustomerInputValue(newInputValue)}
                renderInput={(params) => <TextField {...params} label="Kunde"/>}
            />
        )

        // return (
        //     <FormControl fullWidth>
        //         {/*<InputLabel id="customer-select-label">Kunden</InputLabel>*/}
        //         {/*<Select*/}
        //         {/*    labelId="customer-select-label"*/}
        //         {/*    id="customer-select"*/}
        //         {/*    value={selectedCustomer}*/}
        //         {/*    label="Customers"*/}
        //         {/*    style={{height: "2.5rem"}}*/}
        //         {/*    onChange={(e) => setSelectedCustomer(e.target.value)}*/}
        //         {/*>*/}
        //         {/*    <MenuItem value="Alle">*/}
        //         {/*        Alle*/}
        //         {/*    </MenuItem>*/}
        //         {/*    {calculatedYearChart.datasets ? (*/}
        //         {/*        calculatedYearChart.datasets.map((item) => (*/}
        //         {/*            <MenuItem key={item.label} value={item.label}>*/}
        //         {/*                {item.label}*/}
        //         {/*            </MenuItem>*/}
        //         {/*        ))*/}
        //         {/*    ) : (*/}
        //         {/*        <MenuItem>Loading..</MenuItem>*/}
        //         {/*    )}*/}
        //         {/*</Select>*/}
        //     </FormControl>
        // )
    }

    const renderChart = (
        <MDBox
            py={2}
            pr={2}
            pl={icon.component ? 1 : 2}
        >
            {title || description ? (
                <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
                    {icon.component && (
                        <MDBox
                            width="4rem"
                            height="4rem"
                            style={{backgroundColor: gradients.foodhiLightGreen.main}}
                            variant="gradient"
                            coloredShadow="info"
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            mt={-5}
                            mr={2}
                        >
                            <Icon fontSize="medium">{icon.component}</Icon>
                        </MDBox>
                    )}
                    <MDBox sx={{flex: 1}} mt={icon.component ? -2 : 0}>
                        <div style={{flexDirection: "row", display: "flex"}}>
                            <div style={{flexDirection: "column", flex: 2}}>
                                <MDTypography style={{flex: 2}} variant="h6" textTransform="capitalize">
                                    {title}
                                </MDTypography>
                                <MDTypography component="div" variant="button" color="text" fontWeight="light">
                                    {description}
                                </MDTypography>
                            </div>

                            {RenderCustomerSelect()}

                            <MDBox>
                                {customers}
                            </MDBox>


                        </div>
                    </MDBox>
                </MDBox>
            ) : null}
            {useMemo(
                () => (
                    // <MDBox height={height}>
                    <MDBox sx={{width: "100%", overflowX: "auto"}}>
                        <MDBox
                            height="70vh"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {/*<Typography>*/}
                            {/*    In Wartung*/}
                            {/*</Typography>*/}
                            <Line
                                data={data}
                                options={options}
                            />
                        </MDBox>
                    </MDBox>

                ),
                [chart, height]
            )}
        </MDBox>
    );

    return title || description
        ? <Card>
            {renderChart}
        </Card>
        : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
    icon: {color: "info", component: ""},
    title: "",
    description: "",
    height: "19.125rem",
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
    icon: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "light",
            "dark",
        ]),
        component: PropTypes.node,
    }),
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default DefaultLineChart;
