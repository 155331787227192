import {toast} from "react-toastify";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";

export default function dowloadDeliveryNote(order) {
    if(order.fk_order_status.id !== 1) {
        if(order.delivery_slip) {
            // GET FILE DATA CODE
            getAxiosInstance(store).get(order.delivery_slip, {responseType: "blob"})
                .then((response) => {
                    const blob = new Blob([response.data], {type: "application/pdf"});

                    const fileURL = window.URL.createObjectURL(blob);
                    // Setting various property values
                    const alink = document.createElement('a');
                    const filename = `Foodhi Bestellung #${order.id} - Lieferschein`
                    alink.href = fileURL;
                    alink.download = filename;
                    alink.click();
                })
                .catch((error) => {
                    toast.error("Es gab ein Problem beim Herunterladen des Lieferscheins.");
                    console.log("error response", error)
                    // const blob = new Blob([error], {type: "text/plain"});
                    // const reader = new FileReader();
                    // // This fires after the blob has been read/loaded.
                    // reader.onload = function() {
                    //     toast.error(reader.result);
                    // }
                    // // Start reading the blob as text.
                    // reader.readAsText(blob);
                })
        } else {
            toast.error("Keinen Lieferschein für diese Bestellung gefunden.");
        }
    } else {
        toast.info("Die Bestellung muss noch bestätigt und als \"im Versand\" markiert werden, bevor du den Lieferschein sehen kannst. ",{position: toast.POSITION.TOP_CENTER});
    }




}