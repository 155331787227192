/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ToggleButton, ToggleButtonGroup, Grid} from '@mui/material';

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../../reduxStore/store";
import {useNavigate} from "react-router-dom";
import YearlyLineChart from "./Component/YearlyLineChart";
import PieChartFoodhi from "./Component/PieChartFoodhi";
import MostSoldBarChart from "./Component/MostSoldBarChart";
import {Spinner} from "react-bootstrap";
import Divider from "@mui/material/Divider";
import {fetchCustomers} from "../../reduxStore/actions/actionCustomers";

function Report() {

    const farmerId = useSelector(state => state.userProfile?.id);
    const [timeframe, setTimeframe] = useState('this_month')
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [totalOrders, setTotalOrders] = useState({
        totalSales: 0,
        ordersCount: 0
    });
    const [orders, setOrders] = useState([]);

    const linkedCustomers = useSelector(state => state.customers.linkedCustomers);

    // IF NO TOKEN PRESENT, SIGN OUT
    useEffect(() => {
        if (!farmerId) {
            console.log("Logged out!")
            navigate('/authentication/signin');
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        getOrdersData();
        dispatch(fetchCustomers(farmerId))
    }, [timeframe]);

    // GET ALL ORDERS DATA
    const getOrdersData = () => {
        const timeWindow = () => {
            const dateNow = new Date();
            const year = dateNow.getFullYear();
            const month = dateNow.getMonth() + 1;

            // CALCULATE LAST MONTH
            const lastMonth = new Date();
            lastMonth.setMonth(lastMonth.getMonth() - 1);

            // CALCULATE LAST WEEK
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)
            const foramttedSeventDaysAgo = sevenDaysAgo.toISOString().split("T")[0];

            // DYNAMICALLY CHANGE THE FILTERS FOR THE REQUEST, BASED ON THE TIMEFRAME
            switch (timeframe) {
                case "year":
                    return `filter[year(date_created)][_eq]=${year}`
                case "last_month":
                    return `filter[year(date_created)][_eq]=${year}&filter[month(date_created)][_eq]=${lastMonth.getMonth() + 1}`
                case "this_month":
                    return `filter[year(date_created)][_eq]=${year}&filter[month(date_created)][_eq]=${month}`
                case "last_seven_days":
                    return `filter[year(date_created)][_eq]=${year}&filter[date_created][_gte]=${foramttedSeventDaysAgo}`
            }
        }

        getAxiosInstance(store).get(`/items/orders?filter=${timeWindow()}`, {
            params: {
                fields: "total, " +
                    "fk_order_status, " +
                    "fk_order_items.name, " +
                    "fk_order_items.updated_subtotal, " +
                    "fk_order_items.quantity",
                limit: 1000
            },
            filter: {
                "farmer": {
                    "_eq": farmerId
                },
                "fk_order_status": {
                    "name": {
                        "_eq": "completed"
                    }
                }
            }
        })
            .then(response => {
                const {data} = response.data;

                console.log(data, "DATA")
                setOrders(data)
                setLoading(false)
                // PREPARE DATA FOR YEARLY LINE CHART

                if (data.length > 0) {
                    let sumAllOrderTotals = 0;
                    data.forEach((order, index) => {
                        sumAllOrderTotals = sumAllOrderTotals + order.total;
                        if (index === data.length - 1) {
                            setTotalOrders({
                                totalSales: sumAllOrderTotals,
                                ordersCount: data.length
                            })
                        }
                    })
                } else {
                    setTotalOrders({
                        totalSales: 0,
                        ordersCount: 0
                    })
                }
            })
            .catch(error => {
                console.log(error, "FAILED GETTING ORDERS WITH TIMEFRAME");
                setLoading(false)
            })
    };

    // FORMAT MODULE NAMES/TITLES
    function dynamicChartTitle() {
        switch (timeframe) {
            case "year":
                return "Jahr";
            case "last_month":
                return "letzten Monat";
            case "this_month":
                return "diesen Monat";
            case "last_seven_days":
                return "letzte 7 tage";
        }
    }

    const renderTimeframeSelection = () => (
        <Grid container spacing={3} mb={4} ms={3}>
            <Grid item xs={12} md={8} lg={6}>
                <MDBox mb={1.5}>
                    <ToggleButtonGroup
                        color="secondary"
                        value={timeframe}
                        exclusive
                        onChange={(e) => setTimeframe(e.target.value)}
                    >
                        <ToggleButton value="year">Jahr</ToggleButton>
                        <ToggleButton value="last_month">Letzten Monat</ToggleButton>
                        <ToggleButton value="this_month">Diesen Monat</ToggleButton>
                        <ToggleButton value="last_seven_days">Letzte 7 Tage</ToggleButton>
                    </ToggleButtonGroup>
                </MDBox>
            </Grid>
        </Grid>
    )

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            {loading ? (
                <MDBox sx={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Spinner/>
                </MDBox>
            ) : (
                <MDBox py={3}>



                    {/*SELECT THE PREIOD OF TIME YOU WNAT TO SHOW RESULTS IN*/}
                    {renderTimeframeSelection()}
                    {/* SMALL STATS CARDS */}
                    <Grid container spacing={3}>
                        {/*TOTAL SALES*/}
                        <Grid item xs={12} md={4} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    icon="attach_money"
                                    title={"Umsatz"}
                                    loading={false}
                                    count={`€ ${totalOrders.totalSales.toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}`}
                                    percentage={{label: dynamicChartTitle()}}
                                />
                            </MDBox>
                        </Grid>
                        {/*NUMBER OF ORDERS*/}
                        <Grid item xs={12} md={4} lg={4}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="success"
                                    loading={false}
                                    icon="shopping_bag"
                                    title="Bestellungen"
                                    count={totalOrders.ordersCount}
                                    percentage={{label: dynamicChartTitle()}}

                                />
                            </MDBox>
                        </Grid>
                        {/*NUMBER OF CUSTOMERS*/}
                        <Grid item xs={12} md={4} lg={4}>
                            <MDBox mb={1.5}>
                                {linkedCustomers && (
                                    <ComplexStatisticsCard
                                        color="success"
                                        loading={false}
                                        icon="restaurant"
                                        title="Kunden"
                                        count={linkedCustomers.length > 0 ? linkedCustomers.length : 0}
                                        percentage={{
                                            label: "Aktive Kunden",
                                        }}
                                    />
                                )}
                            </MDBox>
                        </Grid>
                    </Grid>

                    <Divider />

                    {/* LINE CHART WITH SALES PER PERIOD */}
                    <MDBox mt={4.5}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <MDBox mb={3}>
                                    <YearlyLineChart/>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>

                    <Divider />

                    {/*ORDER STATUS AND BEST SELLERS */}
                    <MDBox mt={4.5} sx={12}>
                        {/*ORDER STATUS*/}
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={3}>
                                    <PieChartFoodhi
                                        orders={orders}
                                        loading={false}
                                    />
                                </MDBox>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <MDBox mb={3}>
                                    <MostSoldBarChart orders={orders} loading={false}/>
                                </MDBox>
                            </Grid>
                        </Grid>

                    </MDBox>

                </MDBox>
            )}


            <Footer/>
        </DashboardLayout>
    );
}

export default Report;
