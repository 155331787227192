import React, {useCallback, useEffect, useState} from 'react';
import MDBox from "../../../../../components/MDBox";
import {
    Box, Button, Container, Divider,
    FormControl,
    Grid,
    InputAdornment,
    Paper,
    TableContainer
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {fetchProducts} from "../../../../../reduxStore/actions/actionProducts";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import 'react-calendar/dist/Calendar.css';
import {TailSpin} from "react-loader-spinner";
import MDInput from "../../../../../components/MDInput";
import dayjs from "dayjs";
import {Euro} from "@mui/icons-material";
import {THUMBNAIL_SETTINGS} from "../../../../../variables";
import MDTypography from "../../../../../components/MDTypography";
import {createOrder} from "../../../../../reduxStore/actions/actionEditCart";
import AddRemoveProductsToCart from "./AddRemoveProductsToCart";
import ClientSelector from "./ClientSelector";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import {loadingStart, loadingStop} from "../../../../../reduxStore/actions/actionUI";
import {toast} from "react-toastify";
import {fetchOrders} from "../../../../../reduxStore/actions/actionOrders";
import colors from "../../../../../assets/theme/base/colors";
import sendEmail from "../../../../../emails/emailApi";

const CreateNewOrder = ({setModalVisibleCallback}) => {

    const dispatch = useDispatch();

    const products = useSelector(state => state.products);
    const userId = useSelector(state => state.auth.id);
    const farmerId = useSelector(state => state.userProfile.id);
    const farmer = useSelector(state => state.userProfile);
    const productsLoading = useSelector(state => state.products.loading);
    const loading = useSelector(state => state.UI.loading);

    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [deliveryFee, setDeliveryFee] = useState(farmer.delivery_fee);
    const [deliveryNote, setDeliveryNote] = useState('');
    const [calendarDate, setCalendarDate] = useState(dayjs().format("YYYY-MM-DD"));
    const [customCustomer, setCustomCustomer] = useState('');

    useEffect(() => {
        dispatch(fetchProducts(userId))
    }, [])

    // CART PROPERTIES STATUS
    const cartProducts = useSelector(state => state.shop.cartItems);
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    // Calculate PRICE of total sum of products in cart
    const cartTotalSum = cartProducts.map(item => item.price * item.quantity);
    const totalAmountPrice = cartTotalSum.reduce(reducer, 0);

    //Calculate AMOUNT of total sum of products in cart
    // const cartTotalQuantity = cartProducts.map(item => item.quantity);
    // const totalAmountQuantity = cartTotalQuantity.reduce(reducer, 0);

    const calculatePrice = (row) => {
        const reduxPrice = cartProducts.find(state => state.item.id === row.id)?.price;

        if (reduxPrice) {
            return reduxPrice;
        } else {
            return row.price;
        }
    }

    const renderProductsList = useCallback(() => {
        if (productsLoading) {
            return (
                <MDBox
                    height={300}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <TailSpin/>
                </MDBox>)
        } else {
            return (
                <TableContainer component={Paper} sx={{maxHeight: 300}}>
                    <Table aria-label="simple table" style={{minWidth: 780}}>
                        <TableBody>
                            {products.productsList.length && products.productsList.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell width="10%" component="th" scope="row" align="center">
                                        <img
                                            src={`${process.env.REACT_APP_SITE_URL}/assets/${row.images[0]}?${THUMBNAIL_SETTINGS}`}
                                            alt={row.name}
                                            style={{height: 30, width: 30, borderRadius: "50%"}}/>
                                    </TableCell>
                                    <TableCell width="15%" component="th" scope="row" align="left">
                                        <Box sx={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            maxWidth: 100
                                        }}>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                            >
                                                {row.name}
                                            </MDTypography>
                                        </Box>

                                    </TableCell>
                                    <TableCell width="20%" component="th" align="left">
                                        <MDTypography variant="button" fontWeight="regular" color="text">

                                            € {calculatePrice(row).toLocaleString("de-DE", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}/{row.fk_unit_measurement.name}

                                            {/*    € {row.price.toLocaleString("de-DE", {*/}
                                            {/*    minimumFractionDigits: 2,*/}
                                            {/*    maximumFractionDigits: 2*/}
                                            {/*})}*/}
                                        </MDTypography>
                                    </TableCell>

                                    <TableCell width="65%" component="th" align="left">
                                        {/*<MDBox sx={{width: 500}}>*/}
                                        <AddRemoveProductsToCart product={row}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
    }, [products])

    const renderDeliveryPrice = () => {
        return (
            <MDBox>
                <FormControl sx={{minWidth: 300}}>
                    <MDInput
                        type="number"
                        lang="de-DE"
                        label="Liefergebühr"
                        placeholder="EUR"
                        value={deliveryFee}
                        variant="outlined"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <Euro/>
                            </InputAdornment>,
                        }}
                        onChange={(e) => setDeliveryFee(e.target.value)}
                    />
                </FormControl>
            </MDBox>
        )
    }
    const selectedCustomerCallback = (customer) => {
        setSelectedCustomer(customer)
    }
    const customCustomerCallback = (data) => {
        setCustomCustomer(data)
    }

    const confirmSubmit = (e) => {
        e.preventDefault();

        if (!cartProducts.length) {
            toast.error("Bitte lege Produkte in den Warenkorb, um die Bestellung zu erstellen.")
        } else if (selectedCustomer === '' || !selectedCustomer) {
            toast.error('Bitte wähle einen Kunden für die Bestellung aus.')
        } else {
            dispatch(loadingStart());

            const orderData = {
                delivery_date: calendarDate,
                total: (totalAmountPrice + Number(deliveryFee)).toFixed(3),
                delivery_fee: deliveryFee,
                delivery_note: deliveryNote,
                immediate_order: false,
                // IF NO RESTAURANT IS SELECTED, SET IT TO NULL
                ...selectedCustomer.id
                    ? {restaurant: {id: selectedCustomer.id}}
                    : {restaurant: null},
                farmer: {
                    id: farmerId,
                },
                fk_order_items: cartProducts.map((product) => {
                    const subtotal = (product.item.price - (product.item.price * (product.discount / 100)));
                    const item_total = (product.item.price - (product.item.price * (product.discount / 100))) * product.quantity;

                    return (
                        {
                            product_id: product.item.id,
                            stock_quantity: product.item.stock_quantity,
                            name: product.item.name,
                            unit_amount: product.item.amount,
                            measurement: product.item.fk_unit_measurement?.name,
                            images: product.item.images,
                            quantity: product.quantity,
                            price: product.item.price,
                            discount: (product.item.price * (product.discount / 100)),
                            subtotal,
                            item_total,
                            product_amount: product.item.amount * product.quantity,
                            updated_product_amount: product.item.amount * product.quantity,
                            updated_subtotal: subtotal,
                            tax_rate: product.item.tax
                                ? product.item.tax.tax_rate
                                : farmer.fk_global_tax
                                    ? farmer.fk_global_tax.tax_rate
                                    : 0,
                            is_refunded: false
                        }
                    )
                }),
                fk_order_status: {
                    id: 1
                },
                // IF NO RESTAURANT IS SELECTED, SET CUSTOM ADDRESS, ELSE USE ADDRESS OF RESTAURANT
                fk_shipping_address: {
                    ...selectedCustomer.fk_shipping_address
                        ? {id: selectedCustomer.fk_shipping_address.id}
                        : customCustomer
                },
                // IF NO RESTAURANT IS SELECTED, SET CUSTOM ADDRESS
                fk_billing_address: {
                    ...selectedCustomer.fk_shipping_address
                        ? {id: selectedCustomer.fk_shipping_address.id}
                        : customCustomer
                }
            }


            getAxiosInstance(store).post(`/items/orders?fields=*.*.*`, orderData)
                .then((response) => {
                    const orderDataResponse = response.data.data;
                    const {
                        fk_order_items,
                        farmer,
                        restaurant,
                        id,
                        delivery_date
                    } = orderDataResponse;

                    // SEND TRANSACTIONAL EMAIL TO FARMER
                    sendEmail({
                        templateId: "FARMER_Manual_Order_Created",
                        data: {
                            total: (totalAmountPrice + Number(deliveryFee)),
                            restaurant: restaurant || {
                                contact_first_name: customCustomer.first_name,
                                restaurant_name: customCustomer.company
                            },
                            items: fk_order_items,
                            farmer,
                            orderNum: id,
                            delivery_fee: farmer.delivery_fee,
                            deliveryDate: dayjs(delivery_date).format("DD.MM.YYYY"),
                        },
                        recipient: farmer.contact_email,
                    });

                    // LOWER STOCK AND EMPTY CART
                    dispatch(createOrder(orderDataResponse));

                    setModalVisibleCallback(false);

                    dispatch(fetchOrders(true, farmerId));

                    toast.success(`Bestellung für ${customCustomer.company} erfolgreich erstellt`);

                    dispatch(loadingStop());

                })
                .catch(error => {
                    console.log(error, "ERROR CREATING ORDER");
                    // Sentry.Native.captureException(error);
                    dispatch(loadingStop())
                })
        }
    }

    return (
        <>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{}}
            >
                <Container
                    container
                    display="flex"
                    gap={2}
                >

                    {/*CHOOSE PRODUCTS*/}
                    <Grid item mb={4} backgroundColor="#fff" borderRadius={2} xs={12}>
                        <MDTypography variant="h5" padding={2}>
                            Produkte
                        </MDTypography>

                        <Divider/>

                        {renderProductsList()}
                    </Grid>

                    <form onSubmit={confirmSubmit}>
                        {/*CHOOSE DATE*/}
                        <Grid item alignSelf="flex-start" backgroundColor="#fff" borderRadius={2} padding={2}
                              boxShadow={3} marginBottom={4} xs={12}>
                            <MDTypography variant="h5" mb={2}>
                                Liefertermin
                            </MDTypography>

                            <Divider/>

                            <FormControl sx={{minWidth: 300}}>
                                <MDInput
                                    type="date"
                                    label="Liefertermin"
                                    required
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setCalendarDate(e.target.value)
                                    }}
                                    value={calendarDate}
                                />
                            </FormControl>
                        </Grid>


                        {/*CHOOSE CLIENT*/}
                        <Grid item alignSelf="flex-start" backgroundColor="#fff" borderRadius={2} padding={2}
                              boxShadow={3} marginBottom={4}>
                            <MDTypography variant="h5" mb={2}>
                                Foodhi Kunden
                            </MDTypography>

                            <Divider/>

                            <ClientSelector
                                selectedCustomerCallback={selectedCustomerCallback}
                                customCustomerCallback={customCustomerCallback}
                            />
                        </Grid>

                        {/*CHOOSE LIEFERGEBUHR*/}
                        <Grid item alignSelf="flex-start" backgroundColor="#fff" borderRadius={2} padding={2}
                              boxShadow={3} marginBottom={4}>
                            <MDTypography variant="h5" mb={2}>
                                Liefergebühr
                            </MDTypography>

                            <Divider/>

                            {renderDeliveryPrice()}
                        </Grid>

                        {/*DELIVERY NOTE*/}
                        <Grid item alignSelf="flex-start" backgroundColor="#fff" borderRadius={2} padding={2}
                              boxShadow={3} marginBottom={4}>
                            <MDTypography variant="h5" mb={1}>
                                Notiz
                            </MDTypography>

                            <Divider/>

                            <FormControl sx={{minWidth: 300}}>
                                <MDInput
                                    type="text"
                                    label="Notiz"
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => {
                                        setDeliveryNote(e.target.value)
                                    }}
                                    value={deliveryNote}
                                />
                            </FormControl>
                        </Grid>

                        {/*SUBMIT*/}
                        <Grid item mt={3} alignSelf="flex-start">
                            <Button
                                type="submit"
                                disabled={loading}
                                style={{
                                    border: "none",
                                    padding: "7px 25px",
                                    minWidth: 150,
                                    textAlign: "center",
                                    fontFamily: "Roboto",
                                    fontWeight: 500,
                                    backgroundColor: !loading ? colors.foodhiOrange.main : "lightgray",
                                    borderRadius: 6,
                                    color: "#fff",
                                    fontSize: 16,
                                    marginBottom: 20,
                                    textTransform: "uppercase",
                                }}
                            >
                                {!loading ? "ERSTELLEN" :
                                    <TailSpin height={25} width={25} color={colors.foodhiOrange.main}/>}
                            </Button>

                        </Grid>
                    </form>

                </Container>
            </Box>
        </>
    );
};

export default CreateNewOrder;
