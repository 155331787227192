/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {toast} from "react-toastify";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import {TailSpin} from "react-loader-spinner";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {ROLE_RESTAURANT} from "../../../variables";
import {addExistingCustomer} from "../../../reduxStore/actions/actionCustomers";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

// Data
// import authorsTableData from "layouts/tables/data/authorsTableData";
// import projectsTableData from "layouts/tables/data/projectsTableData";

const CreateCustomerInvitation = ({dismissModal}) => {
    const [email, setEmail] = useState("");
    const [disable, setDisable] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);


    const farmerId = useSelector(state => state.userProfile.id);
    const farmerName = useSelector(state => state.userProfile.store_name);
    const linkedCustomers = useSelector(state => state.customers.linkedCustomers);

    const dispatch = useDispatch();

    const handleExistingCustomer = () => {
        console.log("HANDLE EXISTING CUSTOMER")
        // CHECK IF CUSTOMER IS ALREADY CONNECTED TO YOU
        const alreadyConnected = linkedCustomers.some(customer => customer.restaurant_profile_id.contact_email === email);
        if (!alreadyConnected) {
            // 1 FIND ID OF EMAIL
            getAxiosInstance(store).get(`items/restaurant_profile`, {
                params: {
                    fields: "id, restaurant_name, contact_first_name, contact_email",
                    "filter[contact_email][_eq]": email
                },
            })
                .then(response => {
                    console.log(response.data.data)
                    if (response.data.data.length > 0) {
                        const restaurantId = response.data.data[0].id;
                        const restaurantDetails = {
                            restaurant_name: response.data.data[0].restaurant_name,
                            contact_first_name: response.data.data[0].contact_first_name,
                            contact_email: response.data.data[0].contact_email
                        };
                        dispatch(addExistingCustomer(restaurantId, restaurantDetails, farmerId, farmerName, linkedCustomers, email))
                        dismissModal()
                    } else {
                        setDialogOpen(true)
                    }
                })
                .catch(error => {
                    toast.error("Fehler: bitte kontaktiere uns an tech@foodhi.de.")
                    console.log(error, "ERROR HANDLING EXISTING CUSTOMER")
                })
        } else {
            toast.info("Kunden bereits verbunden.")
        }
    }

    const handleNewCustomer = () => {
        console.log("HANDLE NEW CUSTOMER");
        const inviteDetails = {
            email,
            role: ROLE_RESTAURANT,
            invite_url: `${process.env.REACT_APP_SITE_URL_FRONTEND}/invite_new_user?farmer_id=${farmerId}&restaurant_email=${email}`,
        }
        getAxiosInstance(store).post(`/users/invite`, inviteDetails)
            .then(() => {
                // ADD EMAIL TO THE PENIDNG LIST OF THE FARMER
                getAxiosInstance(store).get(`/items/farmer_profile/${farmerId}?fields=pending_invites`)
                    .then(response => {
                        const existingInvites = response.data.data.pending_invites;
                        getAxiosInstance(store).patch(`/items/farmer_profile/${farmerId}`, {
                            ...existingInvites
                                ? {pending_invites: [...existingInvites, email]}
                                : {pending_invites: [email]}
                        })
                            .then((response) => {
                                dismissModal()
                                console.log(response.data.data)
                                toast.success(`Die Einladung wurde an ${email} versendet.`)
                            })
                            .catch(() => {
                                dismissModal()
                                toast.error(`Es ist ein Fehler beim Senden der Einladung aufgetreten.`)
                            })
                    })
            })
            .catch((error) => {
                if (error.response.data.errors[0].message === "Field \"email\" has to be unique.") {
                    toast.error(`Diese E-Mail ist bereits eingeladen worden.`)
                } else {
                    toast.error(`Es ist ein Fehler beim Senden der Einladung aufgetreten.`)
                }
            })
    }

    const checkIfUserExists = (e) => {
        e.preventDefault();
        console.log("CHECK USER")
        setDisable(true);

        getAxiosInstance(store).get(`${process.env.REACT_APP_SITE_URL}/api/invite-user?email=${email}`)
            .then(response => {
                console.log(response.data)
                if (!response.data) {
                    handleNewCustomer()
                    setDisable(false);
                } else {
                    handleExistingCustomer();
                    setDisable(false);
                }
            })
            .catch(error => console.log(error.response))
    }

    return (
        <>
            <form onSubmit={checkIfUserExists}>

                <MDBox px={3} mt={2} pb={2}>
                    <p style={{fontSize: 14, fontWeight: "400"}}>
                        Gebe die E-Mail-Adresse deines/deiner Kund*in ein, um eine Einladung zu deinem Shop auf Foodhi zu verschicken.
                    </p>
                </MDBox>
                {/*<MDBox px={3} pb={2}>*/}
                {/*    <p style={{fontSize: 14, fontWeight: "400"}}>*/}
                {/*        Dein*e Kund*in kann deinen Shop in der App sehen, nachdem er/sie ein Benutzerkonto erstellt hat.*/}
                {/*    </p>*/}
                {/*</MDBox>*/}

                <MDBox pt={0} pb={2} px={3}>
                    <MDInput
                        type="email"
                        label="E-Mail"
                        fullWidth
                        required
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        value={email}
                        id="email"
                        placeholder="E-Mail"
                    />
                </MDBox>


                {/* <button type="submit" className="btn btn-primary">Create</button> */}
                <MDBox pt={2} pb={3} px={3}>
                    <MDButton
                        type="submit"
                        variant="gradient"
                        color="foodhiOrange"
                        disabled={disable}
                    >
                        {!disable ? "Einladen" : <TailSpin height={20} color="#fff"/>}
                    </MDButton>
                </MDBox>
            </form>

            {/* Delete confirmation Dialog box */}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Kunde bereits eingeladen
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Die E-mail "{email}" wurde schon eingeladen. Möchtest du eine E-Mail erneut verschicken?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <MDButton variant="gradient" onClick={() => setDialogOpen(false)}
                              color="light" style={{color: "gray"}}>Abbrechen</MDButton>
                    <MDButton variant="gradient" onClick={handleNewCustomer}
                              color="info">
                        Erneut einladen
                    </MDButton>
                </DialogActions>
            </Dialog>
        </>

    );
}

export default CreateCustomerInvitation;
