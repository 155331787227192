/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Product from "layouts/product";
import Icon from "@mui/material/Icon";
import Report from "layouts/report";
import Orders from "./layouts/orders/order-list";
import LogIn from "./layouts/authentication/login";
import CustomerList from "layouts/customers";
import ProfileIndex from "./layouts/profile/profileIndex";
import NewUserRegistration from "./layouts/authentication/registration/NewUserRegistration";
import AppHerunterladen from "./layouts/authentication/registration/AppHerunterladen";
import PasswordReset from "./layouts/authentication/reset-password/ResetPassword";
import Datenschutzerklaerung from "./layouts/datenschutzerklaerung/datenschutzerklaerung";
import NewFarmerInvitation from "./layouts/invitations/NewFarmerInvitation";

// @mui icons

const routes = [
  {
    type: "collapse",
    route: "dashboard",
    icon: <Icon fontSize="small">home</Icon>,
    name: "Dashboard",
    component: <Dashboard />,
    key: "dashboard",
  },
  {
    type: "collapse",
    name: "Produkte",
    key: "produkte",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/produkte",
    component: <Product />,
  },
  {
    type: "collapse",
    name: "Kunden",
    key: "kunden",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/kunden",
    component: <CustomerList />,
  },
  {
    type: "collapse",
    name: "Bestellungen",
    key: "bestellungen",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/bestellungen",
    component: <Orders />,
  },
  {
    type: "collapse",
    name: "Statistiken",
    key: "statistiken",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/statistiken",
    component: <Report />
  },
  {
    type: "collapse",
    name: "Einstellungen",
    key: "einstellungen",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/einstellungen",
    component: <ProfileIndex />,
  },
  {
    type: "route",
    route: "authentication/sign-in",
    icon: <Icon fontSize="small">profile</Icon>,
    name: "Anmelden",
    component: <LogIn />,
    key: "signIn",
  },
  {
    type: "route",
    route: "/invite-farmer",
    name: "Farmer Einladung",
    key: "neuesBenutzerkonto",
    icon: <Icon fontSize="small">farm</Icon>,
    component: <NewFarmerInvitation />,
  },
  {
    type: "route",
    route: "/invite_new_user",
    name: "Neues Benutzerkonto",
    key: "neuesBenutzerkonto",
    icon: <Icon fontSize="small">add</Icon>,
    component: <NewUserRegistration />,
  },
  {
    type: "route",
    route: "/download-app",
    name: "App herunterladen",
    key: "appHerunterladen",
    icon: <Icon fontSize="small">phone</Icon>,
    component: <AppHerunterladen />,
  },
  {
    type: "route",
    route: "/password-reset",
    name: "Passwort zurücksetzen",
    key: "password-zuruecksetzen",
    icon: <Icon fontSize="small">password</Icon>,
    component: <PasswordReset />,
  },
  {
    type: "route",
    route: "/datenschutzerklaerung",
    name: "Datenschutzerklärung",
    key: "datenschutzerklaerung",
    // icon: <Icon fontSize="small">password</Icon>,
    component: <Datenschutzerklaerung />,
  }
];

export default routes;
