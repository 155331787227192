/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
// import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TailSpin} from "react-loader-spinner";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import {Icon, IconButton, Switch} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {ShoppingBagOutlined} from "@mui/icons-material";


import colors from "../../../assets/theme/base/colors";
import {
    openProductDeleteDialog,
    openProductEditDialog,
} from "../../../reduxStore/actions/actionUI";
import {fetchProducts} from "../../../reduxStore/actions/actionProducts";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {THUMBNAIL_SETTINGS} from "../../../variables";

export default function productDataTable() {

    const products = useSelector(state => state.products.productsList)
    const dispatch = useDispatch();

    const Company = ({image, name}) => {
        const thumbnail = image && image.length && `${process.env.REACT_APP_SITE_URL}/assets/${image[0]}?${THUMBNAIL_SETTINGS}`

        return (
            <MDBox display="flex" alignItems="center" lineHeight={1}>
                {thumbnail
                    ? <div style={{minWidth: "2.5rem"}}><MDAvatar src={thumbnail} size="sm"/></div>
                    : <ShoppingBagOutlined style={{height: "1.5rem", minWidth: "2.5rem", padding: 2}}/>
                }
                <MDTypography variant="button" fontWeight="medium" ml={2} lineHeight={1}>
                    {name}
                </MDTypography>
            </MDBox>
        )
    };

    const ProductActions = (product) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <IconButton onClick={() => dispatch(openProductEditDialog(product))}>
                <Icon
                    sx={{
                        fontWeight: "bold",
                        fontSize: "18px !important",
                        border: "1px solid gray",
                        color: "black",
                        height: 35,
                        width: 35,
                        lineHeight: "35px",
                        alignItems: "center",
                        borderRadius: "50%",
                        marginRight: 2
                    }}
                >
                    edit
                </Icon>
            </IconButton>
            <IconButton onClick={() => dispatch(openProductDeleteDialog(product))}>
                <Icon
                    sx={{
                        fontSize: "18px !important",
                        border: "1px solid gray",
                        color: "black",
                        height: 35,
                        width: 35,
                        lineHeight: "35px",
                        alignItems: "center",
                        borderRadius: "50%",
                    }}
                >
                    delete
                </Icon>
            </IconButton>
        </MDBox>
    );

    const ProductStatus = ({product}) => {

        const [toggleButtonLoading, setToggleButtonLoading] = useState({loading: false, id: null});
        const [toggleStatus, setToggleStatus] = useState(false)

        useEffect(() => {
            if (product.status === "published") {
                setToggleStatus(true)
            } else (setToggleStatus(false))
        }, [])

        function toggleProductStatus() {

            setToggleButtonLoading({
                loading: true,
                id: product.id
            });

            const productStatus = {
                "status": toggleStatus ? "draft" : "published"
            }

            getAxiosInstance(store).patch(`/items/products/${product.id}`, productStatus)
                .then((response) => {
                    if (response.data.data.status === "published") {
                        setToggleButtonLoading({
                            loading: false,
                            id: product.id
                        });
                        setToggleStatus(true)
                    }
                    if (response.data.data.status === "draft") {
                        setToggleButtonLoading({
                            loading: false,
                            id: product.id
                        });
                        setToggleStatus(false)
                    }
                    // dispatch(fetchProducts());
                })
                .catch(error => {
                    console.log(error);
                    setToggleButtonLoading({
                        loading: false,
                        id: product.id
                    });
                })
        }

        if (!toggleButtonLoading.loading) {
            return (
                <FormControl fullWidth>
                    <Switch
                        key={product.id}
                        color="warning"
                        checked={toggleStatus}
                        onClick={() => toggleProductStatus()}
                    />
                </FormControl>
            )
        }
        return <TailSpin color={colors.foodhiOrange.main} height={40}/>
    }

    const DateAvailableFrom = ({product}) => {
        const dateAvailableFrom = product.product_available_from;

        if (dateAvailableFrom) {
            // FORMAT THE DATE TO DISPLAY IN GERMAN STANDARD
            const day = dateAvailableFrom.substring(8, 10);
            const month = dateAvailableFrom.substring(5, 7);
            const year = dateAvailableFrom.substring(0, 4);

            // GET TODAYs DATE AND FORMAT IT TO GERMAN STANDARD
            const date = new Date();
            const zeroPad = (num, places) => String(num).padStart(places, '0')
            const today = `${date.getFullYear()}-${zeroPad(date.getMonth() + 1, 2)}-${zeroPad(date.getDate(), 2)}`

            return (
                // IF DATE IS IN THE FUTURE, FORMAT COLOR RED, IF PRODUCT ALREADY AVAILABLE, FORMAT GREEN
                <span style={dateAvailableFrom > today ? {color: "red"} : {color: "green"}}>
                {`${day}.${month}.${year}`}
            </span>
            );
        }
        return "Not set"
    }

    return {
        columns: [
            {Header: "produkte", accessor: "produkte", width: "15%", align: "left"},
            {Header: "verfügbar ab", accessor: "dateAvailable", width: "18%", align: "left"},
            {Header: "auf lager", accessor: "stock", width: "10%", align: "left"},
            {Header: "preis", accessor: "preis", align: "center"},
            {Header: "veröffentlicht", accessor: "status", align: "center"},
            {Header: "aktion", accessor: "aktion", align: "center"},
        ],

        rows: products.filter(products => products.status !== "archived").map(product => (
            {
                key: product.id,
                produkte: <Company image={product.images} name={product.name}/>,
                dateAvailable: <DateAvailableFrom product={product}/>,
                stock: product.stock_quantity,
                preis: `€ ${product.price.toLocaleString("de-DE", {minimumFractionDigits: 2})}`,
                status: <ProductStatus key={product.id} product={product}/>,
                aktion: <ProductActions product={product}/>
            }
        ))
    };
}
