import {Pagination} from "@mui/material";
import MDBox from "../components/MDBox";

const FoodhiPagination = ({totalPages, page, setPage}) => {

    return (
        <MDBox px={3} py={3} display="flex" justifyContent="flex-end">
            <Pagination
                count={totalPages}
                color="foodhiOrange"
                variant="outlined"
                page={page}
                onChange={(e, value) => {setPage(value)}}
            />
        </MDBox>

    )
}

export default FoodhiPagination;