/* eslint-disable no-console */

import axios from 'axios';
import {authLogout, generateNewTokens} from "../reduxStore/actions/actionAuth";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";

export const getAxiosInstance = (store) => {

    const {token} = store.getState().auth;
    const {refresh_token} = store.getState().auth;

    const baseURL = process.env.REACT_APP_SITE_URL;
    const axiosInstance = axios.create({
        baseURL,
        headers: {Authorization: `Bearer ${token}`}
    })

    axiosInstance.interceptors.request.use(
        async config => {

            if (!token) {
                const {token} = store.getState().auth;
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            // CHECK IF TOKEN IS EXPIRED
            const isExpired = dayjs.unix(jwt_decode(token).exp).diff(dayjs()) < 1;


            // IF TOKEN VALID
            if (!isExpired) {
                console.log("Token is valid")
                return config
            } else {
                // IF TOKEN EXPIRED
                console.log("Token is expired, refreshig token")
                try {
                    const data = {
                        refresh_token,
                        mode: "json"
                    }
                    const response = await axios.post(`${process.env.REACT_APP_SITE_URL}/auth/refresh`, data);
                    const newTokens = response.data.data;
                    console.log(newTokens, "NEW TOKENS")
                    config.headers.Authorization = `Bearer ${newTokens.access_token}`
                    store.dispatch(generateNewTokens(newTokens));
                    return config;
                } catch (error) {
                    // console.log(error.response, "ERROR TOKENS");
                    console.log(error, "ERROR TOKENS");
                    if (error.message === "Network Error") {
                        console.log("ERROR DE RED")
                        alert("Es gibt ein Fehler mit der Verbindung.")
                        return config;
                    } else if (error.response.status === 401) {
                        store.dispatch(authLogout())
                        alert("Abgemeldet. Deine Sitzung ist abgelaufen")
                        return config;
                    }
                }
            }
        },
        async error => {
            console.log(error, "ERROR")
        }
    );

    return axiosInstance;
}

