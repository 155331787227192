import {useEffect, useState} from "react";
import {AddCircle, ArrowDropDown, Euro, HighlightOffOutlined, Percent} from "@mui/icons-material";
import {Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select} from "@mui/material";
import MDInput from "../../../components/MDInput";
import {toast} from "react-toastify";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";


function ProductAmountOptionsField({bundlePackages, isLoading, initialAmountState, productId, productPrice}) {

    // SET THE STATE OF THE BUNDLE PACKAGE
    const [amount, setAmount] = useState([]);
    const [discountType, setDiscountType] = useState(0)


    // SET AMOUNT WHEN PRODUCT FINISHED LOADING SO IT CAN RE-RENDER
    useEffect(() => {
        if (initialAmountState) {
            setAmount(initialAmountState)
        } else setAmount([])
    }, [initialAmountState]);

    useEffect(() => {
        bundlePackages(amount)
    }, [amount]);

    // CONVERT % TO PRICE AND VICEVERSA WHEN CHANGING DISCOUNT TYPE
    useEffect(() => {
        console.log(amount)
        const newArray = [];
        amount.forEach((item, index) => {
            newArray.push({
                bundle_quantity: item.bundle_quantity,
                discount: discountType === 1
                    ? (productPrice * (1 - (item.discount / 100))).toFixed(2)
                    : (((item.discount * 100 / productPrice) - 100) * (-1)).toFixed(2),
                ...item.fk_product && {fk_product: item.fk_product},
                ...item.id && {id: item.id}
            })
        })
        setAmount(newArray)
    }, [discountType]);

    // HANDLE CHANGE PACKAGE BUNDLE AMOUNT
    const handleProductAmountChange = (index, e) => {
        const newAmount = [...amount];
        newAmount[index].bundle_quantity = e.target.value;
        setAmount(newAmount);

        const lastArray = [];
        newAmount.forEach((item, index) => {
            lastArray.push({
                bundle_quantity: item.bundle_quantity,
                discount_type: discountType,
                discount: discountType === 1
                    ? ((item.discount * 100 / productPrice) - 100) * (-1)
                    : item.discount,
                ...item.fk_product && {fk_product: item.fk_product},
                ...item.id && {id: item.id}
            })
        })
        bundlePackages(lastArray)
    };

    // HANDLE CHANGE PACKAGE BUNDLE DISCONT %
    const handleStaffelpreis = (index, e) => {
        const newAmount = [...amount];
        newAmount[index].discount = e.target.value;
        setAmount(newAmount);

        const lastArray = [];
        newAmount.forEach((item, index) => {
            lastArray.push({
                bundle_quantity: item.bundle_quantity,
                discount_type: discountType,
                discount: discountType === 1
                    ? ((item.discount * 100 / productPrice) - 100) * (-1)
                    : item.discount,
                ...item.fk_product && {fk_product: item.fk_product},
                ...item.id && {id: item.id}
            })
        })
        bundlePackages(lastArray)
    };

    // ADD A NEW OPTION
    const handleAddAmount = () => {
        setAmount(amount.concat([{
            bundle_quantity: ""
        }]))
    }

    // REMOVE OPTION
    function handleRemoveAmount(a, index) {
        setAmount(amount.filter((item) => item !== a));
    }

    return (
        <>
            {amount.length > 0 && (
                /* eslint-disable */
                <>
                    <MDBox sx={{display: "flex", justifyContent: "flex-end", marginRight: 5}}>
                        <FormControl style={{height: 40, marginBottom: 20, minWidth: 145}}>
                            <InputLabel>Typ</InputLabel>
                            <Select
                                style={{height: 40}}
                                label="Typ"
                                endAdornment={<InputAdornment position="end"><ArrowDropDown/></InputAdornment>}
                                value={discountType}
                                onChange={(e) => {
                                    if (amount.length && amount[0].discount) {
                                        if (confirm(discountType === 0 ? "Der Preis wird in Prozent dargestellt." : "Prozent wird als Preis dargestellt.")) {
                                            setDiscountType(e.target.value)
                                        }
                                    } else {
                                        setDiscountType(e.target.value)
                                    }

                                }}
                            >
                                <MenuItem value={0}>Prozent</MenuItem>
                                <MenuItem value={1}>Preis</MenuItem>
                            </Select>
                        </FormControl>
                    </MDBox>

                    {amount.map((a, index) => (
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 15,
                        }} key={index}>

                            <MDInput
                                type="number"
                                placeholder="Menge"
                                label="Gebindegröße"
                                inputProps={{
                                    min: (index - 1 >= 0) ? Number(amount[index - 1].bundle_quantity) + 1 : 0
                                }}
                                required
                                disabled={isLoading && true}
                                onChange={(e) => handleProductAmountChange(index, e)}
                                value={a.bundle_quantity}
                                style={{minWidth: "55%"}}
                            />

                            {/*CHANGE WHETEHR ITS PRICE OR PERCENTAGE*/}
                            <div style={{display: "flex", alignItems: "center", paddingLeft: 20}}>
                                {discountType === 0
                                    ? <Percent fontSize="small" color="primary"/>
                                    : <Euro fontSize="small" color="primary"/>
                                }
                                <MDInput
                                    type="number"
                                    inputProps={
                                        discountType === 0 ? (
                                            {
                                                min: amount[index - 1] ? amount[index - 1].discount : 0,
                                                max: 100,
                                                step: "any"
                                            }
                                        ) : (
                                            {
                                                min: 0,
                                                max: amount[index - 1] ? amount[index - 1].discount : productPrice,
                                                step: "any"
                                            }
                                        )
                                    }
                                    label={discountType === 0 ? "Rabatt" : "Preis"}
                                    placeholder={discountType === 0 ? "Pro Packgröße" : "Pro Packgröße"}
                                    fullWidth
                                    step="any"
                                    disabled={isLoading && true}
                                    onChange={(e) => handleStaffelpreis(index, e)}
                                    value={a.discount}
                                    style={{maxWidth: 250, marginLeft: 5}}
                                />
                            </div>


                            <IconButton
                                color="info"
                                aria-label="close tier pricing"
                                component="label"
                                onClick={() => handleRemoveAmount(a, index)}
                            >
                                <HighlightOffOutlined style={{color: "red"}}/>
                            </IconButton>

                        </div>
                        /* eslint-disable */

                    ))}
                </>
            )}

            <IconButton
                display="flex"
                flexDirection="row"
                alignItems="center"
                cursor="pointer"
                onClick={() => {
                    if (productPrice) {
                        handleAddAmount()
                    } else {
                        toast.error("Bitte gebe zuerst einen Preis für das Produkt an.")
                    }
                }}
            >
                <AddCircle
                    variant="outlined"
                    color="foodhiOrange"
                    fontSize="medium"
                    style={{marginRight: 5}}
                />
                <MDTypography variant="span" fontWeight="regular" textTransform="uppercase" fontSize={16}>
                    Gebinde
                </MDTypography>
            </IconButton>

        </>

    );
}

export default ProductAmountOptionsField;