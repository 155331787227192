import React, {useCallback, useEffect, useRef, useState} from 'react';
import {TailSpin} from "react-loader-spinner";
import {
    Button,
    Checkbox,
    FormControl,
    InputLabel,
    FormControlLabel,
    Icon,
    InputAdornment,
    MenuItem,
    Select,
    TableContainer, Grid, Input, TextField, Modal
} from "@mui/material";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import MDButton from "../../../../../components/MDButton";
import MDInput from "../../../../../components/MDInput";
import MDAvatar from "../../../../../components/MDAvatar";
import {getAxiosInstance} from "../../../../../utils/interceptor";
import store from "../../../../../reduxStore/store";
import {useDispatch, useSelector} from "react-redux";
import {createDeliveryNote} from "../functions/createDeliveryNote";
import sendEmail from "../../../../../emails/emailApi";
import {fetchOrders} from "../../../../../reduxStore/actions/actionOrders";
import {
    ArrowDropDown,
    DeleteOutline, Euro, Inventory, Inventory2Outlined,
    LocalShippingOutlined, NoteAddOutlined, PercentRounded
} from "@mui/icons-material";
import colors from "../../../../../assets/theme/base/colors";
import {sendPushNotification} from "../../../../../utils/sendPushNotification";
import Divider from "@mui/material/Divider";
import {toast} from "react-toastify";
import Card from "@mui/material/Card";
import DeliveryNoteCustomerSelection from "./DeliveryNoteCustomerSelection";
import {loadingStart, loadingStop} from "../../../../../reduxStore/actions/actionUI";

function ModifyOrderModal({order, handleCloseModal, orderStatus}) {
    const [editOrders, setEditOrders] = useState([]);
    const [checkCreateDeliveryNote, setCheckCreateDeliveryNote] = useState(false);
    const [freeDelivery, setFreeDelivery] = useState(false);
    const [externalVendorCustomerData, setExternalVendorCustomerData] = useState({});
    const [apiKeyNumber, setApiKeyNumber] = useState('');
    const [apiKeyProvider, setApiKeyProvider] = useState('');
    const [externalApiConnection, setExternalApiConnection] = useState(false);
    const [openProductNoteDialog, setOpenProductNoteDialog] = useState({
        visible: false,
        item: null,
        text: ""
    });

    const loading = useSelector(state => state.UI.loading);
    const farmer = useSelector(state => state.userProfile);

    const dispatch = useDispatch();

    const [manualDiscount, setManualDiscount] = useState(0);
    const [manualDiscountPrice, setManualDiscountPrice] = useState(0);

    const [packagingItems, setPackagingItems] = useState([]);
    const [packaging, setPackaging] = useState({
        name: '',
        amount: 0
    });
    const [packagingAdded, setPackagingAdded] = useState(order.packaging?.length ? order.packaging : []);

    const [discountType, setDiscountType] = useState(0);

    // DEFINE VARIABLES IF RESTAURANT IS NOT PRESENT (CUSTOM ORDER FROM FARMER)
    const restaurantName = order.restaurant ? order.restaurant.restaurant_name : "TEST"


    const handleTotalContentChange = (e, index) => {
        let items = [...editOrders]
        let item = {
            ...items[index],
            modifiedTotalContent: e.target.value,
        };
        items[index] = item
        setEditOrders(items)
    }

    // CHECK IF CONNECTION TO EXTERNAL API PROVIDER EXISTS AND TURN
    useEffect(() => {
        getAxiosInstance(store).get(`/items/farmer_profile/${farmer.id}`, {
            params: {"fields": "fk_api_connections.*"}
        })
            .then(response => {
                if (response.data.data.fk_api_connections.length > 0) {
                    setApiKeyNumber(response.data.data.fk_api_connections[0].key)
                    setApiKeyProvider(response.data.data.fk_api_connections[0].provider)
                    setCheckCreateDeliveryNote(true)
                    setExternalApiConnection(true)
                } else {
                    dispatch(loadingStop());
                }
            })
            .catch(error => {
                dispatch(loadingStop());
                console.log(error);
            })
    }, [])

    // RETREIVE ORDER DETAILS AND PACKAGING OPTIONS
    useEffect(() => {
        getAxiosInstance(store).get(`items/packaging_items`)
            .then(response => {
                setPackagingItems(response.data.data)
            })
            .catch(error => console.log(error.response))

        if (order.fk_order_items && order.fk_order_items.length) {
            setEditOrders([]);
            order.fk_order_items.forEach(item => {
                setEditOrders(prevState => [
                    ...prevState,
                    {
                        id: item.id,
                        name: item.name,
                        unit_amount: item.unit_amount,
                        measurement: item.measurement,
                        pricePerUnit: item.subtotal,
                        updatedPricePerUnit: item.updated_subtotal,
                        discount: item.discount,
                        quantity: item.quantity,
                        price: item.price * item.quantity,
                        // modifiedPrice: (item.price * item.quantity) * (1 - manualDiscount / 100),
                        image: item.images[0],
                        totalContent: item.product_amount,
                        modifiedTotalContent: item.updated_product_amount,
                        note: item.note
                    }
                ])
            })
        }
    }, [])

    // WHEN SELECTING PACKAGE, RESET SELECTOR
    useEffect(() => {
        setPackaging({
            name: '',
            amount: 0
        })
    }, [packagingAdded]);

    const renderPackagingSelect = () => {
        // IF PACKAGE ADDED NAME, FILTER PACKAGING ITEMS
        const added = packagingAdded.map(item => item.name)
        const filteredArray = packagingItems.filter(item => !added.includes(item.name))

        if (filteredArray.length) {
            return (
                filteredArray.map(item => (
                    <MenuItem key={item.id} value={item.name}>
                        {item.name}
                    </MenuItem>
                ))
            )
        }
    }

    const handleManualDiscount = (e, item) => {
        if (discountType === 0) {
            setManualDiscount(e.target.value)
            setManualDiscountPrice(((item.updatedPricePerUnit * item.quantity) * (1 - item.discount / 100)) * (1 - manualDiscount / 100))
        }
        if (discountType === 1) {
            setManualDiscount((e.target.value * 100 / (item.updatedPricePerUnit * item.quantity) - 100) * (-1))
            setManualDiscountPrice(e.target.value)
        }
    }

    const calculateProductPrice = (item) => {
        function applyImmediateOrderManualDiscount() {
            if (discountType === 0) {
                return finalPriceBeforeManualDiscountForInstantOrder * (1 - manualDiscount / 100)
            }
            if (discountType === 1) {
                return manualDiscountPrice || 0
            }
            // if (order.immediate_order) {
            //     if (discountType === 0) {
            //         return finalPriceBeforeManualDiscountForInstantOrder * (1 - manualDiscount / 100)
            //     }
            //     if (discountType === 1) {
            //         return manualDiscountPrice
            //     }
            // } else {
            //     return finalPriceBeforeManualDiscountForInstantOrder.toLocaleString("de-DE", {
            //         minimumFractionDigits: 2,
            //         maximumFractionDigits: 2
            //     })
            // }
        }

        const originalPriceWithDiscount = item.modifiedTotalContent * (item.price / item.quantity - item.discount);
        const totalPriceOfModifiedAmount = originalPriceWithDiscount / item.totalContent * item.quantity;
        const finalPriceBeforeManualDiscountForInstantOrder = totalPriceOfModifiedAmount;

        return applyImmediateOrderManualDiscount(finalPriceBeforeManualDiscountForInstantOrder)
        // return (Math.round((item.modifiedTotalContent * (Math.round((((item.price) / item.quantity) - item.discount) * 100) / 100 / item.totalContent * item.quantity) * (1 - manualDiscount / 100)) * 100) / 100)
    }

    const handleChangeDiscountType = (e, item) => {
        setManualDiscount(0);
        setManualDiscountPrice(((item.price / item.quantity) - item.discount) * item.quantity)
        setDiscountType(e.target.value)
    }

    const openAngabe = (item) => {
        getAxiosInstance(store).get(`items/order_items/${item.id}`)
            .then(response => {
                setOpenProductNoteDialog({
                    visible: true,
                    item,
                    text: response.data.data.note || ""
                })
            })
            .catch(error => console.log(error.response))
    }


    const setExternalVendorCustomerDataCallback = (data) => {
      setExternalVendorCustomerData(data)
    }

    const setCheckCreateDeliveryNoteCallback = (data) => {
      setCheckCreateDeliveryNote(data)
    }

    const handleSubmitChanges = () => {
        // SET LOADING ON
        dispatch(loadingStart())

        const newLineItemsObject = [];
        let sumOfProductTotals = [];
        order.fk_order_items.forEach((item, index) => {
            // ADD PRODUCT ITEMS TO ARRAY
            const updated_subtotal = (editOrders[index].modifiedTotalContent * editOrders[index].pricePerUnit / editOrders[index].totalContent);

            function updatedSubtotalWithManualDiscount() {
                // if (!order.immediate_order) {
                //     return updated_subtotal;
                // } else {
                //     if (discountType === 0) {
                //         return Number(updated_subtotal * (1 - manualDiscount / 100));
                //     }
                //     if (discountType === 1) {
                //         return Number(manualDiscountPrice / item.quantity);
                //     }
                // }
                // IF PERCENTAGE DISCOUNT IS SELECTED
                if (discountType === 0) {
                    return Number(updated_subtotal * (1 - manualDiscount / 100));
                }
                // IF FINAL PRICE IS INPUT
                if (discountType === 1) {
                    return Number(manualDiscountPrice / item.quantity);
                }
            }

            newLineItemsObject.push(
                {
                    id: item.id,
                    updated_product_amount: editOrders[index].modifiedTotalContent,
                    updated_subtotal: updatedSubtotalWithManualDiscount(),
                    // item_total: (updated_subtotal * item.quantity) * (1 - manualDiscount / 100),
                    item_total: updatedSubtotalWithManualDiscount() * item.quantity,
                    discount: editOrders[index].discount
                    // ...!order.immediate_order
                    //     ? {discount: editOrders[index].discount}
                    //     : discountType === 0
                    //         ? {discount: item.price - updatedSubtotalWithManualDiscount()}
                    //         : {discount: ((item.price * item.quantity) - manualDiscountPrice) / item.quantity}
                }
            )
            sumOfProductTotals.push(updatedSubtotalWithManualDiscount() * item.quantity)
        })

        // ADD PACKAGING TO ARRAY
        const packagingItems = [];
        packagingAdded.forEach((item) => {
            packagingItems.push(item)
        })

        const sumOfTotal = sumOfProductTotals.reduce((a, b) => a + b, 0)

        const data = {
            fk_order_status: 2,
            fk_order_items: newLineItemsObject,
            packaging: packagingItems,
            total: freeDelivery ? sumOfTotal : (sumOfTotal + order.delivery_fee),
            ...freeDelivery && {delivery_fee: 0}
        }

        dispatch(loadingStop())
        getAxiosInstance(store).patch(`/items/orders/${order.id}`, data, {params: {fields: "*.*, farmer.fk_address.*"}})
            .then((response) => {
                orderStatus(response.data.data.fk_order_status);
                const orderData = response.data.data;

                // IF CHECKBOX FOR CREATING LIEFERSCHEIN IS TRUE, EXECUTE FUNCTION
                if (checkCreateDeliveryNote) {
                    // CREATE LIEFERSHEIN AND SEND EMAIL
                    createDeliveryNote(orderData, farmer, dispatch, externalVendorCustomerData)
                } else {
                    createDeliveryNote(orderData, farmer, dispatch, externalVendorCustomerData)


                    // // IF THERES A RESTARUANT LINKED TO THE ORDER, SEND EMAIL AND PUSH NOTIFICATION
                    // if(order.restaurant) {
                    //     sendEmail({
                    //         templateId: "RESTAURANT_Order_Shipped",
                    //         data: {...orderData, farmer},
                    //         recipient: orderData.restaurant.contact_email,
                    //     });
                    //     // SEND PUSH NOTIFICATION TO RESTAURANT
                    //     sendPushNotification(
                    //         `${order.restaurant.push_notification_key}`,
                    //         "default",
                    //         "Lieferung deiner Bestellung",
                    //         `Die Bestellung #${order.id} wird bald geliefert`
                    //     )
                    // }
                    //
                    // dispatch(fetchOrders(false, farmer.id))
                }

                handleCloseModal();
                dispatch(loadingStop());
            })
            .catch((error) => {
                console.log(error, "ERROR MODIFYING ORDER");
                dispatch(loadingStop());
                handleCloseModal();
            });
    }


    return (
        <MDBox style={{
            position: 'absolute',
            top: 40,
            left: 0,
            right: 0,
            marginLeft: "auto",
            marginRight: "auto",
            // width: 800,
            maxWidth: 860,
            backgroundColor: '#fff',
            boxShadow: 24,
            p: 4,
            borderRadius: 10
        }}>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                variant="gradient"
                bgColor="foodhiLightGreen"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h5" color="dark">
                    Bestellung #{order.id} | <span color="red">{restaurantName}</span>
                </MDTypography>

                <Button
                    variant="gradient"
                    onClick={() => handleCloseModal()}
                >
                    <Icon
                        sx={{
                            fontWeight: "bold",
                            color: "#fff",
                        }}
                        fontSize="16"
                    >cancel</Icon>
                </Button>
            </MDBox>

            <MDBox pt={3} px={3} mb={1}
                   sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                {
                    order.immediate_order && (
                        <MDTypography variant="h6" color="error" sx={{
                            marginRight: 2,
                            backgroundColor: colors.foodhiOrange.main, color: "#fff", padding: "0 10px", borderRadius: 8
                        }}>
                            SOFORT BESTELLUNG
                        </MDTypography>
                    )
                }
            </MDBox>
            {
                order.delivery_note && (
                    <MDBox display="flex" sx={{flexDirection: "row", alignItems: "center", gap: 1}} pt={1} px={3}>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontSize="18px"
                            fontWeight="medium"
                            color="error"
                        >
                            Bemerkung:
                        </MDTypography>
                        <MDTypography
                            display="flex"
                            alignItems="center"
                            variant="button"
                            fontSize="18px"
                            fontWeight="regular"
                            color="text"
                        >
                            {`${order.delivery_note}`}
                        </MDTypography>
                    </MDBox>
                )
            }
            <MDBox pt={3}>
                <form onSubmit={(e) => {
                    e.preventDefault();

                    // IF THERES NO CUSTOMER SELECTED, SHOW A WARNING
                    if ((!externalVendorCustomerData || Object.keys(externalVendorCustomerData).length === 0) && apiKeyProvider && !order.delivery_slip) {
                        if (confirm("Du hast keinen Kunden ausgewählt, um einen Lieferschein zu erstellen. Möchtest du einen Lieferschein mit den bestehenden Kundeninformationen erstellen?")) {
                            handleSubmitChanges(e)
                        }
                    } else {
                        handleSubmitChanges(e)
                    }
                }}>
                    <TableContainer style={{overflowX: "auto", boxShadow: "none", padding: "0 15px"}}>
                        <Table
                            sx={{minWidth: 650, marginBottom: 2}}
                            aria-label="Product details"
                        >
                            <TableHead sx={{display: "table-header-group"}}>
                                <TableRow>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Menge</TableCell>
                                    <TableCell align="left">Inhalt (aktualisiert)</TableCell>
                                    <TableCell align="left">{discountType === 0 ? "Rabatt" : "Neuer Preis"}</TableCell>
                                    <TableCell align="left">Preis</TableCell>
                                    <TableCell align="left">Angabe</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {editOrders && editOrders.length
                                    ? (editOrders.map((item, index) => {
                                        return (
                                            <TableRow key={item.name}>
                                                {/*PRODUCT*/}
                                                <TableCell width="20%" component="th" scope="row">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                        <MDAvatar
                                                            src={`${process.env.REACT_APP_SITE_URL}/assets/${item.image}`}
                                                            size="sm"
                                                            sx={{marginRight: 1}}
                                                        />
                                                        <MDTypography style={{fontSize: 14}}>
                                                            {`${item.name}`}</MDTypography>
                                                    </div>
                                                </TableCell>

                                                {/*CONTENT PER PIECE*/}
                                                <TableCell width="15%" align="left">
                                                    <MDTypography style={{fontSize: 14}}>
                                                        {/*IF MEASUREMENT IS DIFFERENT THAT "STUCK"*/}
                                                        {`${item.quantity} x ${item.unit_amount} ${item.measurement}`}
                                                    </MDTypography>
                                                </TableCell>

                                                {/*TOTAL QUANTITY AMOUNT*/}
                                                <TableCell align="left" width="20%">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}>
                                                        <div style={{maxWidth: 80, marginRight: 10}}>
                                                            {
                                                                <MDInput
                                                                    type="number"
                                                                    // variant="standard"
                                                                    label="Inhalt"
                                                                    disabled={loading && true}
                                                                    onChange={(e) => handleTotalContentChange(e, index)}
                                                                    value={item.modifiedTotalContent}
                                                                />
                                                                // )
                                                            }
                                                        </div>
                                                        <span style={{fontSize: 14}}>{item.measurement}</span>
                                                    </div>
                                                </TableCell>

                                                {/*MANUAL DISCOUNT*/}

                                                <TableCell align="left" width="25%">
                                                    <MDBox sx={{
                                                        flexDirection: "row",
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}>
                                                        <MDInput
                                                            type="number"
                                                            required
                                                            // variant="standard"
                                                            label={discountType === 0 ? "%" : "€"}
                                                            style={{marginRight: 10}}
                                                            inputProps={
                                                                discountType === 0 ? {
                                                                    min: 0,
                                                                    max: 100
                                                                } : {
                                                                    min: 0
                                                                }
                                                            }
                                                            sx={{flex: 2}}
                                                            disabled={loading && true}
                                                            onChange={(e) => handleManualDiscount(e, item)}
                                                            // value={(item.discount * item.quantity * 100 / item.price).toFixed(0)}
                                                            value={discountType === 0 ? manualDiscount : manualDiscountPrice.toLocaleString("de-DE", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}
                                                        />

                                                        <Select
                                                            style={{height: 44}}
                                                            variant="standard"
                                                            label="Typ"
                                                            sx={{flex: 1}}
                                                            endAdornment={<InputAdornment
                                                                position="center"><ArrowDropDown/></InputAdornment>}
                                                            value={discountType}
                                                            onChange={(e) => handleChangeDiscountType(e, item)}
                                                        >
                                                            <MenuItem value={0}>
                                                                <PercentRounded fontSize="small" color="standard"/>
                                                            </MenuItem>
                                                            <MenuItem value={1}>
                                                                <Euro fontSize="small" color="standard"/>
                                                            </MenuItem>
                                                        </Select>
                                                    </MDBox>
                                                </TableCell>

                                                {/*TOTAL PRICE*/}
                                                <TableCell align="left" width="15%">
                                                    {
                                                        <MDTypography style={{fontSize: 14}} fontWeight="bold">
                                                            € {calculateProductPrice(item, index).toLocaleString("de-DE", {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                        </MDTypography>

                                                    }

                                                </TableCell>

                                                {/*ANGABE*/}
                                                <TableCell align="center" width="10%">
                                                    <NoteAddOutlined
                                                        fontSize="medium"
                                                        sx={{cursor: "pointer"}}
                                                        onClick={() => openAngabe(item)}
                                                        color={item.note ? "error" : "standard"}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }))
                                    : <TailSpin/>
                                }

                                {/*PACKAGING ITEMS*/}
                                {
                                    packagingAdded.length > 0 && packagingAdded.map((item) => (
                                        <TableRow key={item.id}>
                                            <TableCell width="30%" sx={{
                                                flexDirection: "row",
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 2
                                            }}>
                                                <Inventory2Outlined/>
                                                <MDTypography style={{fontSize: 14}}>
                                                    {item.name}
                                                </MDTypography>
                                            </TableCell>
                                            <TableCell>
                                                <MDTypography style={{fontSize: 14}}>
                                                    {item.amount} Stück
                                                </MDTypography>
                                            </TableCell>
                                            <TableCell width="0%">
                                            </TableCell>
                                           <TableCell width="0%">
                                            </TableCell>
                                            <TableCell>
                                                <MDTypography style={{fontSize: 14}} fontWeight="bold">
                                                    € {Number(0).toLocaleString("de-DE", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                                </MDTypography>
                                            </TableCell>
                                            <TableCell  align="center">
                                                <DeleteOutline
                                                    sx={[{cursor: "pointer", flex: 1, justifyContent: "center"}]}
                                                    fontSize="medium"
                                                    style={{color: "red"}}
                                                    onClick={() => setPackagingAdded(packagingAdded.filter(pack => pack !== item))}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                    {/*DELIVERY INFORMATION*/}
                    <Grid pt={3} px={4} pb={1} md={8}>
                        <MDTypography
                            sx={{fontSize: 16, paddingBottom: 2, display: "flex", alignItems: "center", gap: 1}}
                            fontWeight="bold"
                        >
                            <LocalShippingOutlined fontSize="small"/> Liefergebühr
                        </MDTypography>
                        <MDBox px={1} sx={{flexDirection: "row", display: "flex", alignItems: "center", gap: 4}}>
                            <MDBox sx={{flexDirection: "row", display: "flex", alignItems: "center", flex: 1}}>
                                <MDTypography style={{fontSize: 16}} fontWeight="regular">
                                    Liefergebühr: {freeDelivery
                                    ? <span style={{color: "green"}}>Kostenlos</span>
                                    : `€ ${order.delivery_fee.toLocaleString("de-DE", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}`
                                }
                                </MDTypography>
                            </MDBox>
                            {order.delivery_fee > 0 && (
                                <FormControlLabel
                                    sx={{alignItems: "center", display: "flex", flexDirection: "row", flex: 1}}
                                    control={<Checkbox checked={freeDelivery}
                                                       onChange={() => setFreeDelivery(!freeDelivery)}
                                                       inputProps={{'aria-label': 'controlled',}}/>}
                                    label="Kostenlose Lieferung"
                                />
                            )}
                        </MDBox>

                    </Grid>


                    <Divider/>

                    {/*PACKAGING SECTION*/}
                    <MDBox pb={2} px={4}>
                        <MDTypography
                            sx={{fontSize: 16, paddingBottom: 3, display: "flex", alignItems: "center", gap: 1}}
                            fontWeight="bold">
                            <Inventory/> Packung hinzufügen
                        </MDTypography>
                        <Grid
                            container
                            gap={2}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            md={8}
                        >
                            <Grid item flex={6}>
                                <FormControl variant="outlined" fullWidth style={{maxWidth: 200}}>
                                    <InputLabel id="verpackung-label">Verpackung</InputLabel>
                                    <Select
                                        labelId="verpackung"
                                        id="verpackung"
                                        value={packaging.name}
                                        label="Verpackung"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <ArrowDropDown fontSize="medium" color="standard"/>
                                            </InputAdornment>
                                        }
                                        onChange={(e) => setPackaging(prevState => ({
                                            ...prevState,
                                            name: e.target.value,
                                        }))}
                                        disabled={loading && true}
                                        style={{minHeight: "44px"}}
                                    >
                                        {/*HERE WATCH OUT, IDs ARE SKIPPED AT NUMBER 4!!!!*/}
                                        {renderPackagingSelect()}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item flex={4}>
                                <FormControl fullWidth style={{maxWidth: 100}}>
                                    <MDInput
                                        type="number"
                                        inputProps={{
                                            min: 0
                                        }}
                                        label="Menge"
                                        disabled={loading && true}
                                        onChange={(e) => setPackaging(prevState => ({
                                            ...prevState,
                                            amount: e.target.value
                                        }))}
                                        value={packaging.amount}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item flex={2}>
                                <MDButton
                                    sx={{
                                        color: "#fff",
                                        '&:hover': {
                                            backgroundColor: '#fff',
                                            color: colors.foodhiOrange.main,
                                            border: 2,
                                            borderColor: colors.foodhiOrange.main,
                                            // borderRadius: 8
                                        },
                                        backgroundColor: colors.foodhiOrange.main,
                                        cursor: "pointer"
                                    }}
                                    fontSize="medium" onClick={() => {
                                    if (packaging.name && packaging.amount) {
                                        if (packaging.amount > 0) {
                                            setPackagingAdded(prevState => ([
                                                ...prevState,
                                                packaging
                                            ]))
                                        } else {
                                            alert("Menge muss größer als 0 sein")
                                        }
                                    } else alert("Bitte gib eine Paketart und -nummer an")

                                }}>
                                    Hinzufügen
                                </MDButton>
                                {/*<AddCircleOutlined sx={{color: colors.foodhiOrange.main, cursor: "pointer"}}*/}
                                {/*                   fontSize="medium" onClick={() => {*/}
                                {/*    if (packaging.name && packaging.amount) {*/}
                                {/*        if (packaging.amount > 0) {*/}
                                {/*            setPackagingAdded(prevState => ([*/}
                                {/*                ...prevState,*/}
                                {/*                packaging*/}
                                {/*            ]))*/}
                                {/*        } else {*/}
                                {/*            alert("Menge muss größer als 0 sein")*/}
                                {/*        }*/}
                                {/*    } else alert("Bitte gib eine Paketart und -nummer an")*/}

                                {/*}}/>*/}
                            </Grid>
                        </Grid>
                    </MDBox>

                    <Divider/>


                    {/*DELIVERY NOTE CREATION SECTION*/}
                    <DeliveryNoteCustomerSelection
                        externalApiConnection={externalApiConnection}
                        externalVendorCustomerData={externalVendorCustomerData}
                        setExternalVendorCustomerData={setExternalVendorCustomerDataCallback}
                        order={order}
                        checkCreateDeliveryNote={checkCreateDeliveryNote}
                        apiKeyNumber={apiKeyNumber}
                        apiKeyProvider={apiKeyProvider}
                        setCheckCreateDeliveryNote={setCheckCreateDeliveryNoteCallback}
                    />


                    <Divider/>


                    {/*UPDATE SUBMIT BUTTON*/}
                    <MDBox pt={2} pb={3} px={4}>
                        <MDButton type="submit" variant="gradient" color="info" disabled={loading}>
                            {!loading ? "Aktualisieren" : <TailSpin height={20} color="lightgray"/>}
                        </MDButton>
                    </MDBox>
                </form>
            </MDBox>


            {/*PRODUCT EXTRA DETAILS DIALOG*/}
            <Modal
                open={openProductNoteDialog.visible}
                onClose={!openProductNoteDialog.visible}
                style={{
                    // position: "absolute",
                    position: "fixed",
                    // top: "10%",
                    // left: "10%",
                    overflow: "scroll",
                    height: "100%",
                    display: "block",
                    overflowY: "auto"
                }}
            >
                <MDBox
                    pt="5rem"
                    pb={3}
                    // sx={{width: window.innerWidth * 0.75}}
                    maxWidth={400}
                    margin="auto"
                >
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={2}
                                    px={2}
                                    variant="gradient"
                                    bgColor="foodhiLightGreen"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <MDTypography color="#3D3D3D">
                                        Weitere Angaben zum Produkt: {openProductNoteDialog.item?.name}
                                    </MDTypography>
                                    <Button
                                        color={colors.foodhiOrange.main}
                                        variant="gradient"
                                        onClick={() => {
                                        }}
                                    >
                                        <Icon
                                            sx={{
                                                fontWeight: "bold",
                                                color: "#fff",
                                            }}
                                            fontSize="16"
                                        >
                                            cancel
                                        </Icon>
                                    </Button>
                                </MDBox>
                                <MDBox p={3}>
                                    <MDInput
                                        multiline
                                        rows={3}
                                        label="z.B.: Mindesthaltbarkeitsdatum..."
                                        fullWidth
                                        disabled={loading && true}
                                        onChange={(e) => {
                                            setOpenProductNoteDialog(prevState => ({
                                                ...prevState,
                                                text: e.target.value
                                            }))
                                        }}
                                        value={openProductNoteDialog.text}
                                        sx={{marginBottom: 2}}
                                    />
                                    <MDBox display="flex" justifyContent="space-between">
                                        <Button sx={{
                                            color: "#fff",
                                            '&:hover': {
                                                backgroundColor: '#fff',
                                                color: colors.foodhiOrange.main,
                                                border: 2,
                                                borderColor: colors.foodhiOrange.main,
                                                // borderRadius: 8
                                            },
                                            backgroundColor: colors.foodhiOrange.main,
                                            cursor: "pointer"
                                        }}
                                                fontSize="medium"
                                                onClick={() => {
                                                    // SAVE NOTE ON PRODUCT ITEM RECORD
                                                    getAxiosInstance(store).patch(`items/order_items/${openProductNoteDialog.item.id}`, {
                                                        note: openProductNoteDialog.text
                                                    })
                                                        .then(response => {
                                                            toast.success(`Angabe zum ${openProductNoteDialog.item.name} wurde hinzugefügt.`)
                                                            setOpenProductNoteDialog({
                                                                visible: false,
                                                                item: null,
                                                                text: openProductNoteDialog.text
                                                            })
                                                        })
                                                        .catch(error => {
                                                            toast.error(error.response)
                                                            setOpenProductNoteDialog({
                                                                visible: false,
                                                                item: null,
                                                                text: openProductNoteDialog.text
                                                            })
                                                        })
                                                }}
                                        >
                                            Speichern
                                        </Button>
                                        <MDButton sx={{
                                            color: "#3D3D3D",
                                            backgroundColor: "lightgray",
                                            cursor: "pointer",
                                            '&:hover': {
                                                backgroundColor: "lightgray",
                                                color: "gray"
                                            },
                                        }}
                                                  fontSize="medium"
                                                  onClick={() => {
                                                      setOpenProductNoteDialog({
                                                          visible: false,
                                                          item: null,
                                                          text: openProductNoteDialog.text
                                                      })
                                                  }}
                                        >
                                            Abbrechen
                                        </MDButton>
                                    </MDBox>

                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </Modal>
        </MDBox>
    );
}

export default ModifyOrderModal;