/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import {TailSpin} from "react-loader-spinner";

import {Divider, Card, Grid, Typography, Box, Icon} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";

import {fetchOrders} from "../../reduxStore/actions/actionOrders";
import DataTable from "../../examples/Tables/DataTable";
import FutureOrders from "./components/FutureOrders";
import {authLogout} from "../../reduxStore/actions/actionAuth";
import QuickNumberCard from "./components/QuickNumberCard";
import MDAvatar from "../../components/MDAvatar";
import {THUMBNAIL_SETTINGS} from "../../variables";
function Dashboard() {
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orders.ordersList);
    const loading = useSelector(state => state.orders.loading);

    const token = useSelector(state => state.auth.token);
    const farmerId = useSelector(state => state.userProfile.id);
    const navigate = useNavigate();

    const [futureDeliveryDates, setFutureDeliveryDates] = useState([]);

    useEffect(() => {
        // IF AUTHENTICATED, REDIRECT TO DASHBOARD INDEX
        if (!token) {
            dispatch(authLogout());
            navigate("/authentication/signin");
        } else {
            dispatch(fetchOrders(true, farmerId))
        }
    }, []);

    useEffect(() => {
        if (orders.length > 0) {
            // SET AN EMPTY ARRAY TO FILL IN WITH ALL DELIVERY DATES COLLECTED FORM THE FETCHED ORDERS
            const deliveryDates = [];

            // LOOP THROUGH THE ORDERS AND EXTRACT THEIR SAVED DELIVERY DATE, THEN PUSH IT TO THE ARRAY
            orders.forEach(order => {
                const orderDate = order.delivery_date;
                deliveryDates.push(orderDate);
            })

            // ELIMINATE DUPLICATE DATES
            const uniqueDates = [];

            deliveryDates.forEach((date) => {
                if (!uniqueDates.includes(date)) {
                    uniqueDates.push(date);
                }
            });

            // SORT THE UNIQUE DATES
            /* eslint-disable */
            uniqueDates.sort((a, b) => {
                a = a.split('-').reverse();
                b = b.split('-').reverse();
                return a[2] - b[2] || a[1] - b[1] || a[0] - b[0]
            })

            // PARSE STRING DATES INTO DATE OBJECTS
            const stringToDate = []
            uniqueDates.forEach(date => {
                const dateSplit = date.split('-')
                const dateObject = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2])
                stringToDate.push(dateObject.toLocaleDateString("fr-CH"))
            })

            // SET STATE WITH THE FORMATTED DATES
            setFutureDeliveryDates(stringToDate);
        }
    }, [orders])
    function numberOfStops(futureDeliveryDate) {

        // ISOLATE ADDRESSES IN THE ARRAY AND CREATE A NEW ARRAY WITH THEM
        const addresses = []
        futureDeliveryDate.forEach((order) => {
            addresses.push(`${order.fk_shipping_address?.street_1}, ${order.fk_shipping_address?.postcode}, ${order.fk_shipping_address?.city}`)
        })

        // ELIMINATE DUPLICATES ON THE ARRAY
        const uniq = [...new Set(addresses)];
        const noOfStops = uniq.length;

        return (
            <>
                {/*CALCULATE THE AMOUNT OF ORDERS TO BE SHIPPED ON THAT DATE*/}
                <QuickNumberCard
                    color="success"
                    icon="local_shipping"
                    title="# Stops"
                    loading={false}
                    count={noOfStops}
                />
            </>

        )
    }
    function numberOfProducts(futureDeliveryDate) {
        function countNumberOfProducts(futureDeliveryDate) {

            const sumOfProducts = [];
            futureDeliveryDate.forEach(item => {
                item.fk_order_items.forEach(i => {
                    const updated_product_amount_number = Number(i.updated_product_amount)
                    const {name, images, quantity, unit_amount, measurement} = i;
                    sumOfProducts.push({
                        name,
                        images,
                        quantity,
                        unit_amount,
                        measurement,
                        updated_product_amount_number
                    })
                })
            });

            // SUM UP ALL DIFFERENT PRODUCTS INTO ONE
            const arrayByName = [];
            for (const element of sumOfProducts) {
                const name = element.name;
                const images = element.images;
                const quantity = element.quantity;
                const unit_amount = element.unit_amount;
                const measurement = element.measurement;
                const updated_product_amount = element.updated_product_amount_number;

                const index = arrayByName.findIndex(obj => obj.name === name);
                if (index !== -1) {
                    arrayByName[index].images = images;
                    arrayByName[index].quantity += quantity;
                    arrayByName[index].unit_amount = unit_amount;
                    arrayByName[index].measurement = measurement;
                    arrayByName[index].updated_product_amount += updated_product_amount;
                } else {
                    arrayByName.push({name, images, quantity, unit_amount, measurement, updated_product_amount});
                }
            }

            return arrayByName;
        }

        return (
            // LIST UNIQUE PRODUCTS
            <Grid item style={{height: "100%"}}>
                <MDBox style={{flex: 1, display: "flex", flexDirection: "column", overflow: "hidden"}}>
                    {/*CALCULATE THE AMOUNT OF ORDERS TO BE SHIPPED ON THAT DATE*/}
                    <MDTypography style={{
                        padding: "0 15px",
                        // background: colors.foodhiLightGreen.main,
                        // borderRadius: "12px",
                        // margin: "-25px 10px 0px 10px",
                        // color: "#fff",
                        fontFamily: "Rubik, sans-serif",
                        fontWeight: "600",
                        fontSize: 18
                    }}>Bestellte Produkte
                    </MDTypography>

                    <Box pt={1} px={2}>
                        <DataTable
                            table={{
                                columns: [
                                    {Header: "produkt", accessor: "produkt", flex: 4, align: "left"},
                                    {Header: "menge", accessor: "menge", align: "left", flex: 4},
                                    {Header: "gesamt (aktualisiert)", accessor: "gesamt", flex: 4},
                                ],
                                rows: countNumberOfProducts(futureDeliveryDate).map((item) => {
                                    const thumbnail = item.images && `${process.env.REACT_APP_SITE_URL}/assets/${item.images[0]}?${THUMBNAIL_SETTINGS}`
                                    return {
                                        produkt: <Box sx={{flexDirection: "row", display: "flex", alignItems: "center", gap: 2}}>
                                            <MDAvatar src={thumbnail} size="sm"/>
                                            <span>{item.name}</span>
                                        </Box>,
                                        menge: `${item.quantity} x ${item.unit_amount} ${item.measurement}`,
                                        gesamt: `${item.updated_product_amount} ${item.measurement}`,
                                    }
                                })
                            }}
                            sx={{padding: 20}}
                            showTotalEntries={false}
                            noEndBorder
                            entriesPerPage={false}
                            canSearch={false}
                        />
                    </Box>
                </MDBox>
            </Grid>
        )
    }
    // FILTER ORDERS ACCORDING TO THE FUTURE DATES
    function listOrdersFromDate(date) {
        const splitDate = date.split(".").reverse()
        const day = splitDate[2].padStart(2, "0");
        const month = splitDate[1].padStart(2, "0");
        const year = splitDate[0];
        const dateToMatch = `${year}-${month}-${day}`

        const filteredOrdersPerDay = orders.filter(order => order.delivery_date === dateToMatch)

        return filteredOrdersPerDay;
    }
    function renderContent() {
        if (futureDeliveryDates.length > 0) {
            return (
                <MDBox mt={4.5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12}>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="foodhiLightGreen"
                                borderRadius="lg"
                                coloredShadow="info"
                                style={{zIndex: 2, position: "relative"}}
                            >
                                <MDTypography
                                    color="white"
                                    fontWeight="bold"
                                    sx={{
                                        background: "foodhiLightGreen",
                                        color: "#fff",
                                        fontFamily: "Rubik, sans-serif",
                                        fontSize: 18
                                    }}>
                                    Lieferplanung
                                </MDTypography>
                            </MDBox>

                            {/*MAP THROUGH THE FUTURE DELIVERY DATES AND CREATE DIFFERENT LISTS PER DAY*/}
                            {
                                futureDeliveryDates.map((futureDeliveryDate, index) => (
                                    <Card key={index}
                                          style={{
                                              padding: "25px 5px",
                                              ...(index === 0 ? {margin: "-35px 0 30px 0"} : {margin: "0 0 30px 0"}),
                                              zIndex: 1,
                                              position: "relative"
                                          }}
                                    >
                                        <MDBox style={{marginBottom: 0, marginTop: index === 0 ? 40 : 0}}>
                                            <MDTypography
                                                variant="h4"
                                                sx={{
                                                    marginLeft: 3,
                                                    marginBottom: 1,
                                                    alignItems: "center",
                                                    display: "flex",
                                                    flex: 1
                                            }}>
                                                <Icon sx={{marginRight: 1}}>local_shipping</Icon>
                                                {index === 0 ? "Nächster Liefertermin: " : "Anstehende Bestellung: "}
                                                {futureDeliveryDate}
                                            </MDTypography>

                                            <Divider/>

                                            {/*SHOW SUMMARY CARDS ONLY ON FIRST COMING ORDER*/}
                                            {index === 0 && (
                                                <Grid
                                                    container
                                                    gap={3}
                                                    px={1}
                                                    py={3}
                                                    flexDirection="row"
                                                    display="flex"
                                                    md={8}
                                                    lg={6}
                                                >
                                                    {/*CALCULATE THE AMOUNT OF ORDERS TO BE SHIPPED ON THAT DATE*/}
                                                    <Grid item sx={{flex: 1}}>
                                                        <QuickNumberCard
                                                            color="success"
                                                            icon="shopping_bag"
                                                            title="Bestellungen"
                                                            loading={false}
                                                            count={listOrdersFromDate(futureDeliveryDate).length}
                                                            percentage={{
                                                                label: "Bestellungen",
                                                            }}
                                                        />
                                                    </Grid>

                                                    {/*NUMBER OF STOPS THE AMOUNT OF PRODUCTS INDIVIDUALLY TO BE SHIPPED */}
                                                    <Grid item sx={{flex: 1}}>
                                                        {numberOfStops(listOrdersFromDate(futureDeliveryDate))}
                                                    </Grid>

                                                </Grid>
                                            )}

                                            <Grid container gap={3} px={1} mb={4}>
                                                {/*NUMBER OF PRODUCTS THAT NEED TO BE PREPARED */}
                                                <Grid pt={5} xs={12} md={12}>
                                                    {numberOfProducts(listOrdersFromDate(futureDeliveryDate))}
                                                </Grid>
                                            </Grid>

                                            <Divider />

                                            {/*LIST ALL ORDERS FOR THE DAY*/}
                                            <Grid container gap={3} px={1} mb={4}>
                                                <FutureOrders orders={listOrdersFromDate(futureDeliveryDate)}/>
                                            </Grid>
                                        </MDBox>
                                    </Card>
                                ))}
                        </Grid>
                    </Grid>
                </MDBox>
            )
        }
        return (
            <MDBox ml={2}>
                <h3>Keine kommenden Bestellungen</h3>
            </MDBox>
        )
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            {
                !loading
                    ? futureDeliveryDates.length
                        ? renderContent()
                        : <Typography>Noch keine Bestellungen</Typography>

                    : (
                        <MDBox>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "400px"
                            }}>
                                <TailSpin/>
                            </div>
                        </MDBox>
                    )
            }
            <Footer/>
        </DashboardLayout>
    )

}

export default Dashboard;
