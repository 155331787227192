import * as actionTypes from "./types";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../store";

export const fetchOrders = (isDashboard, farmerId, page, sort, limit, search) => async dispatch => {

    dispatch({type: actionTypes.FETCH_ORDERS_REQUESTED})

    const today = new Date();
    const todayToLocale = today.toLocaleString("de-DE").split(",")[0].split(".").reverse();
    const todayFormatted = `${todayToLocale[0]}-${todayToLocale[1]}-${todayToLocale[2]}`

    const params = {
        "fields": "*.*",
        // GET META DATA
        meta: "*",
        // SORT ORDERS BY DELIVERY DATES -> DESCENDING
        // IF NO LIMIT SET, DISPLAY 1000 RESULTS
        limit: limit || 1000,
        search: search || "",
        page: page || 1,
        filter: {
            "_and": [
                {
                    "farmer": {
                        "_eq": farmerId
                    }
                },
                // IF REQUEST COMES FROM DASHBOARD VIEW, FILTER ORDERS ONLY IN THE FUTURE
                ...isDashboard ? [{
                    "delivery_date": {
                        "_gte": todayFormatted,
                    }
                }] : [],
                // IF REQUEST COMES FROM DASHBOARD VIEW, EXCLUDE CANCELLED ORDERS
                // ...isDashboard && [{
                    // "fk_order_status": {
                    //     "id": {
                    //         "_neq": 6
                    //     }
                    // }
                // }]
            ]
        },
        sort: sort || "-delivery_date",
    }


    getAxiosInstance(store).get('/items/orders', {params})
        .then(response => {
            const orders = response.data.data;
            const totalPages = limit ? Math.ceil(response.data.meta.total_count / limit) : 1;
            console.log("ORDERS FETCHED", response.data)
            dispatch({
                type: actionTypes.FETCH_ORDERS_SUCCEEDED,
                payload: orders,
                totalPages,
                page
            });
        })
        .catch(error => {
            console.log("ORDERS ERROR")
            dispatch({
                type: actionTypes.FETCH_ORDERS_FAILED,
                payload: error.message
            })
        })

}