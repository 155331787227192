import * as actionTypes from '../actions/types';

const initialState = {
    loading: false,
    productsList: [],
    error: ''
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRODUCTS_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FETCH_PRODUCTS_SUCCEEDED:
            return {
                loading: false,
                productsList: action.payload,
                error: ''
            }
        case actionTypes.FETCH_PRODUCTS_FAILED:
            return {
                loading: false,
                productsList: [],
                error: action.payload
            }
        default:
            return state;
    }
}

export default productsReducer;