/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 */
import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ProductForm from "../Components/ProductForm";
import {getAxiosInstance} from "../../../utils/interceptor";
import store from "../../../reduxStore/store";
import {Button, Icon} from '@mui/material';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import {closeProductEditDialog, loadingStart, loadingStop} from "../../../reduxStore/actions/actionUI";
import {toast} from "react-toastify";
import {fetchProducts} from "../../../reduxStore/actions/actionProducts";


function ProductUpdate() {

    const productToEdit = useSelector(state => state.UI.editProductId);
    const userId = useSelector(state => state.auth.id);
    const id = productToEdit;

    const isLoading = useSelector(state => state.UI.loading)

    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        dispatch(loadingStart())
        getAxiosInstance(store).get(`items/products/${id}`, {params: {fields: "*.*"}})
            .then(response => {
                setFormValues(response.data.data)
                dispatch(loadingStop())
            })
            .catch(error => {
                console.log(error.response)
                dispatch(loadingStop())
            })
    }, []);


    const handleProductSubmit = (updatedValues) => {

        const updateData = {
            name: updatedValues.name,
            price: updatedValues.price,
            status: updatedValues.status,
            description: updatedValues.description,
            stock_quantity: updatedValues.stock_quantity,
            ...updatedValues.stock_was_edited && {stock_quantity_reference: updatedValues.stock_quantity_reference},
            categories: updatedValues.categories,
            tax: updatedValues.tax.id,
            fk_tags: updatedValues.fk_tags,
            images: updatedValues.images,
            product_available_from: updatedValues.product_available_from,
            taxes_enabled: updatedValues.taxes_enabled,
            amount: updatedValues.amount,
            fk_unit_measurement: updatedValues.fk_unit_measurement,
            amount_adjustment: updatedValues.amount_adjustment,
            min_order_amount: updatedValues.min_order_amount,
            featured_product: updatedValues.featured_product,
            fk_bundle_discounts: updatedValues.fk_bundle_discounts,
            fk_farmer: updatedValues.fk_farmer,
            recurrent_order_enabled: updatedValues.recurrent_order_enabled
        }
        dispatch(loadingStart());

        getAxiosInstance(store).patch(`items/products/${id}`, updateData)
            .then(response => {
                console.log("RESPONSE UPDATE", response.data)
                dispatch(loadingStop())
                dispatch(closeProductEditDialog())
                dispatch(fetchProducts(userId))
                toast.success("Produkt wurde erfolgreich aktualisiert.");
            })
            .catch(error => {
                console.log(error.response)
                toast.error("Es ist ein Fehler bei der Aktualisierung Ihres Produkts aufgetreten.");
                dispatch(loadingStop())
            })
    }

    return (
        <MDBox>
            <MDBox
                mx={0}
                mt={0}
                py={3}
                px={2}
                sx={{display: "flex", alignItems: "center", justifyContent: "space-between", borderTopRightRadius: 0, borderTopLeftRadius: 0}}
                variant="gradient"
                bgColor="foodhiLightGreen"
                borderRadius="lg"
                coloredShadow="info"
            >
                <MDTypography variant="h6" color="white">
                    Produkt aktualisieren
                </MDTypography>
                <Button
                    variant="gradient"
                    onClick={() => {
                        dispatch(closeProductEditDialog())
                    }}
                >
                    <Icon
                        sx={{
                            fontWeight: "bold",
                            color: "#fff",
                        }}
                        fontSize="16"
                    >cancel</Icon>
                </Button>
            </MDBox>
            <MDBox pt={3}>
                <ProductForm
                    handleCallback={handleProductSubmit}
                    isLoading={isLoading}
                    editProductValues={formValues}
                    productId={productToEdit}
                />
            </MDBox>
        </MDBox>
    );
}

export default ProductUpdate;
