import React from 'react';
import {useDispatch} from "react-redux";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import {Typography} from "@mui/material";
import { useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {authLogout} from "../../reduxStore/actions/actionAuth";


export default function Logout () {

    const dispatchAction = useDispatch();

    const navigate = useNavigate();

    const logout = () => {
        const confirm = window.confirm("Möchtest du dich abmelden?");
        if (confirm) {
            dispatchAction(authLogout());
            navigate("/authentication/sign-in")
        }
    };

    return (
        <div style={{marginLeft: 15, display: "flex", flex: 1, alignItems: "flex-start", justifyContent: "flex-start"}}>
            <IconButton
                size="small"
                disableRipple
                onClick={logout}
            >
                <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", padding: "10px 15px"}}>
                    <Icon>logout</Icon>
                    <span style={{fontSize: "14px", fontWeight: 300, color: "#344767", paddingLeft: 15}}>
                        Abmelden
                    </span>
                </Box>
            </IconButton>
        </div>
    );
};
