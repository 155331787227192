import React from 'react';
import DataTable from "../../../examples/Tables/DataTable";
import OrderModal from "../../orders/order-list/Orders/Components/OrderModal";
import MDTypography from "../../../components/MDTypography";
import colors from "../../../assets/theme/base/colors";
import {Box, Card, Chip, Typography} from "@mui/material";
import MDBox from "../../../components/MDBox";
import dayjs from "dayjs";

export default function FutureOrders({orders}) {

    function renderStatus(status) {
        switch (status) {
            case "in_progress":
                return (
                    <MDBox shadow="xs" style={{backgroundColor: "lightblue", padding: "5px 10px", borderRadius: 20, minWidth: 120, textAlign: "center"}}>
                        In Bearbeitung
                    </MDBox>
                );
                break;
            case "shipped":
                return (
                    <MDBox shadow="xs" style={{backgroundColor: "lightgreen", padding: "5px 10px", borderRadius: 20, minWidth: 120, textAlign: "center"}}>
                        Im Versand
                    </MDBox>
                );
                break;
            case "completed":
                return (
                    <MDBox shadow="xs" style={{backgroundColor: "lightgray", padding: "5px 10px", borderRadius: 20, minWidth: 120, textAlign: "center"}}>
                        Abgeschlossen
                    </MDBox>
                );
                break;
            case "cancelled":
                return (
                    <MDBox shadow="xs" style={{backgroundColor: "#ffbebe", padding: "5px 10px", borderRadius: 20, minWidth: 120, textAlign: "center"}}>
                        Storniert
                    </MDBox>
                );
                break;
            default:
                return;
        }
    }

    return orders
        ? (
            <MDBox style={{flex: 1, display: "flex", flexDirection: "column", overflow: "hidden"}}>
                {/*CALCULATE THE AMOUNT OF ORDERS TO BE SHIPPED ON THAT DATE*/}
                <MDTypography style={{
                    padding: "0 15px",
                    // background: colors.foodhiLightGreen.main,
                    // borderRadius: "12px",
                    // margin: "-25px 10px 0px 10px",
                    // color: "#fff",
                    fontFamily: "Rubik, sans-serif",
                    fontWeight: "600",
                    fontSize: 18
                }}>
                    Bestellungen
                </MDTypography>

                <Box pb={2} pt={1} px={2}>
                    <DataTable
                        table={{
                            columns: [
                                {Header: "info", accessor: "info", align: "left", width: "10%"},
                                {Header: "status", accessor: "status", align: "left", width: "10%"},
                                {Header: "id", accessor: "id", align: "left", width: "10%"},
                                {Header: "lieferdatum", accessor: "date", align: "left"},
                                {Header: "gesamt", accessor: "total", align: "left"},
                                {Header: "kunde", accessor: "client", align: "left"},
                                {Header: "adresse", accessor: "address", align: "left"},
                                {Header: "notiz", accessor: "note", align: "left"}
                            ],
                            rows: orders.map((order) => ({
                                info: <OrderModal order={order}/>,
                                status: renderStatus(order?.fk_order_status?.name),
                                id: order.id,
                                date: dayjs(order.delivery_date).format("DD MMMM YYYY"),
                                total: `€ ${order.total.toLocaleString("de-DE")}`,
                                client: <div style={{maxWidth: 200}}>{order.fk_shipping_address?.company}</div>,
                                address:
                                    <div style={{maxWidth: 200, overflowX: "hidden"}}>
                                        <a rel="noreferrer"
                                           href={`https://www.google.de/maps/place/${order.fk_shipping_address?.street_1},+${order.fk_shipping_address?.postcode},+${order.fk_shipping_address?.city}/`}
                                           target="_blankbuil">
                                            {`${order.fk_shipping_address?.street_1}, ${order.fk_shipping_address?.postcode}, ${order.fk_shipping_address?.city}`}
                                        </a>
                                    </div>,
                                note: <div style={{maxWidth: 200}}>{order.delivery_note}</div>
                            }))
                        }}
                        showTotalEntries={false}
                        noEndBorder
                        entriesPerPage={false}
                        canSearch={false}
                    />
                </Box>


            </MDBox>
        ) : (
            <span>Keine Bestellungen</span>
        )
}
