/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */


import React, {useState, useEffect} from "react";

// react-router components
import {Routes, Route, Navigate, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import {useMaterialUIController, setMiniSidenav} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import LogIn from "./layouts/authentication/login";
import axios from "axios";

export default function App() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    // const [rtlCache, setRtlCache] = useState(null);
    const {pathname} = useLocation();

    const navigation = useNavigate();
    const location = useLocation();

    const userStatus = useSelector(state => state.auth);

    useEffect(() => {
        if (location.pathname !== "/invite_new_user") {
            if (location.pathname !== "/invite-farmer") {
                if (location.pathname !== "/download-app") {
                    if (location.pathname !== "/password-reset") {
                        if (location.pathname !== "/datenschutzerklaerung") {
                            if (!userStatus.token) {
                                navigation('/authentication/sign-in')
                            }
                        }
                    }
                }
            }
        }
    }, [userStatus]);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);


    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }
            if (route.route) {
                return <Route exact path={route.route} element={route.component} key={route.key}/>;
            }
            return null;
        });


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <ToastContainer/>
            {layout === "dashboard" && (
                <Sidenav
                    color={sidenavColor}
                    brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                    brandName="Foodhi"
                    routes={routes}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                />
            )}
            {/* {layout === "vr" && <Configurator/>} */}
            <Routes>
                {getRoutes(routes)}
                <Route path="*" element={<Navigate to="/dashboard"/>}/>
            </Routes>
            {/* <AuthVerify logOut={logOut}/> */}
        </ThemeProvider>
    );
}
