/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {useDispatch, useSelector} from "react-redux";
import {TailSpin} from "react-loader-spinner";
import {Icon, Typography, Card, Modal, Grid, Button} from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../../reduxStore/store";
import {actionUnlinkCustomer, fetchCustomers} from "../../reduxStore/actions/actionCustomers";
import {RESTAURANT_PROFILE_PLACEHOLDER, THUMBNAIL_SETTINGS} from "../../variables";
import {useState} from "react";
import CustomerSettingsModal from "./Components/CustomerSettingsModal";


function CustomersList({customers}) {
    const farmer_id = useSelector(state => state.userProfile.id);
    const dispatch = useDispatch();
    const [settingsModalVisible, setSettingsModalVisible] = useState(
        {
            visible: false,
            restaurant_id: null
        });


    const avatarImage = (item) => {
        if (!item.restaurant_thumbnail) {
            return `${process.env.REACT_APP_SITE_URL}/assets/${RESTAURANT_PROFILE_PLACEHOLDER}`
        }
        return `${process.env.REACT_APP_SITE_URL}/assets/${item?.restaurant_thumbnail}?${THUMBNAIL_SETTINGS}`
    }

    const unlinkCustomer = (item) => {
        if (confirm("Bist du sicher, dass du diesen Kunde entfernen möchtest?")) {
            getAxiosInstance(store).get(`/items/farmer_profile_restaurant_profile`, {
                params: {"filter[restaurant_profile_id][_neq]": item.id}
            })
                .then(response => {
                    const patchData = {fk_customer_restaurant: response.data.data};
                    dispatch(actionUnlinkCustomer(farmer_id, patchData))
                })
                .catch(error => console.log(error.response))
        }
    }

    const table = {
        columns: [
            {Header: "avatar", accessor: "avatar", width: "10%", align: "left"},
            {Header: "name", accessor: "name", align: "left"},
            {Header: "e-mail", accessor: "email", align: "left"},
            {Header: "telefon", accessor: "phone", width: "20%", align: "left"},
            {Header: "geschäft", accessor: "business", align: "left"},
            {Header: "unlink", accessor: "unlink", align: "center"},
            {Header: "mehr", accessor: "mehr", align: "center"},
        ],

        rows: customers.map(customer => {
            const item = customer.restaurant_profile_id;
            return (
                {
                    avatar: item && <img src={avatarImage(item)} alt="User Profile"
                                         style={{height: 40, width: 40, borderRadius: "50%"}}/>,
                    name: (item?.contact_first_name || item?.contact_last_name)
                        ? <div style={{maxWidth: 180}}>{item?.contact_first_name || ""} {item?.contact_last_name || ""}</div>
                        : "N/A",
                    email: <div style={{maxWidth: 180, textOverflow: "ellipsis", whiteSpace: "nowrap", overflowX: "hidden"}}>{item?.contact_email || "N/A"}</div>,
                    phone: item?.contact_telephone || "N/A",
                    business: <div style={{maxWidth: 200}}>{item?.restaurant_name}</div>,
                    unlink: <Icon style={{marginTop: 4, cursor: "pointer"}} color="error"
                                  onClick={() => unlinkCustomer(item)}>cancel</Icon>,
                    mehr: <Icon
                        style={{marginTop: 4, cursor: "pointer"}}
                        fontSize={"medium"}
                        color="foodhiRed"
                        onClick={() => setSettingsModalVisible({visible: true, restaurant_id: item.id})}> more_vert </Icon>
                    //IF INVITATION STILL NOT VALID <MDButton color="warning" size="small">Nicht bestätigt</MDButton>
                }
            )

        })
    }

    if (customers.length) {
        return (
            <>
                <Card>
                    <MDBox>
                        {customers && customers.length ? (
                                <DataTable
                                    table={table}
                                    isSorted={false}
                                    canSearch
                                    noEndBorder
                                    entriesPerPage={{defaultValue: 50}}
                                />
                            ) :
                            (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: window.innerHeight / 1.5
                                }}>
                                    <TailSpin/>
                                </div>
                            )
                        }

                    </MDBox>
                </Card>

                <CustomerSettingsModal
                    settingsModalVisible={settingsModalVisible}
                    closeModal={() => setSettingsModalVisible({visible: false, restaurant_id: null})}
                />
            </>

        )
    }
    return <Typography>Einlade deinen ersten Kunden</Typography>

}

export default CustomersList;
