import * as actionTypes from '../actions/types';
import {FETCH_PENDING_RESTAURANTS} from "../actions/types";

const initialState = {
    loading: false,
    linkedCustomers: [],
    error: ''
}

const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CUSTOMERS_REQUESTED:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FETCH_ALL_CUSTOMERS:
            return {
                ...state,
                allCustomers: action.payload,
            }
        case actionTypes.FETCH_CUSTOMERS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                linkedCustomers: action.payload,
                error: ''
            }
        case actionTypes.FETCH_CUSTOMERS_FAILED:
            return {
                loading: false,
                linkedCustomers: [],
                error: action.payload
            }
        case actionTypes.START_ADD_EXISTING_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case actionTypes.ADD_EXISTING_CUSTOMER:
            return {
                ...state,
                linkedCustomers: action.payload,
                loading: false
            }
        case actionTypes.ERROR_ADD_EXISTING_CUSTOMER:
            return {
                ...state,
                loading: false
            }
        case actionTypes.START_UNLINK_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case actionTypes.UNLINK_CUSTOMER:
            return {
                ...state,
                linkedCustomers: action.payload,
                loading: false
            }
        case actionTypes.ERROR_UNLINK_CUSTOMER:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case actionTypes.FETCH_PENDING_RESTAURANTS:
            return {
                ...state,
                pendingRestaurants: action.payload
            }
        case actionTypes.FETCH_RECURRENT_ORDERS:
            return {
                ...state,
                recurrentOrders: action.payload
            }
        case actionTypes.DELETE_RECURRENT_ORDER:
            return {
                ...state,
                recurrentOrders: state.recurrentOrders.filter(order => order.id !== action.payload),
                loading: false
            }
        default:
            return state;
    }
}

export default customersReducer;