/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Card from "@mui/material/Card";
import {TailSpin} from "react-loader-spinner";
import {FormControl, MenuItem, Select, Typography} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";

import colors from "../../../../assets/theme/base/colors";
import {fetchOrders} from "../../../../reduxStore/actions/actionOrders";
import ordersDataTable from "./ordersDataTable";
import FoodhiPagination from "../../../../utils/FoodhiPagination";


function OrdersList() {
    const {columns, rows} = ordersDataTable();

    const dispatch = useDispatch();

    const orders = useSelector(state => state.orders.ordersList);
    const isLoading = useSelector(state => state.orders.loading);
    const orderError = useSelector(state => state.orders.error);
    const totalPages = useSelector(state => state.orders.totalPages);
    const farmerId = useSelector(state => state.userProfile.id);

    const [resultsPerPage, setResultsPerPage] = useState(15);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState("-delivery_date");
    const [page, setPage] = useState(1);

    useEffect(() => {
        console.log("FIRE")
        dispatch(fetchOrders(false, farmerId, page, sort, resultsPerPage, search))
    }, [resultsPerPage, sort, search, page])

    const handleSetPage = (value) => {
        setPage(value)
    }

    const renderPagination = useCallback(() => {
        return <FoodhiPagination totalPages={totalPages} page={page} setPage={handleSetPage}/>
    }, [totalPages, page])

    if (isLoading) {
        return (
            <MDBox>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "400px"
                }}>
                    <TailSpin color={colors.foodhiOrange.main}/>
                </div>
            </MDBox>
        )
    }
    if (orderError) {
        return (<span>Fehler: {orderError}</span>)
    }
    if (orders && orders.length > 0) {
        return (
            <Card>
                <MDBox>
                    {orders && orders.length && (
                        <>
                            <MDBox display="flex" justifyContent="space-between">
                                <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={3}>
                                    <MDBox display="flex" flexDirection="center" alignItems="center" width={150} gap={1}>
                                        <MDTypography
                                            variant="caption"
                                            color="secondary"
                                            flex={3}
                                            fontSize={12}
                                        >
                                            Einträge pro Seite:
                                        </MDTypography>

                                        <FormControl fullWidth sx={{flex: 1}}>
                                            <Select
                                                labelId="results-per-page-label"
                                                id="results-per-page"
                                                value={resultsPerPage}
                                                variant="standard"
                                                sx={{color: "gray"}}
                                                onChange={(e) => setResultsPerPage(e.target.value)}
                                            >
                                                <MenuItem value={10}>10</MenuItem>
                                                <MenuItem value={15}>15</MenuItem>
                                                <MenuItem value={20}>20</MenuItem>
                                                <MenuItem value={25}>25</MenuItem>
                                                <MenuItem value={50}>50</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </MDBox>
                                </MDBox>

                                <MDBox display="flex" justifyContent="flex-end" alignItems="center" pt={3} px={3} >
                                    <MDBox display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end" width={220}>
                                        <MDTypography
                                            variant="caption"
                                            color="secondary"
                                            flex={2}
                                            fontSize={12}
                                        >
                                            Sortieren nach:
                                        </MDTypography>
                                        <FormControl fullWidth sx={{flex: 2}}>
                                            <Select
                                                labelId="sort-label"
                                                id="sort-page"
                                                value={sort === "-delivery_date" ? "> Liefertermin" :
                                                    sort === "delivery_date" ? "< Liefertermin" :
                                                        sort === "-id" ? "< ID" :
                                                            sort === "id" ? "> ID" : "Name"}
                                                label="Sortierung"
                                                variant="standard"
                                                sx={{color: "gray"}}
                                                onChange={(e) => setSort(e.target.value)}
                                            >
                                                <MenuItem value={"-delivery_date"}>Letzter Liefertermin</MenuItem>
                                                <MenuItem value={"delivery_date"}>Erster Liefertermin</MenuItem>
                                                <MenuItem value={"-id"}>Letzte ID</MenuItem>
                                                <MenuItem value={"id"}>Erste ID</MenuItem>
                                                {/*<MenuItem value={"order.restaurant.restaurant_name"}>Kunde</MenuItem>*/}
                                            </Select>
                                        </FormControl>

                                    </MDBox>
                                </MDBox>
                            </MDBox>


                            <DataTable
                                table={{columns, rows}}
                                showTotalEntries={false}
                                isSorted={false}
                                noEndBorder
                                entriesPerPage={{defaultValue: resultsPerPage, entries: [10, 15, 20, 25, 50]}}
                            />

                            {renderPagination()}

                        </>

                    )
                    }

                </MDBox>
            </Card>
        )
    }
    return (
        <MDBox>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "400px"
            }}>
                <Typography>Keine Bestellungen</Typography>
            </div>
        </MDBox>
    )
}

export default OrdersList;
