import axios from "axios";
import * as actionTypes from './types';
import {getAxiosInstance} from "../../utils/interceptor";
import store from "../store";
import {loadingStart, loadingStop} from "./actionUI";
import {toast} from "react-toastify";

export const refreshProfileData = (token, storeId) => async dispatch => {
    const response = await axios.get(`${process.env.REACT_APP_SITE_URL}/wp-json/wc/v3/customers/${storeId}`, {headers: {Authorization: `Bearer ${token}`}})

    dispatch({
        type: actionTypes.READ_USER_PROFILE,
        payload: response.data
    })
}

export const changeStoreThumbnail = (data) => {
    return {
        type: actionTypes.UPDATE_THUMBNAIL,
        payload: data
    }
}

export const deleteStoreBanners = (banners, image, farmer_id) => async dispatch => {

    const newBannersArray = banners.filter(item => item.directus_files_id !== image)

    getAxiosInstance(store).patch(`items/farmer_profile/${farmer_id}?fields=store_banners.*`, {store_banners: newBannersArray})
        .then(response => {
            console.log("PATCH", response.data)
            dispatch({
                type: actionTypes.UPDATE_BANNERS,
                payload: response.data.data
            })

            getAxiosInstance(store).delete(`/files/${image}`)
                .then(() => {
                    console.log("SUCCESS IMAGE DELETION");
                    dispatch(loadingStop());
                })
                .catch(error => {
                    console.log(error.response, "DELETE IMAGE ERROR");
                    dispatch(loadingStop());
                })
        })
        .catch(error => {
            console.log(error, "PATCH ERROR");
            dispatch(loadingStop());
        })

}

export const uploadStoreBanners = (formData, farmer_id) => async dispatch => {

    const banners = store.getState().userProfile.store_banners;

    console.log(banners)

    getAxiosInstance(store).post(`/files?fields=*`, formData)
        .then((response) => {
            const newImageBanner = {
                farmer_profile_id: farmer_id,
                directus_files_id: response.data.data.id
            };
            const newArray = banners.concat(newImageBanner);

            const patchData = {
                store_banners: newArray
            }
            getAxiosInstance(store).patch(`items/farmer_profile/${farmer_id}?fields=*.*`, patchData)
                .then((response) => {
                    console.log(response.data.data, "IMAGE")
                    dispatch({
                        type: actionTypes.UPDATE_BANNERS,
                        payload: response.data.data
                    })
                    dispatch(loadingStop())
                })
                .catch(error => console.log(error, "PATCH ERROR"))
        })
        .catch(error => {
            console.log(error, "UPLOAD IMAGE ERROR");
        })

}

export const updateBusinessDetails = (submitData, farmerId) => async dispatch => {
    dispatch(loadingStart())
    getAxiosInstance(store).patch(`/items/farmer_profile/${farmerId}`, submitData, {
        params: {"fields": "store_name, short_description, delivery_fee, delivery_deadline, shipping_availability, store_description, fk_farmer_categories, fk_global_tax.*"}
    })
        .then((response) => {
            dispatch(loadingStop())
            dispatch({
                type: actionTypes.UPDATE_BUSINESS_DETAILS,
                payload: response.data.data
            })

            toast.success('Geschäftliche Daten wurden erfolgreich aktualisiert.',
                {position: toast.POSITION.TOP_CENTER}
            );
        })
        .catch((error) => {
            dispatch(loadingStop())
            console.log("patch error", error.response)
            toast.error('Es ist ein Fehler aufgetreten, bitte versuche es erneut.',
                {position: toast.POSITION.TOP_CENTER}
            );
        })
}