import * as actionTypes from '../actions/types';

const initialState = {};

const authReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.AUTH_LOGIN:
            return {
                ...state,
                user_email: action.payload.email,
                id: action.payload.id,
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                language: action.payload.language,
                role: action.payload.role,
                isLoading: false,
                loggedIn: true,
            }

        case actionTypes.SET_TOKENS:
            return {
                ...state,
                token: action.payload.access_token,
                refresh_token: action.payload.refresh_token
            }

        case actionTypes.NOT_AUTHORIZED:
            return {
                ...state,
                loggedIn: false,
                isGuest: false
            }

        default:
            return state;
    }
};

export default authReducer;
